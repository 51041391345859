import React from "react";
import { connect } from "react-redux";


class AlgemeenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentWillMount() {}
  render() {
    const { beoordeling } = this.props;
    function beoordelingresult(result) {
       if (result ==="onvoldoende") {
        return <span className='badge bg-red'>{result}</span>
       }
       if (result ==="voldoende") {
        return <span className='badge bg-orange'>{result}</span>
       }
       if (result ==="redelijk") {
        return <span className='badge bg-blue'>{result}</span>
       }
       if (result ==="goed") {
        return <span className='badge bg-green'>{result}</span>
       }
      }
    if (this.props.loaded && beoordeling.algemeen) {
      return (
        <div className="box box-default">
          <div className="card">
            <div className="card-header">
              Algemeen
              <div className="float-right">{beoordelingresult(beoordeling.algemeen.algemeen)}</div>
            </div>
            <div className="card-body">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">Uitstraling</div>
                  <div className="col-sm-3">{beoordelingresult(beoordeling.algemeen.uitstraling)}</div>
                  <div className="col-sm-3">Enthousiast</div>
                  <div className="col-sm-3">{beoordelingresult(beoordeling.algemeen.enthousiast)}</div>
                </div>
                <div className="row">
                  <div className="col-sm-3">Vaardigheden</div>
                  <div className="col-sm-3">{beoordelingresult(beoordeling.algemeen.vaardigheden)}</div>
                  <div className="col-sm-3">Actieve houding</div>
                  <div className="col-sm-3">{beoordelingresult(beoordeling.algemeen.houding)}</div>
                </div>
                <div className="row">
                  <div className="col-sm-3">Groepsgedrag</div>
                  <div className="col-sm-3">{beoordelingresult(beoordeling.algemeen.groepsgedrag)}</div>
                  <div className="col-sm-3">Soort medewerker</div>
                  <div className="col-sm-3">{beoordeling.algemeen.medewerker}</div>
                </div>
              </div>
              <h5 className="card-title paddingtop">Toelichting</h5>
              {beoordeling.algemeen.toelichting}
              <h5 className="card-title paddingtop">Bijzonderheden trainingsweekend:</h5>
              {beoordeling.algemeen.bijzonderheden}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
    return { beoordeling: state.beoordelingen.item };
  }
  
  export default connect(mapStateToProps, {  })(AlgemeenForm);
