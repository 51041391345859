import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import ProfileFotoPage from "./Components/ProfielFoto";
import MessageSucces from "../../../Components/Forms/Validate/conform";
import MessageError from "../../../Components/Forms/Validate/error";
import { Link } from "react-router-dom";
import { fetchcurist } from "./Reducers/actions";
import { gettraining, getcurist } from "../Trainingen/Reducers/actions";
import {fetchbeoordelingcursisit} from "../Beoordelingen/Reducers/actions"
import BeoordelingenOverzicht from "../Beoordelingen/overzicht"

import CertDownload from "../Beoordelingen/Components/certificaat"

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cursist: {},
      loadbeoordeling: false,
      loaded: false,
      training: {}
    };
  }
  componentWillMount() {
    this.setState({ loadbeoordeling: false, loaded: false });
    this.props.fetchcurist(this.props.match.params.id).then(data => {
      if (data.results) {
        this.setState({ cursist: data.results });
        this.props.getcurist(this.props.match.params.id).then(data => {
          this.setState({ loadbeoordeling: true });
        });
      }
    });
  }
loadboordeling = (training) => {
  this.props
  .fetchbeoordelingcursisit({
    cursist: this.props.match.params.id,
    training: training._id
  })
  .then(data => {
    if (data.results) {
      this.setState({ beoordelingen: data.results });
      this.setState({ loaded: true, training });
    }
  });
}
  render() {
    const { cursist, trainingen, token } = this.props;    
    function leeftijd(datum) {
      if (datum) {
        var birthday = moment(datum, "DD/MM/YYYY");
        return moment().diff(birthday, "years");
      }
    }
    function meerderjaars(ervaring) {
      if (ervaring === "1") {
        return <span className="label label-success">Eerste jaars</span>;
      }
      if (ervaring === "2") {
        return <span className="label label-warning">Meerderjaars</span>;
      }
    }
    function datum(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }

    function wijzigen (role) {
      if (role ==="admin" || role==="root" || role==="hoofdtrainer") {
        return true
      } else {
        return false
      }
    }

    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Het profiel is goed opgeslagen"}
          success={this.state.success}
        />
        <MessageError
          tekst={"Er is iets fout gegaan"}
          error={this.state.error}
        />
        <div className="box-header with-border">
          <div className="box-title">
            <h2>
              {cursist.voornaam} {cursist.achternaam}
            </h2>
          </div>
          { wijzigen(token.role) &&
          <Link
            to={"/admin/trainingen/cursist/edit/"+ this.props.match.params.id}
            className="btn btn-warning btn-sm float-right"
            role="group"
          >
            Wijzigen
          </Link> 
  }
          <Link
            to="/admin/trainingen/cursisten"
            className="btn btn-primary btn-sm float-right"
            role="group"
          >
            Overzicht
          </Link>        
        </div>
        <div className="row">
          <div className="col-md-8">
          <div className="box-body box-profile">
            Leeftijd:{" "}
            <span className="geboortedatum">
              {leeftijd(cursist.geboortedatum)}
            </span>
            {cursist.bedrijf && (
              <div>
                Park: <span className="geboortedatum">{cursist.bedrijf.naam}</span>
              </div>
            )}
            {meerderjaars(cursist.ervaring)}
            </div>
            <div className="box-header with-border">
              <h3 className="box-title"> Trainingen</h3>
              <div className="box-body box-profile" />
              <div className="react-bootstrap-table">
                {trainingen && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Titel</th>
                        <th>Trainer</th>
                        <th style={{ width: "20px" }}>Acties</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainingen.map(training => (
                        <tr key={training._id}>
                          <td>{datum(training.datum)}</td>
                          <td>{training.titel}</td>
                          <td>{training.trainer.voornaam}</td>
                          <td>
                            <button
                              onClick={this.loadboordeling.bind(this,training)}
                              className="btn btn-primary"
                              role="group"
                            >
                              Beoordeling
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ProfileFotoPage cursist={this.state.cursist} />

          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
          <div className="box-body box-profile">
          {this.state.loaded &&
          <div>
          <BeoordelingenOverzicht cursist={cursist} />
  
          <CertDownload training={this.state.training} cursist={cursist} />
          </div>
  }
          </div></div>
          </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    cursist: state.cursisten.item,
    trainingen: state.trainingen.items,
    beoordelingen: state.beoordelingen.item,
    token: state.auth.token
  };
}

export default connect(mapStateToProps, {
  fetchcurist,
  gettraining,
  getcurist,
  fetchbeoordelingcursisit
})(ProfilePage);
