import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensorelectra, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur } from "../Reducers/actions";
import { fixmessage, loading } from "../../../Reducers/loading/actions";
import moment from "moment";
import LineGraph from "./Components/linegraps";
import BarGraph from "./Components/graphbar";
import { Link } from "react-router-dom";
import Tabel from "./Components/tabel";
import FilterSensor from "./Components/filter";
import KiesDate from "../Components/datumkeuze";
const SensorItemElectra = (props) => {
  const [load, Setload] = useState(false);
  const [datum, Setdatum] = useState({ startdate: moment().subtract(7, "days").toDate(), enddate: new Date() });
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
    temp_id: "eui-a84041df118356ba",
  });
  const [datum_day] = useState({ startdate: moment().subtract(6, "days").toDate(), enddate: new Date() });
  const [datum_month] = useState({ startdate: moment().subtract(12, "months").toDate(), enddate: new Date() });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [max, Setmax] = useState(0);
  const [fases, Setfases] = useState([0, 0, 0]);
  const [standdag, setStandDag] = useState(0);

  useEffect(() => {
    getvals();
    getvals_dag();
    getvals_uur();
    getvals_maand();
  }, [datum, datum_hour]);

  function changedata(data) {
    if (data.datum.enddate && data.datum.startdate) {
      const timer = setTimeout(() => {
        Setdatum(data.datum);
      }, 1000);
    }
  }

  function getvals() {
    props.getsensorelectra(props.match.params.id, datum).then((data) => {
      if (data.results) {
        Setload(true);
        Setgemiddelde(data.results.data.map((element) => element.value).reduce((a, b) => a + b, 0) / data.results.data.length);
        Setmax(Math.max(...data.results.data.map((o) => o.value)));
        gemfases(data.results);
      } else {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }
  function gemfases(data) {
    var val = [];
    val[0] =
      data.data
        .map((element) => {
          if (element.decoded_payload.Current1) {
            return element.decoded_payload.Current1;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    val[1] =
      data.data
        .map((element) => {
          if (element.decoded_payload.Current2) {
            return element.decoded_payload.Current2;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    val[2] =
      data.data
        .map((element) => {
          if (element.decoded_payload.Current3) {
            return element.decoded_payload.Current3;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    Setfases(val);
  }
  function getvals_dag() {
    props.getsensorelectra_dag(props.match.params.id, datum_day).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }
  function getvals_maand() {
    props.getsensorelectra_maand(props.match.params.id, datum_month).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
  }
  function getvals_uur() {
    props.getsensorelectra_uur(props.match.params.id, datum_hour).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      } else {
    
        if (data.results.data && data.results.data[0]) {
          var totaal = data.results.data.reduce((prev, curr) => prev + curr.value, 0);
        }
        setStandDag((((totaal / 1000) * 690) / 1000).toFixed(2));
      }
    });
  }
  function getkwh(data) {
    var kwh = 0;
    if (data && data.decoded_payload && data.decoded_payload.Device === "R718N3") {
      kwh = ((data.value / 1000) * 690) / 1000;
    }
    return kwh.toFixed(2);
  }

  const { sensor } = props;
  function battery(value) {
    if (value && value && value.decoded_payload && value.decoded_payload.Volt) {
      if (value.decoded_payload.Volt >= 3.6) {
        return <i className="fas fa-battery-full green"></i>;
      } else {
        return <i className="fas fa-battery-quarter red"></i>;
      }
    }
  }

  function luodate(data) {
    if (data[0] && data[0].datum) {
      return moment(data[0].datum).fromNow();
    } else {
      return null;
    }
  }
  function changedag(data) {
    Setdatum_hour({
      startdate: moment(data.datum.format()).subtract(1, "days").toDate(),
      enddate: data.datum.format(),
      temp_id: "eui-a84041df118356ba",
    });
  }

  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title"> {sensor.item.naam}</h3>
        <div className="box-tools pull-right">
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/sensoren/sensor/edit/" + sensor.item._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor changedata={changedata} gemiddelde={gemiddelde} max={max} fases={fases} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row p-0">
              <div className="col-12 col-md-4 col-lg-2 p-0">
                <div className="p-4">
                  <span className="valuekwh"> {getkwh(sensor.item.data[0])} KwH</span>

                  <div className="details">Laatste meting {luodate(sensor.item.data)}</div>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-10 p-0">
                <LineGraph sensor={sensor.item} kleur="#D96A29" />
              </div>
              <div className="col-12">
                <div className="p2">Batterij: {battery(sensor.item.data[0])} </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen per dag</h3>
                <BarGraph sensor={sensor.dag} kleur="#B1BF41" type="dag" />
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen per maand</h3>
                <BarGraph sensor={sensor.maand} kleur="#D96A29" type="maand" />
              </div>
              <div className="col-12 col-md-12 col-lg-12 p-0">
                <div className="box-titel">
                  <h3 className="p-4">Metingen 24 uur ({standdag} KwH)</h3>
                  <KiesDate changedata={changedag} />
                </div>
                <BarGraph sensor={sensor.uur} kleur="#D96A29" type="uur" />
              </div>
              <div className="col-12">
                <h3 className="p-4">Gegevens tabel metingen</h3>
                <Tabel sensor={sensor.item} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren };
}

export default connect(mapStateToProps, { getsensorelectra, fixmessage, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur })(
  SensorItemElectra
);
