import React, { Component } from 'react'
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
    render() {
        const { sensor } = this.props;
        function priceFormatter(cell, row) {
          return moment(cell).format("DD-MM-YYYY HH:mm");
        }
        const columns = [
          {
            dataField: "datum",
            text: "Datum",
            formatter: priceFormatter,
          },
          {
            dataField: "value",
            text: "Temperatuur",
          },
          {
            dataField: "_id",
            text: "Status",
            formatter: (cellContent, row) => {
              if (row.value) {
              if (sensor.minvalue > row.value) {
                return <span className="badge badge-danger">Temp. te laag</span>;
              } else if (sensor.maxvalue < row.value) {
                return <span className="badge badge-danger">Temp. te hoog</span>;
              } else {
                return <span className="badge badge-success">Goed</span>;
              }
            } else {
              return null
            }
            },
          },
        ];
        return (
            <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={sensor.data} columns={columns} pagination={paginationFactory({ sizePerPage: 20})} />
        )
    }
}
