import React, { Component } from "react";
import { connect } from "react-redux";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import GastenForm from "./Components/insert";
import GastenFormRegist from "./Components/regist";
import { checkgast, Sendmail, gast, checkgastmail } from "../Gasten/Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    sendmail: false,
    inschrijven: false,
    email: "",
    from: "",
    registform: false,
    checkform: true,
  };

  componentWillMount() {
    this.props.getbedrijf(this.props.match.params.id).then((bdata) => {
      this.setState({ loaded: true });
    });
    if (this.props.location.state && this.props.location.state.from) {
      this.setState({ from: this.props.location.state.from });
    } else {
      this.setState({ from: "/public/activiteiten/" + this.props.match.params.id });
    }
  }
  checkgastmail = (vals) => {
    const values = Object.assign(
      {},
      {
        isadmin: this.props.token,
        from: this.state.from,
        taal: localStorage.getItem("taal") ? localStorage.getItem("taal") : "NL",
      },
      vals
    );
    this.props.checkgastmail(values).then((data) => {
      if (data.check === "blocked") {
        this.props.fixmessage(3000, "danger", lang("Je account is geblokkeerd"));
      }
      if (data.check === "email") {
        this.setState({ sendmail: true, email: vals.email });
      }
      if (data.check === "new") {
        this.setState({ loaded: true, registform: true, checkform: false, email: vals.email });
        this.props.fixmessage(
          "warning",
          lang("Er is nog geen account met dit e-mailadres") +
            " " +
            vals.email +
            " " +
            lang("Vul de onderstaande gegevens in om je e-mailadres te controleren")
        );
      
      }
      if (data.check === "ok") {
        localStorage.setItem("gast", data.results._id);
        if (this.state.from) this.props.history.push(this.state.from);
        else this.props.history.push("/public/activiteiten/" + this.props.bedrijf._id);
      }
    });
  };
  checkgast = (vals) => {
    const values = Object.assign(
      {},
      {
        isadmin: this.props.token,
        from: this.state.from,
        taal: localStorage.getItem("taal") ? localStorage.getItem("taal") : "NL",
      },
      vals
    );
    this.props.checkgast(values).then((data) => {
      if (data.check === "blocked") {
        this.props.fixmessage(3000, "danger", lang("Je account is geblokkeerd"));
      }
      if (data.check === "email") {
        this.setState({ sendmail: true, email: vals.email });
      }
      if (data.check === "new") {
        this.setState({ loaded: false });
        this.props.fixmessage(
          "warning",
          lang("Er wordt nu een mail gestuurd naar") +
            " " +
            vals.email +
            " " +
            lang("Bevestig eerst je e-mailadres, daarna kun je verder met je inschrijving.")
        );
      }
      if (data.check === "ok") {
        localStorage.setItem("gast", data.results._id);
        if (this.state.from) this.props.history.push(this.state.from);
        else this.props.history.push("/public/activiteiten/" + this.props.bedrijf._id);
      }
    });
  };
  componentWillUnmount() {
    this.props.clearmessage();
  }
  sendvmail(e) {
    this.props.Sendmail({ email: this.state.email }).then((whatever) => {
      if (whatever) {
        this.setState({ sendmail: false });
        this.setState({ sendmailluck: true });
      }
    });
  }
  renderAlert() {
    if (this.state.sendmail === true) {
      return (
        <div className="alert alert-danger">
          {lang("Je e-mailadres is niet gecontroleerd. Klik")}{" "}
          <strong className="pointer" onClick={() => this.sendvmail()}>
            {lang("hier")}
          </strong>{" "}
          {lang("om nogmaals je account te activeren")}
          <br />
        </div>
      );
    }
  }
  render() {
    return (
      <div className="public-box-body">
        {this.state.loaded && this.state.from && (
          <div>
            <h2 className="headline text-yellow"> {lang("Inschrijven")}</h2>
            {this.renderAlert()}
            <div className="gastinfo">
              {lang("Wat leuk dat je je wilt inschrijven voor de recreatieactiviteiten of faciliteiten op ")}
              <b>{this.props.bedrijf.naam}</b>
              {lang(
                ". Je maakt heel eenvoudig een account aan door je e-mailadres en familienaam in te vullen. Je ontvangt van ons een bevestigingsmail met een unieke link om je account te activeren. Als je account geactiveerd is kun je je inschrijven!"
              )}
            </div>{" "}
            <div className="inschrijf-content">
              {this.state.checkform && <GastenForm onSubmit={this.checkgastmail} />}
              {this.state.registform && <GastenFormRegist email={this.state.email} onSubmit={this.checkgast} />}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  clearmessage,
  getbedrijf,
  checkgast,
  Sendmail,
  gast,
  checkgastmail,
})(Inschrijven);
