import { SET_VERHUUR, ADD_VERHUUR, VERHUUR_ERROR } from "./actions";
export default function verhuur(
  state = {
    item: [],
    items: [],
    beschikbaarheid: [],
    week: [],
    reserveringen: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_VERHUUR:
      return { ...state, items: [...state.itens, action.results] };
    case VERHUUR_ERROR:
      return { ...state, error: action.payload };
    case SET_VERHUUR:
      return { ...state, items: action.results };
    default:
      return state;
  }
}
