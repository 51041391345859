import React from 'react';
import {connect} from 'react-redux';
import {Savealertprofile} from './Reducers/actions';
import BedrijvenForm from './Components/form'
class SensorInsert extends React.Component {
  state = {
    errors: {}
  }


  componentWillMount() {
    //this.props.fetchprofile();
  }

  submit = vals => {
      this.props.Savealertprofile(vals).then(data => {
  
        if (data.type ==="ALERTPROFILE_ERROR") {
          alert(data.payload);
          return false;
        } else {
              this.props.history.push('/sensoren/alertprofile');
        }
      });
  }

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Alert Profile toevoegen</h3>
        </div>

      <div className="row">
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile">
            <BedrijvenForm onSubmit={this.submit}/></div>
        </div>
      </div>
    </div>
  </div>);
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, alertprofile: state.alertprofile};
}

export default connect(mapStateToProps, {Savealertprofile})(SensorInsert);
