import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

class InputLang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {},
    };
  }
  componentWillMount() {
    if (this.props.input.value) {
      const value = this.props.input.value;
      if (!value.NL) {
        this.setState({ value: { NL: this.props.input.value } });
      } else this.setState({ value });
    }
  }

  handleSelect = (event, taal) => {
    var value = this.state.value;
    value[taal] = event.target.value;
    this.setState({ value });
    this.props.input.onChange(value);
  };

  render() {
    const {
      input,
      label,
      type,
      languages,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div>
        {label && <label>{label}</label>}
        <Tabs>
          <TabList>
            {languages.map((lan, i) => (
              <Tab key={i}>{lan}</Tab>
            ))}
          </TabList>
          <div>
            {languages.map((lan, i) => (
              <TabPanel key={i}>
                <input
                  name={`${input.name}.${lan}`}
                  value={this.state.value[lan] || this.state.value["NL"]}
                  onChange={(value) => {
                    this.handleSelect(value, lan);
                  }}
                  className="form-control"
                  placeholder={label}
                  type={type}
                />
              </TabPanel>
            ))}
          </div>
          <div>{touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}</div>
        </Tabs>
      </div>
    );
  }
}

export default InputLang;
