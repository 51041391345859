import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../Components/loading/loading";
import { config } from "../../Reducers/auth/actions";
import { changelanguage } from "../../Components/Lib/Languages/language";
class Account extends Component {
  componentWillMount() {
    this.props.config();
  }
  setlang = (lang) => {
    changelanguage(lang);
    window.location.reload(false);
  };

  render() {
    return (
      <div className="content-page login-background_recreatie">
        <div className="content-wrapper fullscreen transpbackground">
          <div className="public-box">
            <div className="login-logo">
              <div className="landen">
                <button onClick={this.setlang.bind(this, "NL")} className="langlogo">
                  <img alt="nl" src={process.env.PUBLIC_URL + "/img/nederlands.png"} />
                </button>
                <button onClick={this.setlang.bind(this, "DE")} className="langlogo">
                  <img alt="de" src={process.env.PUBLIC_URL + "/img/duits.png"} />
                </button>
                <button onClick={this.setlang.bind(this, "EN")} className="langlogo">
                  <img alt="en" src={process.env.PUBLIC_URL + "/img/engels.png"} />
                </button>
                <button onClick={this.setlang.bind(this, "FR")} className="langlogo">
                  <img alt="fr" src={process.env.PUBLIC_URL + "/img/frans.png"} />
                </button>
              </div>
              <img alt="nl" src={process.env.PUBLIC_URL + "/img/logorcn.png"} />
              <div>
                {" "}
                <Link
                  className="bedrijfs-link"
                  to={{
                    pathname: "/public/bedrijf/" + this.props.bedrijf._id,
                  }}
                >
                  {this.props.bedrijf.naam}
                </Link>
              </div>
            </div>
            <section className="content">
              <Loading />
              {this.props.children}
            </section>
            {/* /.content */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { config: state.auth.config, bedrijf: state.pubred.bedrijf };
}

export default connect(mapStateToProps, { config })(Account);
