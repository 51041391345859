import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { renderTextField } from "../../../../Components/Forms/renders";
class Kwailiteitenspel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.loaded) {
      return (
        <div className="box box-default">
          <form onSubmit={handleSubmit}>
            <div className="box-body">
              <div className="row">
                <div className="col-12">
                  <Field
                    className="form"
                    name="kwaliteitenspel.top"
                    label="Waarin blinkt de deelnemer uit?"
                    component={renderTextField}
                  />
                  <Field
                    className="form"
                    name="kwaliteitenspel.bijzonderheden"
                    label="Wat zijn de uitdagingen voor de deelnemer?"
                    component={renderTextField}
                  />
                        <Field
                    className="form"
                    name="kwaliteitenspel.ontvangen"
                    label="Welke kwaliteit heb je ontvangen?"
                    component={renderTextField}
                  />
                </div>
              </div>
              <div className="pull-left submit">
                <input
                  type="submit"
                  className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                  name="next"
                  value="Beoordelen"
                />
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return null;
    }
  }
}
Kwailiteitenspel = reduxForm({
  form: "kwailiteitenspel", // a unique identifier for this form
  enableReinitialize: true
})(Kwailiteitenspel);

// You have to connect() to any reducers that you wish to connect to yourself
Kwailiteitenspel = connect(
  state => ({
    initialValues: state.beoordelingen.item
  }),
  {}
)(Kwailiteitenspel);

export default Kwailiteitenspel;
