import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../../Components/Forms/renders";
import MultiSelect from "../../../../Components/Forms/multiselect"

class BedrijvenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit } = this.props;
    const createslug = (event, value) => {
      var slug = value.toString().trim().toLowerCase().replace(/\s+/g, "-").replace(/[^\w]+/g, "").replace(/^-+/, "").replace(/-+$/, "");
      this.props.change('slug', slug);
    }
    const checkslug = (value) => {
      return value.toString().replace(/ /g, "-").trim().toLowerCase().replace(/\s+/g, "-").replace(/[^\w]+/g, "-").replace(/ /g, "-");
    }
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field name="naam" onChange={createslug} type="input" validate={[required()]} component={renderField} label="Naam Bedrijf" />
                <Field name="beschrijving" type="input" component={renderTextField} label="Vul korte bedrijving van je bedrijf" />
                <Field name="group" type="input" component={renderField} label="Vul een groupnaam in als nodig" />
                <Field name="slug" normalize={checkslug} type="input" component={renderField} label="Korte naam bedrijf (slug)" />
                <Field name="website" type="input" component={renderField} label="Website waar activiteiten staan" />
                <Field name="modules" type="input" component={MultiSelect} options={
                  ["horecas","recreatie", "logboeken", "trainingen", "faciliteiten", "sensoren","biepp","activiteiten", "standplaatsen"]
                } label="Modules die je wil activeren" />
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updates" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
BedrijvenForm = reduxForm({
  form: "bedrijfinsert", // a unique identifier for this form
})(BedrijvenForm);

// You have to connect() to any reducers that you wish to connect to yourself
BedrijvenForm = connect(
  (state) => ({
    initialValues: state.bedrijven.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(BedrijvenForm);

export default BedrijvenForm;
