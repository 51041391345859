export const SET_TRAININGEN = 'SET_TRAININGEN';
export const ADD_TRAININGEN = 'ADD_TRAININGEN';
export const TRAINING_ERROR = 'TRAINING_ERROR';
export const SET_TRAINING = 'SET_TRAINING'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_TRAININGEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_TRAINING,
    results
  }
}
export function add(results) {
  return {
    type: ADD_TRAININGEN,
    results
  }
}
export function itemError(error) {
  return {
    type: TRAINING_ERROR,
    payload: error
  };
}

export function savetrainingen(data) {
  return dispatch => {
    return fetch('/api/admin/trainingen', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results.results));
    }).catch((err) => {
      console.log(err);
      
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function updatetraining(id, data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      console.log(err);
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchtrainingen() {
  return dispatch => {
    return fetch('/api/admin/trainingen', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function gettraining(id) {
  return dispatch => {
    return fetch('/api/admin/training/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function addcursisttotraining (id, data) {
  return dispatch => {
    return fetch('/api/admin/training/update/cursisten/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return results.results;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };

}

export function getcurist(id) {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursist/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function deletecursist(id, data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/cursist/delete/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}




