import React from "react";
class ProgressComponent extends React.Component {
  render() {
    const { naam, tel1, tel2 } = this.props;
    var procent = 100-(tel2/tel1)*100
    return (
      <div className="row">
      <div className="col-md-12">
      <div className="progress-group">
        <span className="progress-text">{naam}</span>
        <span className="progress-number">
          <b>{tel1}</b>/{tel2}
        </span>

        <div className="progress sm">
          <div
            className="progress-bar progress-bar-aqua"
            style={{ width: procent+ "%" }}
          />
        </div>
      </div>
      </div>
      </div>
    );
  }
}
export default ProgressComponent;
