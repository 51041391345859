import React, { Component } from "react";
import PulseLoader from 'react-spinners/PulseLoader';
export default function Loading({loadings}) {

    if (!loadings) {
      return (
        <div>
          <PulseLoader color={'#D96929'} size={20} />
        </div>
      );
    } else {
      return null;
    }
  }


