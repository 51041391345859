import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderselect,
  renderTextField
} from "../../../../Components/Forms/renders";
class PitchenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.loaded) {
      return (
        <div className="box box-default">
          <form onSubmit={handleSubmit}>
            <div className="box-body">
              <div className="row">
                <div className="col-12">
                  <Field
                    className="form"
                    name="pitches.pitch"
                    label="Pitch"
                    component={renderselect}
                    options={{
                      onvoldoende: "onvoldoende",
                      voldoende: "voldoende",
                      redelijk: "redelijk",
                      goed: "goed"
                    }}
                  />
                  <Field
                    className="form"
                    name="pitches.voorbereid"
                    label="Voorbereid"
                    component={renderselect}
                    options={{
                      ja: "ja",
                      nee: "nee"
                    }}
                  />
                  <Field
                    className="form"
                    name="pitches.uitvoering"
                    label="Uitvoering"
                    component={renderTextField}
                  />
                </div>
              </div>
              <div className="pull-left submit">
                <input
                  type="submit"
                  className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                  name="next"
                  value="Beoordelen"
                />
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return null;
    }
  }
}
PitchenForm = reduxForm({
  form: "beoordelingpitches", // a unique identifier for this form
  enableReinitialize: true
})(PitchenForm);

// You have to connect() to any reducers that you wish to connect to yourself
PitchenForm = connect(
  state => ({
    initialValues: state.beoordelingen.item // pull initial values from account reducer
  }),
  {}
)(PitchenForm);

export default PitchenForm;
