export const SET_LOGBOEKEN = 'SET_LOGBOEKEN';
export const ADD_LOGBOEKEN = 'ADD_LOGBOEKEN';
export const LOGBOEK_ERROR = 'LOGBOEK_ERROR';
export const SET_LOGBOEK = 'SET_LOGBOEK'
export const SET_VERGELIJKEN = 'SET_VERGELIJKEN'
export const SET_JAAR ='SET_JAAR'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_LOGBOEKEN,
    results
  }
}
export function setjaar(results) {
  return {
    type: SET_JAAR,
    results
  }
}
export function setvergelijken(results) {
  return {
    type: SET_VERGELIJKEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_LOGBOEK,
    results
  }
}
export function add(results) {
  return {
    type: SET_LOGBOEK,
    results
  }
}
export function itemError(error) {
  return {
    type: LOGBOEK_ERROR,
    payload: error
  };
}

export function Savelog(data) {
  return dispatch => {
    return fetch('/api/admin/logboeken/insert', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function updatelogboek(id, data) {
  return dispatch => {
    return fetch('/api/admin/logboeken/'+id, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function dellogboek(id) {
  return dispatch => {
    return fetch('/api/admin/logboeken/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return true
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getlogboek(id) {
  return dispatch => {
    return fetch('/api/admin/logboeken/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchlogboeken(data) {
  return dispatch => {
    return fetch('/api/admin/logboeken/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function vergelijken(data) {
  return dispatch => {
    return fetch('/api/admin/vergelijken/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setvergelijken(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function Verwerken(data) {
  return dispatch => {
    return fetch('/api/admin/logboeken/website/verwerken', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return results.results;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function veranderjaar(data) {
  return dispatch => {
    return dispatch(setjaar(data));
  }
}
