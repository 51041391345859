import React, { Component } from "react";
import { Chart } from "react-google-charts";
export default class LineGraph extends Component {
  render() {
    const { sensor, kleur } = this.props;
    function grdata(data) {
      const columns = [
        { type: "datetime", label: "datum" },
        { type: "number", label: "KwH" },
      ];
      const rows = [];
      data.map(function (a, key) {
        if (a.datum && a.value && key > 0) {
          rows.push([new Date(a.datum), (data[key - 1].value - a.value)]);
        } else {
          return;
        }
      });

      return [columns, ...rows];
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor.data)}
        options={{
          curveType: "function",
          legend: { position: "bottom" },
          hAxis: {
            title: "Datum",
            format: "d MMM HH:mm",
          },
          vAxis: {
            title: "KwH.",
          },
          colors: [kleur],
          series: {
            1: { curveType: "function" },
          },
          explorer: {
            axis: "horizontal",
            actions: ["dragToZoom", "rightClickToReset"],
          },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
