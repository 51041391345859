export const SET_STANDPLAATSEN = 'SET_STANDPLAATSEN';
export const ADD_STANDPLAATSEN = 'ADD_STANDPLAATSEN';
export const SET_KAMPEERPLAATSEN = 'SET_KAMPEERPLAATSEN'
export const SET_STANDPLAATS = 'SET_STANDPLAATS'
export const STANDPLAATS_ERROR ='STANDPLAATS_ERROR'
export const SET_KAMPEERPLAATS ='SET_KAMPEERPLAATS'


function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_STANDPLAATSEN,
    results
  }
}
export function set_kampeerplaatsen(results) {
  return {
    type: SET_KAMPEERPLAATSEN,
    results
  }
}
export function set_kampeerplaats(results) {
  return {
    type: SET_KAMPEERPLAATS,
    results
  }
}

export function setone(results) {
  return {
    type: SET_STANDPLAATS,
    results
  }
}
export function add(results) {
  return {
    type: ADD_STANDPLAATSEN,
    results
  }
}
export function itemError(error) {
  return {
    type: STANDPLAATS_ERROR,
    payload: error
  };
}

export function fetchkampeerplaatsen() {
  return dispatch => {
    return fetch('/api/admin/standplaatsen/kampeerplaatsen', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set_kampeerplaatsen(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchkampeerplaats(id) {
  return dispatch => {
    return fetch('/api/admin/standplaatsen/kampeerplaatsen/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set_kampeerplaats(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function insertkampeerplaatsen(data) {
  return dispatch => {
    return fetch('/api/admin/standplaatsen/kampeerplaatsen', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set_kampeerplaats(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function updatekampeerplaatsen(id, data) {
  return dispatch => {
    return fetch('/api/admin/standplaatsen/kampeerplaatsen/'+id, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set_kampeerplaats(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}