import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import moment from "moment";
class FilterSensor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: true,
      gemiddelde: 0,
      max: 0,
      stand: 0,
      fases: [0, 0, 0],
    };
  }
  componentWillReceiveProps(nextprops) {
    if (!nextprops.loading) {
      this.setState({ loading: nextprops.loading, gemiddelde: (((nextprops.gemiddelde)).toFixed(2) ) });
      this.setState({ loading: nextprops.loading, max: (((nextprops.max.toFixed(2)))) });
      this.setState({ loading: nextprops.loading, stand: (((nextprops.stand.toFixed(2)))) });
    }
  }
  onSubmit(values) {
    this.props.changedata(values);
  }

  onchangeitem = (vals) => {
    const { handleSubmit } = this.props;
    this.setState({ loading: true });
    setTimeout(
      handleSubmit((params) => {
        this.onSubmit(params);
      })
    );
  };

  loading() {
    if (this.state.loading) {
      return <div className="col-md-3 col-sm-6 col-xs-12 loadingsearch">Gegevens worden geladen ...</div>;
    }
  }
  render() {
  
    return (
      <form onSubmit={this.onSubmit}>
        <div className="row container filter ">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <Field name="datum" type="number" component={renderDaterange} label="Kies een datum" onChange={this.onchangeitem} />
          </div>
          <div className="col-md-2 col-sm-6 col-xs-6">
            Gemiddelde<span className="gemiddelde">{this.state.gemiddelde} kWh</span>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-6">
            Hoogste Piek<span className="gemiddelde">{this.state.max} kWh</span>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-6">
            Laatste Meterstand<span className="gemiddelde">{this.state.stand}</span>
          </div>
        </div>
      </form>
    );
  }
}

FilterSensor = reduxForm({
  form: "filtersensor", // a unique identifier for this form
})(FilterSensor);

// You have to connect() to any reducers that you wish to connect to yourself
FilterSensor = connect((state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(7, "days"), enddate: new Date() },
  },
}))(FilterSensor);

export default FilterSensor;
