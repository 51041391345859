export const SET_CERTIFICATEN = 'SET_CERTIFICATEN';
export const ADD_CERTIFICATEN = 'ADD_CERTIFICATEN';
export const CERTIFICAAT_ERROR = 'CERTIFICAAT_ERROR';
export const SET_CERTIFICAAT = 'SET_CERTIFICAAT'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_CERTIFICATEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_CERTIFICAAT,
    results
  }
}
export function add(results) {
  return {
    type: ADD_CERTIFICATEN,
    results
  }
}
export function itemError(error) {
  return {
    type: CERTIFICAAT_ERROR,
    payload: error
  };
}

export function savecert(data) {
  var formData = new FormData();    
  formData.append("file", data.files);   
  formData.append("naam", data.naam);    
  return dispatch => {
    return fetch('/api/admin/trainingen/certificaat', {
      method: 'post',
      body: formData,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function fetchcertificaten() {
  return dispatch => {
    return fetch('/api/admin/trainingen/certificaat', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
