export const HORECA_ERROR = "HORECA_ERROR";
export const SET_HORECAS = 'SET_HORECAS';
export const ADD_HORECAS = 'ADD_HORECAS';
export const SET_HORECA = 'SET_HORECA'
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function itemError(error) {
  return {
    type: HORECA_ERROR,
    payload: error,
  };
}
export function set(results) {
  return {
    type: SET_HORECAS,
    results
  }
}


export function setone(results) {
  return {
    type: SET_HORECA,
    results
  }
}
export function savetafel(id, data) {
  return (dispatch) => {
    return fetch("/api/public/horeca/" + id, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem("gast"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(results);
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function gethoreca(id) {
  return dispatch => {
    return fetch('/api/public/horeca/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem("gast"),
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchhoreca(id) {
  return dispatch => {
    return fetch('/api/public/horecas/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem("gast"),
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
