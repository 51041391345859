import React from "react";
import { connect } from "react-redux";

import MessageSucces from "../../../Components/Forms/Validate/conform";
import MessageError from "../../../Components/Forms/Validate/error";
import {
  fetchbeoordelingcursisit,
  updatebeoordeling
} from "./Reducers/actions";
import AlgemeenForm from "./Components/algemeen";
import Kwailiteitenspel from "./Components/kwailieitsspel";
import PitchenForm from "./Components/piches";
import SportenSpelform from "./Components/sportenspel";
import KindertheaterForm from "./Components/kindertheater";
import Fun2Danceform from "./Components/fun2dance";

class TrainingPage extends React.Component {
  state = {
    errors: {},
    success: false,
    error: false,
    beoordelingen: {},
    loaded: false
  };
  componentWillMount() {
    this.props
      .fetchbeoordelingcursisit({
        cursist: this.props.cursist._id,
        training: this.props.training._id
      })
      .then(data => {
        if (data.results) {
          this.setState({ beoordelingen: data.results });
          this.setState({ loaded: true });
        }
      });
  }

  submit = vals => {
    const values = Object.assign({}, this.props.beoordelingen, vals, {
      cursist: this.props.cursist._id,
      training: this.props.training._id
    });
    this.props.updatebeoordeling(values).then(data => {
      if (data.type === "BEOORDELING_ERROR") {
        alert(data.payload);
        return false;
      } else {
        alert("De trainig is bijgewerkt");
      }
    });
  };

  setcounter = (counter, teller) => {
    if (counter) {
      if (Object.keys(counter).length === teller) {
        return <span className="pull-right badge bg-green">voltooid</span>;
      } else {
        return (
          <span className="pull-right badge bg-blue">
            {Object.keys(counter).length}/ {teller}
          </span>
        );
      }
    }
  };

  render() {
    const { beoordelingen } = this.props;  
    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Het profiel is goed opgeslagen"}
          success={this.state.success}
        />
        <MessageError
          tekst={"Er is iets fout gegaan"}
          error={this.state.error}
        />
        <div className="box-group" id="accordion">
          <div className="panel box box-primary">
            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#algemeen"
                >
                  <h3 className="box-title"> Algemeen </h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.algemeen, 9)}
            </div>
            <div id="algemeen" className="panel-collapse collapse in">
              <div className="box-body">
                <AlgemeenForm
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>

            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#kwailiteitenspel"
                >
                  <h3 className="box-title"> Kwaliteitenspel</h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.kwaliteitenspel, 3)}
            </div>
            <div id="kwailiteitenspel" className="panel-collapse collapse in">
              <div className="box-body">
                <Kwailiteitenspel
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>

            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#pitches"
                >
                  <h3 className="box-title"> Pitches</h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.pitches, 3)}
            </div>
            <div id="pitches" className="panel-collapse collapse in">
              <div className="box-body">
                <PitchenForm
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>

            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#sportenspel"
                >
                  <h3 className="box-title"> Sport en Spel</h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.sportenspel, 10)}
            </div>
            <div id="sportenspel" className="panel-collapse collapse in">
              <div className="box-body">
                <SportenSpelform
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>

            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#kindertheater"
                >
                  <h3 className="box-title"> Kindertheater</h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.kindertheater, 9)}
            </div>
            <div id="kindertheater" className="panel-collapse collapse in">
              <div className="box-body">
                <KindertheaterForm
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>

            <div className="box-header with-border">
              <h4 className="box-title">
                <a
                  data-toggle="collapse"
                  data-parent="#accordion"
                  href="#fun2dance"
                >
                  <h3 className="box-title"> Fun2Dance</h3>
                </a>
              </h4>
              {this.setcounter(beoordelingen.fun2dance, 3)}
            </div>
            <div id="fun2dance" className="panel-collapse collapse in">
              <div className="box-body">
                <Fun2Danceform
                  onSubmit={this.submit}
                  loaded={this.state.loaded}
                />
              </div>
            </div>
          </div>
        </div>       
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { beoordelingen: state.beoordelingen.item };
}

export default connect(mapStateToProps, {
  fetchbeoordelingcursisit,
  updatebeoordeling
})(TrainingPage);
