export const GASTEN_ERROR = 'GASTEN_ERROR';
export const SET_GASTEN = 'SET_GASTEN'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}


export function setone(results) {
  return {
    type: SET_GASTEN,
    results
  }
}
export function itemError(error) {
  return {
    type: GASTEN_ERROR,
    payload: error
  };
}


export function getactiviteit(id) {
  return dispatch => {
    return fetch('/api/public/activiteit/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function checkgast(data) {
  return dispatch => {
    return fetch('/api/public/gasten/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      }
    }).then(handleResponse).then(results => {
       dispatch(setone(results.results));
       return results
    }).catch((err) => {
      return dispatch(itemError(err));
    });
  };
}

export function checkgastmail(data) {
  return dispatch => {
    return fetch('/api/public/gasten/check', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      }
    }).then(handleResponse).then(results => {
       dispatch(setone(results.results));
       return results
    }).catch((err) => {
      return dispatch(itemError(err));
    });
  };
}
export function Sendmail(data) {
  return dispatch => {
    return fetch('/api/public/gasten/resend', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponse).then(results => {
      dispatch(setone(results.results));
      return true;
    }).catch(err => dispatch(itemError(err.statusText)));
  }
}

export function checktoken(data) {
  return dispatch => {
    return fetch('/api/public/gasten/checktoken', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch(err => dispatch(itemError(err.statusText)));
  }
}

export function gast() {
  return dispatch => {
    return fetch('/api/public/gasten/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
       dispatch(setone(results.results));
       return results
    }).catch((err) => {
      return dispatch(itemError(err));
    });
  };
}
