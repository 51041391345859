import { SET_BIEPP_BIEPP_ACTIVITEITEN, ADD_BIEPP_BIEPP_ACTIVITEITEN, BIEPP_ACTIVITEIT_ERROR, SET_BIEPP_ACTIVITEIT } from "./actions";
export default function biepp(
  state = {
    item: [],
    items: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_BIEPP_BIEPP_ACTIVITEITEN:
      return { ...state, items: [...state.itens, action.results] };
    case SET_BIEPP_ACTIVITEIT:
      return { ...state, item: action.results };
    case BIEPP_ACTIVITEIT_ERROR:
      return { ...state, error: action.payload };
    case SET_BIEPP_BIEPP_ACTIVITEITEN:
      return { ...state, items: action.results };

    default:
      return state;
  }
}
