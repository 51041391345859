export const SET_ACTIVITEITEN = 'SET_ACTIVITEITEN';
export const PUBLIC_ERROR = 'PUBLIC_ERROR';
export const SET_ACTIVITEIT = 'SET_ACTIVITEIT'
export const SET_BEDRIJF = 'SET_BEDRIJF'
export const SET_INSCHRIJVINGEN ='SET_INSCHRIJVINGEN'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_ACTIVITEITEN,
    results
  }
}
export function setinschrijving(results) {
  return {
    type: SET_INSCHRIJVINGEN,
    results
  }
}

export function setbedrijf(results) {
  return {
    type: SET_BEDRIJF,
    results
  }
}
export function setone(results) {
  return {
    type: SET_ACTIVITEIT,
    results
  }
}
export function itemError(error) {
  return {
    type: PUBLIC_ERROR,
    payload: error
  };
}

export function getbedrijf(id) {
  return dispatch => {
    return fetch('/api/public/bedrijf/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
      }
    }).then(handleResponse).then(results => {
      return dispatch(setbedrijf(results.bedrijf));
    }).catch((err) => {
        
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchactiviteiten(id) {
  return dispatch => {
    return fetch('/api/public/activiteiten/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function fetchinschrijvingen(id) {
  return dispatch => {
    return fetch('/api/public/inschrijvingen/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setinschrijving(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function getactiviteit(id) {
  return dispatch => {
    return fetch('/api/public/activiteit/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function deleteinschrijving(id) {
  return dispatch => {
    return fetch('/api/public/inschrijvingen/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(results.results);
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function savinschrijving(id, data) {
  return dispatch => {
    return fetch('/api/public/inschrijving/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(results.results);
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
