import React from "react";
import { connect } from "react-redux";
import { fetchcuristen } from "./Reducers/actions";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import LijstTrainingen from "./Components/trainingen"
import {addcursisttotraining} from '../Trainingen/Reducers/actions'

class LogboekPage extends React.Component {
  state = {
    checkedItems: []
  };

  componentWillMount() {
    this.getvals({});
  }

  submit  = vals => {
    
    this.props.addcursisttotraining(vals.training ,{"cursisten":this.state.checkedItems}).then(data => {
 
      if (data.type ==="TRAINING_ERROR") {
        return false;
      } else {
            this.props.history.push('/admin/trainingen');
      }
    });
    
  }
  handleChange(label) {
    if (this.state.checkedItems.includes(label)) {
      this.setState({
        checkedItems: this.state.checkedItems.filter(function(person) {
          return person !== label;
        })
      });
    } else {
      this.setState({ checkedItems: [...this.state.checkedItems, label] });
    }
  }

  getvals = vals => {
    this.props.fetchcuristen(vals).then(data => {});
  };


  filterbedrijf = (cell, row) => {
    const result = this.props.bedrijven.find(tree => tree._id === cell);
    if (result) return result.naam;
  };
  selectcursist = (cell, row) => {
    return (
      <input
        type="checkbox"
        name={cell}
        onChange={this.handleChange.bind(this, cell)}
      />
    );
  };


  render() {
    const columns = [
      {
        dataField: "_id",
        text: "",
        formatter: this.selectcursist,
        headerStyle: (colum, colIndex) => {
          return { width: "50px" };
        }
      },
      {
        dataField: "voornaam",
        text: "Voornaam",
        sort: true,
        filter: textFilter()
      },
      {
        dataField: "achternaam",
        text: "Achternaam"
      },
      {
        dataField: "bedrijf.naam",
        text: "Bedrijf",
        sort: true,
        filter: textFilter()
      },
      {
        dataField: "geboortedatum",
        text: "",
        formatter: (cellContent, row) => {
          if (row.ervaring ==="1") {
            return <span className='label label-success'>Eerste jaars</span>
          }
          if (row.ervaring ==="2") {
            return <span className='label label-warning'>Meerderjaars</span>
          }
        },
        headerStyle: (colum, colIndex) => {
          return { width: "50x" };
        }
      },
      {
        dataField: "ervaring",
        text: "Toevoegen",
        formatter: (cellContent, row) => {
          return (
            <span>
              {" "}
              <Link
                className="btn btn-success"
                to={{ pathname: "/admin/trainingen/cursist/" + row._id }}
              >
                Bekijken
              </Link>
            </span>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "100px" };
        }
      }
    ];
    const { cursisten, username } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Cursisten</h3>
          <div className="box-tools pull-right">
          {username.role !=="user" &&
            <Link
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
              to="/admin/trainingen/cursisten/insert"
            >
              <i className="fa fa-plus"></i>&nbsp; Cursist Toevoegen
            </Link>
  }
          </div>
        </div>
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile" />
            <BootstrapTable
              keyField="_id"
              data={cursisten}
              columns={columns}
              filter={ filterFactory()}
              pagination={paginationFactory({ sizePerPage: 50})}
            />
          </div>
          {username.role !=="user" &&
          <LijstTrainingen onSubmit={this.submit}/>}</div>
    
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.auth.user,
    cursisten: state.cursisten.items
  };
}

export default connect(mapStateToProps, { fetchcuristen, addcursisttotraining })(
  LogboekPage
);
