import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import {
  renderField,
} from "../../../../Components/Forms/renders";
import { lang } from "../../../../Components/Lib/Languages/language";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  componentDidMount () {
      if (this.props.email) {
        this.props.change('email', this.props.email);
      }
  }

  render() {
    const { handleSubmit } = this.props;    
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
      
          <Field
            name="email"
            type="email"
            validate={required({ msg: lang("Dit item is verplicht") })}
            component={renderField}
            label={lang("Vul je e-mailadres in")}
          />
             <Field
            name="naam"
            type="input"
            validate={required({ msg: lang("Dit item is verplicht") })}
            component={renderField}
            label={lang("Vul je familienaam in")}
          />
          <div className="submitform">
          <input
            type="submit"
            className="btn btn-next btn-fill btn-success btn-wd btn-mg"
            name="next"
            value={lang("Controleer e-mailadres")}
          />
          </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  () => ({
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
