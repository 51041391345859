import React, { Component } from "react";
import Dropzone from "react-dropzone";

class UploadFrom extends Component {
  constructor() {
    super();
    this.onDrop = (file) => {
      let { value } = this.state;
      value.push(...file);
      this.setState({ value });
      this.props.input.onChange(value);
    };
    this.state = {
      value: [],
      bestanden: [],
    };
  }
  componentDidMount() {
    if (this.props.input.value) {
      this.setState({ value: this.props.input.value });
    }
  }

  deletefile = (key) => {
    console.log(key);
  };

  render() {
    const value = this.state.value.map(function (file, key) {
      if (file.filename) {
        return (
          <li className="upload uploaded" key={key}>
            {file.filename} - {file.size} bytes
            <span>
              <button type="button">delete</button>
            </span>
          </li>
        );
      }
      else if (file.name) {
        return (
          <li className="upload" key={key}>
            {file.name} - {file.size} bytes
            <span>
              <button type="button">delete</button>
            </span>
          </li>
        );
      } else {
        return null;
      }
    });

    const {
      label,
      meta: { touched, error, warning },
    } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div>
          <Dropzone multiple={true} onDrop={this.onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "form-control" })}>
                  <input {...getInputProps()} />
                  <p>Klik hier om bestanden toe te voegen</p>
                </div>
                {value.length > 0 && (
                  <aside>
                    <h5>Bestanden</h5>
                    <ul className="uploadfiles">{value}</ul>
                  </aside>
                )}
              </section>
            )}
          </Dropzone>
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default UploadFrom;
