import React from "react";
import { connect } from "react-redux";
import { update, getone } from "./Reducers/actions";
import GebruiksInsert from "./Components/insert";
import { signupUser } from "../../Reducers/auth/actions";
import { message } from "../../Reducers/loading/actions";
class GebruikersInsertPage extends React.Component {
  state = {
    errors: {},
    loaded: true,
  };

  componentWillMount() {}

  submit = (vals) => {
    const values = Object.assign({}, { bedrijf: this.props.username.bedrijf }, vals);
    values.telefoonnummer.replace(" ", "").replace("+", "");
    this.props.signupUser(values).then((data) => {
      if (data.type === "auth_error") {
        this.props.message("3000", "danger", data.payload);
      } else {
        this.props.message("3000", "success", "Het account is met succes aangemaakt. De nieuwe gebruiker ontvangt nu een e-mail").then(data => {
          this.props.history.push('/gebruikers/');
        })
      }
    });
  };

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Gebruiker toevoegen</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">{this.state.loaded && <GebruiksInsert onSubmit={this.submit} />}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, bedrijf: state.bedrijven.item, gebruiker: state.mgebruukers.item };
}

export default connect(mapStateToProps, { update, getone, signupUser, message })(GebruikersInsertPage);
