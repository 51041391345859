import { Link } from "react-router-dom";
import React, { useState } from 'react';
const Activiteitensidebar = ({token, username}) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("activiteiten")) {
    if (
      token.role === "admin" ||
      token.role === "manager"
    ) {
      return (
        <li className="treeview">
        <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fa fa-book" />
            <span>Activiteiten</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "} >
            <li>
              <Link to="/activiteiten" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" />
                <span>Activiteiten met inschrijving</span>
              </Link>
            </li>
            <li>
              <Link to="/activiteiten/show/all" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" />
                <span>Alle Activiteiten</span>
              </Link>
            </li>
            <li>
              <Link to="/activiteiten/insert/website" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-book" />
                <span>Toevoegen Website</span>
              </Link>
            </li>
          </ul>
        </li>
      );
    } else {
      return null
    }
  } else {
    return null
  }
  };
  export default Activiteitensidebar