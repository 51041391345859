import React from "react";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../Components/Forms/renders";
import tijdField from "../../../Components/Forms/time";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit, datum } = this.props;
    const startdatum = moment(datum, "WW-YYYY").startOf("week").format("DD-MM-YYYY");
    const eindatum = moment(datum, "WW-YYYY").endOf("week").format("DD-MM-YYYY");

    return (
      <form className="weekform" onSubmit={handleSubmit}>
        <div className="box-body box-profile inschrijving">
          Starten <span>{startdatum}</span>
          Eindigen <span>{eindatum}</span>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Field name="starttijd" type="input" validate={required({ msg: "Dit item is verplicht" })} component={tijdField} label="Starttijd" />
          </div>
          <div className="col-md-3">
            <Field name="eindtijd" type="input" validate={required({ msg: "Dit item is verplicht" })} component={tijdField} label="Eindtijd" />
          </div>
          <div className="col-md-3">
            <Field name="tijdsloten" type="input" validate={required({ msg: "Dit item is verplicht" })} component={renderField} label="Tijdsloten" />
          </div>
          <div className="col-md-3">
            <input type="submit" className="btn btn-next btn-fill btn-warning btn-wd btn-mg weekformsubmit" name="next" value="Reset week" />
          </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "weekform", // a unique identifier for this form
  enableReinitialize: true,
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state, props) => ({
    initialValues: props.week, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
