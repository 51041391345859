import React from "react";
import { connect } from "react-redux";
import { updatelogboek, getlogboek, dellogboek } from "./Reducers/actions";
import {message} from "../../Reducers/loading/actions"
import LogboekEditForm from "./Components/editform";
class LogboekEditPage extends React.Component {
  state = {
    errors: {},
    success: false,
    loaded: false
  };
  componentDidMount() {
    this.setState({ loaded: false });
        this.props.getlogboek(this.props.match.params.id).then(data => {
            if (data.results) {
                this.setState({ loaded: true });
            }
            else {
                this.props.message(2000, "warning", "Er is een fout opgtreden bij het laden van de activititeit").then(data => {
                    this.props.history.push("/logboek");
              })
            }
    });
  }
  submit = vals => {
    this.props.updatelogboek(this.props.match.params.id, vals).then(data => {
      if (data.type === "LOGBOEK_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is met succes aangepast").then(data => {
            this.props.history.push("/logboek");
      })
    }
    });
  };
  verwijderen =() => {
    this.props.dellogboek(this.props.match.params.id).then(data => {
        if (data.type === "LOGBOEK_ERROR") {
          alert(data.payload);
          return false;
        } else {
            this.props.message(2000, "success", "De activiteit is met succes verwijderd").then(data => {
                this.props.history.push("/logboek");
          })
        }
      });
    };

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Logboek wijzigen</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                  {this.state.loaded &&
                <LogboekEditForm verwijderen={this.verwijderen} onSubmit={this.submit} />
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, logboek: state.logboeken.item };
}

export default connect(
  mapStateToProps,
  { updatelogboek, getlogboek, dellogboek, message }
)(LogboekEditPage);
