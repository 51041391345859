import React from "react";
import { connect } from "react-redux";
import { fetchsensorenelectra } from "../Reducers/actions";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../../../Components/loading/load";
import { Sparklines, SparklinesLine } from "react-sparklines";
class SensorIndex extends React.Component {
  state = {
    errors: {},
    load: false,
  };

  componentWillMount() {
    this.getvals();
    this.timerID = setInterval(() => this.getvals({}), 60000);
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  getvals = (vals) => {
    this.props.fetchsensorenelectra(vals).then((data) => {
      this.setState({ load: true });
    });
  };

  getkwh = (data) => {
    var kwh = 0;
    if (data && data.soort === "ampere") {
      kwh = ((data.data[0].value / 1000) * 690) / 1000;
    } else if (data && data.soort === "electra" && data.data.length > 1) {
      kwh = data.data[0].value - data.data[1].value;
    }

    return kwh.toFixed(2);
  };

  render() {
    function luodate(data) {
      if (data[0] && data[0].datum) {
        return moment(data[0].datum).fromNow();
      } else {
        return null;
      }
    }
    const { sensoren } = this.props;
    function getdata(row) {
      const data = [];
      row.data.map(function (a, key) {
        if (key > 0) {
          data.push(Number(a.value) - Number(row.data[key - 1].value));
        }
      });
      return data;
    }
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Sensoren electra</h3>
          <div className="box-tools pull-right"></div>
        </div>
        <div className="col-md-12 no-float sensorkwh">
          <div className="box box-primary">
            <div className="box-body" />
            <Loading loadings={this.state.load} />
            {this.state.load && (
              <div className="row">
                {sensoren.map((sensor, key) => (
                  <div key={key} className="col-lg-3 sensoritem">
                    <div key={key} className="tempsensor card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">{sensor.naam}</h3>
                      </div>
                      <Sparklines height={120} data={getdata(sensor)}>
                        <SparklinesLine color="#d96a29" />
                      </Sparklines>
                      <span className="valuekwh"> {this.getkwh(sensor)} KwH</span>
                      <div className="details">Laatste meting {luodate(sensor.data)}</div>
                      <div className="card-footer">
                        {sensor.soort == "ampere" && (
                          <Link className="small-box-footer" to={{ pathname: "/sensoren/sensor/amp/show/" + sensor._id }}>
                            Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                          </Link>
                        )}
                        {sensor.soort == "electra" && (
                          <Link className="small-box-footer" to={{ pathname: "/sensoren/sensor/electra/show/" + sensor._id }}>
                            Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { sensoren: state.sensoren.items };
}

export default connect(mapStateToProps, { fetchsensorenelectra })(SensorIndex);
