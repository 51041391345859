import { Link } from "react-router-dom";
import React, {useState} from "react";
const Faciliteitensidebar = ({token, username}) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("faciliteiten")) {
    if (
      token.role === "admin" ||
      token.role === "manager" ||
      token.role === "medewerker"
    ) {
      return (
        <li className="treeview">
             <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
            <i className="fa fa-warehouse" />
            <span>Faciliteiten</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "} >
            <li>
              <Link to="/faciliteiten" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-warehouse" />
                <span>Faciliteiten</span>
              </Link>
            </li>
            <li>
              <Link to="/horeca" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-utensils" />
                <span>Horeca</span>
              </Link>
            </li>
          </ul>
        </li>
      );
    } else {
      return null
    }
  } else {
    return null
  }
  };
  export default Faciliteitensidebar
