import React from "react";
import { connect } from "react-redux";
import { fetchlogboeken } from "./Reducers/actions";
import {fetchbedrijven} from "../Admin/Bedrijven/Reducers/actions";
import moment from "moment";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SerachLogboekPage from "./Components/searchheader";
import TellerComponent from "./Components/counters";
class LogboekPage extends React.Component {
  state = {
    errors: {},
    deelnemers: 0,
    hulpkrachten: 0,
    recreatieleider: 0,
    stagiaires: 0,
    totaal: 0,
    gemiddeld: 0,
    loading: true
  };

  componentWillMount() {
    this.getvals({});
    this.props.fetchbedrijven()
  }

  getvals = vals => {
    this.setState({loading: true})
    vals.jaar = this.props.jaar;
    this.props.fetchlogboeken(vals).then(data => {
      this.setState({
        deelnemers: 0,
        recreatieleider: 0,
        hulpkrachten: 0,
        stagiaires: 0,
        totaal: 0
      });
      if (data.results && data.results.length > 0) {
        data.results.map(app => this.handleItem(app, data.results.length));
      }
      this.setState({loading: false})
    });
  };

  handleItem(app, teller) {
    this.setState({
      totaal: parseInt(teller,0)
    });
    if (app.deelnemers) {
      this.setState({
        deelnemers: this.state.deelnemers + parseInt(app.deelnemers,0)
      });
    }
    if (app.recreatieleider) {
      this.setState({
        recreatieleider:
          this.state.recreatieleider + parseInt(app.recreatieleider,0)
      });
    }
    if (app.hulpkrachten) {
      this.setState({
        hulpkrachten: this.state.hulpkrachten + parseInt(app.hulpkrachten,0)
      });
    }
    if (app.stagiaires) {
      this.setState({
        stagiaires: this.state.stagiaires + parseInt(app.stagiaires,0)
      });
    }
  }

  render() {
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "datum",
        text: "datum",
        formatter: priceFormatter
      },
      {
        dataField: "activiteit",
        text: "Naam activiteit"
      },
      {
        dataField: "soortactiviteit",
        text: "Soort activiteit"
      },
      {
        dataField: "deelnemers",
        text: "Deelnemers"
      },
      {
        text: "Acties",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
          <span>
            <Link className="btn btn-success btn-sm" to={"/logboek/edit/"+row._id }>Wijzigen</Link>
            </span>
          )
        },
        headerStyle: (colum, colIndex) => {
         return { width: '100px' };
       }
      }
    ];
    const { logboeken, bedrijven, token, jaar } = this.props;
    var med = this.state.hulpkrachten + this.state.stagiaires + this.state.recreatieleider;
    var gemiddelde = parseFloat(this.state.deelnemers / med).toFixed(1);
    var gemiddeelnemeracti = parseFloat(this.state.deelnemers / this.state.totaal).toFixed(1);
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Logboeken {jaar}</h3>
        </div>

        <SerachLogboekPage loading= {this.state.loading} token= {token} getvals={this.getvals} bedrijven={bedrijven} showbedrijf={true} />
        <div className="box box-default">
          <div className="box-header with-border">
            <h3 className="box-title"> Totalen</h3>
          </div>
          <div className="row">
              <TellerComponent icon="fa fa-running" naam="Activiteiten" teller={this.state.totaal} />
            <TellerComponent icon="fa fa-users" naam="Aantal deelnemers" teller={this.state.deelnemers} />
              <TellerComponent icon="fa fa-users" naam="Gem. per activiteit" teller={gemiddeelnemeracti} />
            <TellerComponent
            icon="fa fa-users"
              naam="Recreatieleider"
              teller={this.state.recreatieleider}
            />
            <TellerComponent
            icon="fa fa-users"
              naam="Hulpkrachten"
              teller={this.state.hulpkrachten}
            />
            <TellerComponent icon="fa fa-users" naam="stagiaires" teller={this.state.stagiaires} />
            <TellerComponent icon="fa fa-users" naam="Gem. Begeleiding" teller={gemiddelde} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile" />
              <BootstrapTable
                keyField="_id"
                data={logboeken}
                columns={columns}
                pagination={paginationFactory()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { username: state.auth.user, logboeken: state.logboeken.items, bedrijven: state.bedrijven.items,   jaar: state.logboeken.jaar , token: state.auth.token };
}

export default connect(
  mapStateToProps,
  { fetchlogboeken, fetchbedrijven }
)(LogboekPage);
