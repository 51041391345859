import React from "react";
import { connect } from "react-redux";
import { saveactiviteiten } from "./Reducers/actions";
import LogboekForm from "./Components/insertform";
import {message} from "../../Reducers/loading/actions"
import { getonewebsite } from "../Website/Reducers/actions";
class LogboekInsertPage extends React.Component {
  state = {
    errors: {},
    load: false,
  };

  componentWillMount() {    
    this.props.getonewebsite(this.props.match.params.id).then((data) => {
      if (data.results) {
        this.setState({ load: true });
      }
    });
  }

  submit = (vals) => {
    this.props.saveactiviteiten(vals).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is succesvol verwerkt").then(data => {
          this.props.history.push("/activiteiten/insert/website");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default maincontent">
        <div className="box-header with-border">
          <h3 className="box-title"> Activiteit toevoegen </h3>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                {this.state.load && <LogboekForm onSubmit={this.submit} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    logboeken: state.logboeken,
    website: state.website.item,
  };
}

export default connect(mapStateToProps, { saveactiviteiten, getonewebsite, message })(
  LogboekInsertPage
);
