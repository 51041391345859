import { Link } from "react-router-dom";
import React, { useState } from "react";
const StansplaatsSidebar = ({ token, username }) => {
  const [isActive, setIsActive] = useState(false);
  if (username.bedrijf && username.bedrijf.modules.includes("standplaatsen")) {
    if (token.role === "admin" || token.role === "manager" || token.role === "medewerker") {
      function addkampeerplaats() {
        if (token.role === "admin" || token.role === "manager") {
          return (
            <li>
              <Link to="/standplaatsen/kampeerplaatsen/insert" onClick={() => setIsActive(!isActive)}>
                <i className="fa fa-search" />
                <span>Kampeerplaats toevoegen</span>
              </Link>
            </li>
          );
        }
      }

      return (
        <li className="treeview">
          <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
          <i className="fas fa-campground"></i> <span>Standplaatsen</span>
            <span className="pull-right-container">
              <i className="fa fa-angle-left pull-right" />
            </span>
          </a>
          <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
            <li>
              <Link to="/standplaatsen/kampeerplaatsen/" onClick={() => setIsActive(!isActive)}>
              <i className="fas fa-caravan"></i> <span>Kampeerplaatsen</span>
              </Link>
            </li>
            {addkampeerplaats()}
          </ul>
        </li>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default StansplaatsSidebar;
