import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import {
  renderField,
  renderselectapiusers,
  renderselectapi
} from "../../../../Components/Forms/renders";
import date from "../../../../Components/Forms/date";
import { fetchusers } from "../../../../Reducers/auth/actions";
import { fetchcertificaten } from "../../Certificaat/Reducers/actions";
class CuristenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  componentDidMount() {
    if (!this.props.initialValues._id) this.props.initialize();
    this.props.fetchcertificaten().then(data => {});
    this.props.fetchusers().then(data => {});
   
  }
  render() {
    const { handleSubmit, users, certificaten } = this.props;

    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field
                  name="titel"
                  type="input"
                  validate={[required()]}
                  component={renderField}
                  label="Titel van de training"
                />
                <Field
                  name="datum"
                  type="input"
                  validate={[required()]}
                  component={date}
                  label="Datum"
                />
                <Field
                  name="trainer"
                  type="input"
                  component={renderselectapiusers}
                  options={users}
                  label="Kies de trainer"
                />
                <Field
                  name="certificaten"
                  type="input"
                  component={renderselectapi}
                  options={certificaten}
                  label="Kies een certificaat"
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input
                type="submit"
                className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                name="next"
                value="Wijzigen"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CuristenForm = reduxForm({
  form: "cursstinsert" // a unique identifier for this form
})(CuristenForm);

// You have to connect() to any reducers that you wish to connect to yourself
CuristenForm = connect(
  state => ({
    initialValues: state.trainingen.item, // pull initial values from account reducer
    users: state.auth.users,
    certificaten: state.certificaten.items
  }),
  { fetchusers, fetchcertificaten }
)(CuristenForm);

export default CuristenForm;
