import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import {
  renderField,
  renderselect,
  renderselectapi
} from "../../../../Components/Forms/renders";
import mask from "../../../../Components/Forms/mask"
import { fetchbedrijven } from "../../../Admin/Bedrijven/Reducers/actions";
class CuristenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  componentDidMount() {
 
    this.props.fetchbedrijven().then(data => {});
  }
  render() {
    const { handleSubmit, bedrijven } = this.props;

    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field
                  name="voornaam"
                  type="input"
                  validate={[required()]}
                  component={renderField}
                  label="Voornaam"
                />
                <Field
                  name="achternaam"
                  type="input"
                  validate={[required()]}
                  component={renderField}
                  label="Achternaam"
                />
                <Field
                  name="email"
                  type="input"
                  component={renderField}
                  label="E-mailadres"
                />
                   <Field
                  name="geboortedatum"
                  type="input"
                  component={mask}
                  mask={"99/99/9999"}
                  label="Geboortedatum"
                />
                <Field
                  name="ervaring"
                  type="input"
                  component={renderselect}
                  validate={[required()]}
                  options={{
                    1: "Eerste jaars",
                    2: "Meerderjaars"
                  }}
                  label="Eerste of meederjaars"
                />
                <Field
                  name="bedrijf"
                  type="input"
                  component={renderselectapi}
                  options={bedrijven}
                  label="Kies het bedrijf"
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input
                type="submit"
                className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                name="next"
                value="Aanmaken"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CuristenForm = reduxForm({
  form: "cursstinsert" // a unique identifier for this form
})(CuristenForm);

// You have to connect() to any reducers that you wish to connect to yourself
CuristenForm = connect(
  state => ({
    bedrijven: state.bedrijven.items
  }),
  { fetchbedrijven }
)(CuristenForm);

export default CuristenForm;
