import React from "react";
import { connect } from "react-redux";
import { update, getone } from "./Reducers/actions";
import BedrijvenForm from "./Components/edit";
import { fetchbedrijven } from "../Bedrijven/Reducers/actions";
class GebruikersEditPage extends React.Component {
  state = {
    errors: {},
    loaded: false,
  };

  componentWillMount() {
    this.setState({ loaded: false });
    this.props.getone(this.props.match.params.id).then((data) => {
      this.props.fetchbedrijven().then((bedrij) => {
        this.setState({ loaded: true });
      });
    });
  }

  submit = (vals) => {
    this.props.update(this.props.match.params.id, vals).then((data) => {
      if (data.type === "GEBRUIKER_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.history.push("/admin/gebruikers/");
      }
    });
  };

  render() {
    const {username} = this.props
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Gebruiker wijzigen</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                {this.state.loaded && <BedrijvenForm bedrijven={this.props.bedrijven} username={username} onSubmit={this.submit} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, gebruiker: state.gebruikers.item, bedrijven: state.bedrijven.items };
}

export default connect(mapStateToProps, { update, getone, fetchbedrijven })(GebruikersEditPage);
