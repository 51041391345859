import { SET_SENSOREN, ADD_SENSOREN, SENSOR_ERROR, SET_SENSOR, SET_SENSOR_DAG, SET_SENSOR_UUR, SET_SENSOR_MAAND } from "./actions";
export default function cats(
  state = {
    item: [],
    items: [],
    uur: [],
    maand: [],
    dag: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_SENSOREN:
      return { ...state, items: [...state.items, action.results] };
    case SET_SENSOR:
      return { ...state, item: action.results };
    case SET_SENSOR_UUR:
      return { ...state, uur: action.results };
    case SET_SENSOR_DAG:
      return { ...state, dag: action.results };
    case SET_SENSOR_MAAND:
      return { ...state, maand: action.results };
    case SENSOR_ERROR:
      return { ...state, error: action.payload };
    case SET_SENSOREN:
      return { ...state, items: action.results };
    default:
      return state;
  }
}
