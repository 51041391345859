import React, { Component } from "react";
import { connect } from "react-redux";
import { message, clearmessage, fixmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { gethoreca } from "./Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
import { savetafel } from "./Reducers/actions";
import Tafelform from "./Components/insert";

class FacilitetenPage extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
    loadform: true,
  };

  componentWillMount() {
    this.props.gethoreca(this.props.match.params.id).then((data) => {
      if (data.results) {
        if (!this.props.bedrijf._id) {
          this.props.getbedrijf(data.results.bedrijf).then((bdata) => {
            this.setState({ loaded: true });
          });
        }
        this.setState({ loaded: true });
      }
    });
  }
  componentWillUnmount() {
    this.props.clearmessage();
  }

  submit = (vals) => {
    this.setState({ loadform: false });
    this.props.savetafel(this.props.match.params.id, vals).then((data) => {
      if (data.type === "PUBLIC_ERROR") {
        alert(data.payload);
        this.setState({ loaded: true });
        return false;
      } else {
        this.props.fixmessage("success", lang("Je registratie is succesvol verwerkt")).then((data) => {
          this.props.history.push("/public/horeca/" + this.props.bedrijf._id);
        });
      }
    });
  };
  render() {
    const {horeca} = this.props
    return (
      <div className="public-box-body">
        <h2 className="headline text-yellow facheadline"> {lang("Tafel aanmelden")}</h2>
        <Loading loading={this.state.loaded} />
        {this.state.loaded && (
          <div className="inschrijf-content">
            <div className="gastinfo">
            <h4>{horeca.naam}</h4>
           {horeca.beschrijving}
            </div>
            {this.state.loadform && <Tafelform onSubmit={this.submit} />}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    faciliteiten: state.openfaciliteiten.items,
    horeca: state.horeca.item,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getbedrijf,
  savetafel,
  clearmessage,
  gethoreca,
})(FacilitetenPage);
