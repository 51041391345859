export const SET_HORECAS = 'SET_HORECAS';
export const ADD_HORECAS = 'ADD_HORECAS';
export const HORECA_ERROR = 'HORECA_ERROR';
export const SET_HORECA = 'SET_HORECA'
export const SET_RESERVERINGEN ='SET_RESERVERINGEN'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_HORECAS,
    results
  }
}

export function setreserveringen(results) {
  return {
    type: SET_RESERVERINGEN,
    results
  }
}

export function setone(results) {
  return {
    type: SET_HORECA,
    results
  }
}
export function add(results) {
  return {
    type: SET_HORECA,
    results
  }
}
export function itemError(error) {
  return {
    type: HORECA_ERROR,
    payload: error
  };
}

export function savehoreca(data) {
  return dispatch => {
    return fetch('/api/admin/horeca', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function updatehoreca(id, data) {
  return dispatch => {
    return fetch('/api/admin/horeca/'+id, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function delhoreca(id) {
  return dispatch => {
    return fetch('/api/admin/horeca/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return true
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function gethoreca(id) {
  return dispatch => {
    return fetch('/api/admin/horeca/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchhoreca() {
  return dispatch => {
    return fetch('/api/admin/horeca/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function savereververing(data) {    
  return dispatch => {
    return fetch('/api/admin/horeca/reservering/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return results;
    }).catch((err) => {
      console.log(err);
      
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function fetchreserveringen(data) {
  return dispatch => {
    return fetch('/api/admin/horeca/reserveringen', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setreserveringen(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function deletereservering(id) {
  return dispatch => {
    return fetch('/api/admin/horeca/reservering/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(results.results);
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
