import React from "react";
import { connect } from "react-redux";
import {fetchactiviteitenmetinschrijving} from "./Reducers/actions"
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { message } from "../../Reducers/loading/actions";
class InschrijvingPage extends React.Component {
  state = {
    errors: {},
    loading: false
  };

  componentWillMount() {
    this.getvals({});
    //this.getcount({});
  }

  getvals = () => {
    this.props.fetchactiviteitenmetinschrijving().then((data) => {
      if (data.type !== "ACTIVITEIT_ERROR") {
        this.setState({loading: false})
      }
    });
  };


  handleItem(item) {
    this.props.Verwerken({ id: item._id }).then((datav) => {
      this.getvals({});
    });
  }

  render() {
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "datum",
        text: "datum",
        formatter: priceFormatter,
      },
      {
        dataField: "activiteit",
        text: "Naam activiteit",
        formatter: (cellContent, row) => {
            if (cellContent.NL) {
              return cellContent.NL
            } else {
              return cellContent
            }
        }
      },
      {
        dataField: "inschrijving",
        text: "Inschrijving",
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {       
          return (
            <span>
               <Link
                className="btn btn-warning"
                to={{ pathname: "/activiteiten/edit/"+row._id}}
              >
                Wijzigen
              </Link>
              <Link
                className="btn btn-success"
                to={{ pathname: "/activiteiten/"+row._id}}
              >
                Bekijken
              </Link>
            </span>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "250px" };
        },
      },
    ];
    const { activiteiten } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Activiteiten met inschrijving</h3>
        </div>
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12 no-float">
              <div className="box box-primary">
                <div className="box-body box-profile" />
                <BootstrapTable
                  keyField="_id"
                  data={activiteiten}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.auth.user,
    activiteiten: state.activiteiten.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fetchactiviteitenmetinschrijving
})(InschrijvingPage);
