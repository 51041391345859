import React, { Component } from "react";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
class timeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  componentDidMount() {
    if (this.props.input.value) {
      this.setState({ value: this.props.input.value });
    }
  }

  handleSelect = (value) => {
    this.setState({ value });
    this.props.input.onChange(value);
  };
  render() {
    const { label, error, touched, warning } = this.props;
    return (
      <div>
        {label && <label>{label}</label>}
        <div>
          <TagsInput
            value={this.state.value}
            onChange={this.handleSelect}
            inputProps={{
              placeholder: label,
            }}
          />
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default timeSelect;
