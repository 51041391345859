import React, { Component } from "react";
import Multiselect from "react-widgets/Multiselect";
import "react-widgets/scss/styles.scss";
class MultiSelectDagen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      options: []
    };
  }

  componentDidMount() {
    if (this.props.input.value) {
      this.setState({ value: this.props.input.value });
    } else {
      this.setState({value: []})
    }
    if (this.props.options) {
      this.setState({ options: this.props.options });
    }
  }
  componentWillUnmount() {
    this.setState({value: []})
  }

  handleCreate(name) {
    let { options, value } = this.state;
    this.setState({
      value: [...value, name],  // select new option
      options: [...options, name] // add new option to our dataset
    })
  }

  handleSearch(evt) {

  }

  handleSelect = (value) => {
    this.setState({ value });
    this.props.input.onChange(value);
  };
  render() {
    const { label, error, touched, warning } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div>
          <Multiselect
           allowCreate="onFilter"
           onCreate={(name) => this.handleCreate(name)}
            data={this.state.options}
            onSearch={this.handleSearch}
            onChange={this.handleSelect}
            value={this.state.value}
            defaultValue={[]}
          />
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default MultiSelectDagen;
