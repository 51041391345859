import React, { Component } from "react";
import { Route } from "react-router-dom";
import InschrijOverzichtPage from "./website";
import Activitieten from "./index"
import InsertWebsite from "./insert"
import ManualInsert from './insertmanual'
import Activteitedit from "./edit"
import Activitiet from "./activiteit"
import ActiviteitenMetinschrijving from "./alleactiviteiten"
class Inschrijvingen extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/activiteiten/" component={ActiviteitenMetinschrijving} />
        <Route exact path="/activiteiten/show/all" component={Activitieten} />
        <Route exact path="/activiteiten/:id" component={Activitiet} />
        <Route exact path="/activiteiten/insert/website" component={InschrijOverzichtPage} />
        <Route exact path="/activiteiten/insert/manual" component={ManualInsert} />
        <Route exact path="/activiteiten/edit/:id" component={Activteitedit} />
        <Route exact path="/activiteiten/website/:id" component={InsertWebsite} />
  
      </React.Fragment>
    );
  }
}

export default Inschrijvingen;
