import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { translate } from "../../../../Components/Lib/Languages/language";
moment.locale("nl");
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  render() {
    const { activiteit, link } = this.props;
    if (link && !activiteit.ingeschreven) {
      return (
        <Link
          className="activiteit-link"
          to={{
            pathname: "/public/inschrijvingen/activiteit/" + activiteit._id,
          }}
        >
          <div className="activiteit-box-body">
            <span className="activiteit-time">{activiteit.tijd}</span>
            <h3 className="activiteit-titel">{translate(activiteit.activiteit)}</h3>
            {translate(activiteit.beschrijving)}
          </div>
          <div className="activiteit-arrow">
            <i className="fa fa-chevron-right"></i>
          </div>
        </Link>
      );
    } else if (activiteit.ingeschreven) {
      return (
        <div className="activiteit-link">
          <div className="activiteit-box-body">
            <span className="activiteit-time">{activiteit.tijd}</span>
            <h3 className="activiteit-titel">{translate(activiteit.activiteit)}</h3>
            {translate(activiteit.beschrijving)}
          </div>
            <div className="activiteit-arrow">
              <i className="fa fa-check-square"></i>
            </div>
        </div>
      );
    } else {
      return (
        <div className="activiteit-link">
          <div className="activiteit-box-body">
            <span className="activiteit-time">{activiteit.tijd}</span>
            <h3 className="activiteit-titel">{translate(activiteit.activiteit)}</h3>
            {translate(activiteit.beschrijving)}
          </div>
            <div className="activiteit-arrow">
              <i className="fa fa-check-check"></i>
            </div>
        </div>
      );
    }
  }
}

export default Inschrijven;
