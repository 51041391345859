import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField } from "../../../../Components/Forms/renders";
import timeSelect from "../../../../Components/Forms/tags";
import Time from "../../../../Components/Forms/time";
class AlertProfileform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-6">
                <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam profile" />
                <Field name="sms" type="number" component={timeSelect} label="Vul hier de sms nummers in" />
                <Field name="email" type="number" component={timeSelect} label="Vul hier de mailadressen in" />
                <div className="mt-4 p-4 border">
                  <strong>Vanaf tot wanneer mogen er berichten worden gestuurd </strong>
                  <Field name="tijden.vanaf" type="number" component={Time} label="Vanaf" />
                  <Field name="tijden.tot" type="number" component={Time} label="tot" />
                </div>
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
AlertProfileform = reduxForm({
  form: "alertprofileinsert", // a unique identifier for this form
})(AlertProfileform);

// You have to connect() to any reducers that you wish to connect to yourself
AlertProfileform = connect()(AlertProfileform);

export default AlertProfileform;
