import React from "react";
import { connect } from "react-redux";
import { insertkampeerplaatsen } from "../Reducers/actions";

import BedrijvenForm from "./Components/form";
class KampeerplaatsInsert extends React.Component {
  state = {
    errors: {},
    loaded: false,
  };

  submit = (vals) => {
    this.props.insertkampeerplaatsen(vals).then((data) => {
      console.log(data);
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.history.push("/standplaatsen/kampeerplaatsen");
      }
    });
  };

  render() {
    const { alertprofile } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Kampeerplaats toevoegen</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile"><BedrijvenForm alertprofile={alertprofile} onSubmit={this.submit} /></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user };
}

export default connect(mapStateToProps, { insertkampeerplaatsen })(KampeerplaatsInsert);
