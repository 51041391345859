import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { fetchcertificaten } from "./Reducers/actions";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
class CertPage extends React.Component {
  state = {
    errors: {},
    deelnemers: 0,
    hulpkrachten: 0,
    recreatieleider: 0,
    stagiaires: 0
  };

  componentWillMount() {
    this.getvals({});
  }

  getvals = vals => {
    this.props.fetchcertificaten(vals).then(data => {});
  };

  downloadEmployeeData = (bestand) => {
		fetch(process.env.PUBLIC_URL + "/"+bestand)
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = bestand;
					a.click();
				});
		});
	}

  render() {
    function datum(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "naam",
        text: "Naam"
      },
      {
        dataField: "datum",
        text: "datum",
        formatter: datum
      },
      {
        text: "Bestand",
        dataField: "bestand",
        formatter: (cellContent, row) => {
          return (
            <button className="btn btn-success"  onClick={this.downloadEmployeeData.bind(this, row.bestand)}>Download</button>
          )
        }
      }
    ];
    const { certificaten } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Certificaten</h3>
          <div className="box-tools pull-right">
            <Link
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
              to="/admin/traingen/certificaat/insert"
            >
              <i className="fa fa-plus"></i>&nbsp; Certificaat Toevoegen
            </Link>
          </div>
        </div>
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile" />
            <BootstrapTable
              keyField="_id"
              data={certificaten}
              columns={columns}
              pagination={paginationFactory()}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { certificaten: state.certificaten.items };
}

export default connect(mapStateToProps, { fetchcertificaten })(CertPage);
