export const SET_WEBSITES = 'SET_WEBSITES';
export const ADD_WEBSITES = 'ADD_WEBSITES';
export const WEBSITE_ERROR = 'WEBSITE_ERROR';
export const SET_WEBSITE = 'SET_WEBSITE'
export const SET_WEBSITE_PRGRESS = 'SET_WEBSITE_PRGRESS'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function counters(results) {
  return {
    type: SET_WEBSITE_PRGRESS,
    results
  }
}

export function set(results) {
  return {
    type: SET_WEBSITES,
    results
  }
}
export function setone(results) {
  return {
    type: SET_WEBSITE,
    results
  }
}
export function add(results) {
  return {
    type: SET_WEBSITE,
    results
  }
}
export function itemError(error) {
  return {
    type: WEBSITE_ERROR,
    payload: error
  };
}

export function Savelog(data) {
  return dispatch => {
    return fetch('/api/admin/logboeken/insert', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchwebsite() {
  return dispatch => {
    return fetch('/api/admin/logboeken/website', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getonewebsite(id) {
  return dispatch => {
    return fetch('/api/admin/logboeken/website/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchwebsitein() {
  return dispatch => {
    return fetch('/api/admin/activiteiten/new', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function websitecounters() {
  return dispatch => {
    return fetch('/api/admin/progress', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(counters(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function websiteinschrijgcounters() {
  return dispatch => {
    return fetch('/api/admin/inschrijvingen/website/progress', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(counters(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
