import React from "react";
class TellerComponent extends React.Component {
  render() {
    const { naam, teller, icon } = this.props;
    return (
      <div className="col-md-3 col-sm-6 col-xs-12">
        <div className="info-box">
          <span className="info-box-icon bg-aqua">
            <i className={icon} />
          </span>

          <div className="info-box-content">
            <span className="info-box-text">{naam}</span>
            <span className="info-box-number">{teller}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default TellerComponent;
