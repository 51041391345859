import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form'
import auth from './Reducers/auth/reducers.js';
import profile from './Pages/profile/Reducers/profile';
import logboeken from './Pages/Logboeken/Reducers/logboeken';
import bedrijven from './Pages/Admin/Bedrijven/Reducers/bedrijven';
import website from './Pages/Website/Reducers/website';
import push from './Pages/profile/Reducers/Push'
import cursisten from './Pages/Trainingen/Curstisten/Reducers/cursisten'
import trainingen from './Pages/Trainingen/Trainingen/Reducers/trainingen'
import beoordelingen from './Pages/Trainingen/Beoordelingen/Reducers/beoordelingen'
import certificaten from "./Pages/Trainingen/Certificaat/Reducers/certificaten"
import loading from "./Reducers/loading/loading"
import gebruikers from './Pages/Admin/Gebruikers/Reducers/gebruikers'
import activiteiten from "./Pages/Activiteiten/Reducers/activiteiten"
import faciliteiten from "./Pages/Faciliteiten/Reducers/facilieiten"
import pubred from "./Pages/Public/Activiteiten/Reducers/pubred"
import gasten from "./Pages/Public/Gasten/Reducers/gasten"
import openfaciliteiten from "./Pages/Public/Faciliteiten/Reducers/facilieiten"
import mgebruukers from "./Pages/Gebruikers/Reducers/gebruikers"
import horecap from "./Pages/Public/Horeca/Reducers/horecared"
import horeca from "./Pages/Horeca/Reducers/horeca"
import bedrijfp from "./Pages/Public/Bedrijven/Reducers/pbedrijf"
import biepp from "./Pages/Biepp/Reducers/biepp"
import verhuur from "./Pages/Verhuur/Reducers/verhuur"
import sensoren from "./Pages/Sensors/Reducers/sensoren"
import alertprofile from "./Pages/Sensors/Profiles/Reducers/alertprofile"
import standplaatsen from './Pages/Standplaatsen/Reducers/standplaatsen.js';

export default combineReducers({
  auth,
  profile,
  logboeken,
  bedrijven,
  website,
  push,
  cursisten,
  trainingen,
  beoordelingen,
  certificaten,
  loading,
  gebruikers,
  activiteiten,
  faciliteiten,
  pubred,
  openfaciliteiten,
  gasten,
  mgebruukers,
  horecap,
  horeca,
  bedrijfp,
  biepp,
  verhuur,
  sensoren,
  alertprofile,
  standplaatsen,
  form: formReducer // <---- Mounted at 'form
});
