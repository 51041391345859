import React, { Component } from "react";
import { Route } from "react-router-dom";
import   BieppInsert  from "./insert"
import BiepShow from "./show";
import Biepp from ".";
import BieppEdit  from "./edit";
class BieppRouter extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/biepp/" component={Biepp} />
        <Route exact path="/biepp/insert" component={BieppInsert} />
        <Route exact path="/biepp/edit/:id/" component={BieppEdit} />
        <Route exact path="/biepp/edit/:id/:step" component={BieppEdit} />
        <Route exact path="/biepp/show/:id" component={BiepShow} />
      </React.Fragment>
    );
  }
}

export default BieppRouter;
