import React, { Component } from "react";
import { connect } from "react-redux";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { checktoken } from "../Gasten/Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";
class VerifyLogin extends Component {
  state = {
    errors: {},
    verfify: false,
  };

  componentWillMount() {
    this.props.checktoken({ token: this.props.match.params.id }).then((data) => {
      if (data.results && data.results._id) {
        this.setState({ verfify: true });
        localStorage.setItem("gast", data.results._id);
        if (data.results.from) {
          this.props.message(3000, "success", lang("Je account is succesvol geactiveerd.")).then((t) => {
            this.props.history.push(data.results.from);
          });
        } else {
          this.props.fixmessage("success", lang("Je account is succesvol geactiveerd."));
        }
      } else {
        this.setState({ verfify: false });
        this.props.fixmessage("danger", lang("Er is geen account gevonden met via deze link"));
      }
    });
  }

  componentWillUnmount() {
    this.props.clearmessage();
  }

  render() {
    return (
      <div className="public-box-body">
        {this.state.verfify && (
          <div>
            <h2 className="headline text-yellow"> {lang("Het is gelukt")}</h2>
            <div className="inschrijf-content">{lang("Je kunt je nu via de RCN app inschrijven voor activiteiten of faciliteiten op ons park.")}</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  clearmessage,
  checktoken,
})(VerifyLogin);
