import React from 'react';
import {connect} from 'react-redux';
import {updatecursisten} from './Reducers/actions';
import CuristenForm from './Components//edit'
import {fetchcurist} from "./Reducers/actions"
import {fetchbedrijven} from "../../Admin/Bedrijven/Reducers/actions"
class CursistInsertPage extends React.Component {
  state = {
    errors: {},
    loaded: false
  }


  componentWillMount() {
    this.setState({ loaded: false });
    this.props.fetchbedrijven().then(data => {
        this.props.fetchcurist(this.props.match.params.id).then(data => {
            this.setState({ loaded: true });
        })
     
    });
  
  }

  submit = vals => {
      this.props.updatecursisten(this.props.match.params.id, vals).then(data => {
 
        if (data.type ==="CURSIST_ERROR") {
          alert(data.payload);
          return false;
        } else {
              this.props.history.push('/admin/trainingen/cursisten');
        }
      });
  }

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Cursist toevoegen</h3>
        </div>

      <div className="row">
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile">
              {this.state.loaded &&
            <CuristenForm onSubmit={this.submit}/>
              }
            </div>
        </div>
      </div>
    </div>
  </div>);
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, cursisten: state.cursisten};
}

export default connect(mapStateToProps, {updatecursisten, fetchcurist, fetchbedrijven})(CursistInsertPage);
