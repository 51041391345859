import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { renderselectapititel } from "../../../../Components/Forms/renders";
import { fetchtrainingen } from "../../Trainingen/Reducers/actions";
class CuristenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  componentDidMount() {
    this.props.fetchtrainingen().then(data => {});
  }
  render() {
    const { handleSubmit, trainingen } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4">
            <Field
              name="training"
              type="input"
              component={renderselectapititel}
              options={trainingen}
              label="Kies  een training"
            /> </div>
          <div className="col-8">
            <input
              type="submit"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Koppel cursisten aan training"
            />
          </div>
        </div>
      </form>
    );
  }
}
CuristenForm = reduxForm({
  form: "cursstinstertraining" // a unique identifier for this form
})(CuristenForm);

// You have to connect() to any reducers that you wish to connect to yourself
CuristenForm = connect(
  state => ({
    initialValues: state.cursisten, // pull initial values from account reducer
    trainingen: state.trainingen.items
  }),
  { fetchtrainingen }
)(CuristenForm);

export default CuristenForm;
