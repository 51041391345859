import React, { Component } from "react";
import { Route } from "react-router-dom";
import CursistenPage from "./Curstisten";
import CursistInsertPage from "./Curstisten/insert";
import CursistPage from "./Curstisten/cursist";
import TrainingenPage from "./Trainingen/index";
import TrainingPage from "./Trainingen/training";
import TrainingPageInsert from "./Trainingen/insert";
import CursistPageAlgemeen from "./Curstisten/cursisialgemeen";
import CursistEdit from "./Curstisten/edit";
import EditTrainerPage from "./Trainingen/edit";
import CertPage from "./Certificaat";
import CertPageInsert from "./Certificaat/insert";
class Bedrijven extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path="/admin/trainingen/cursisten"
          component={CursistenPage}
        />
        <Route
          exact
          path="/admin/trainingen/cursist/edit/:id"
          component={CursistEdit}
        />

        <Route
          exact
          path="/admin/trainingen/cursisten/insert"
          component={CursistInsertPage}
        />
        <Route
          exact
          path="/admin/trainingen/:trainingen/cursist/:id"
          component={CursistPage}
        />
        <Route
          exact
          path="/admin/trainingen/cursist/:id"
          component={CursistPageAlgemeen}
        />
        <Route
          exact
          path="/admin/trainingen/edit/:id"
          component={EditTrainerPage}
        />
        <Route exact path="/admin/trainingen/" component={TrainingenPage} />
        <Route
          exact
          path="/admin/trainingen/insert"
          component={TrainingPageInsert}
        />
        <Route
          exact
          path="/admin/trainingen/certificaat"
          component={CertPage}
        />
        <Route
          exact
          path="/admin/traingen/certificaat/insert"
          component={CertPageInsert}
        />
        <Route exact path="/admin/trainingen/:id" component={TrainingPage} />
      </React.Fragment>
    );
  }
}

export default Bedrijven;
