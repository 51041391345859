import React from 'react';
import {connect} from 'react-redux';

import {upload_cursist_image}  from '../Reducers/actions';

class ProfileFotoPage extends React.Component {
  state = {
    errors: {},
    cursist: {}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cursist && nextProps.cursist.portimage) {
    this.setState({"cursist": nextProps.cursist})
    }
  }


  handleClick(e) {
    var inputField = this.refs.fileUploaders;
    inputField.click();
  }
  handleUploadCursist = (e) => {
    var id = this.props.cursist._id;
    e.preventDefault();
    let errors = {};
    const file = e.target.files[0];        
    this.props.upload_cursist_image(id, {file}).then((data) => {
      this.setState({"cursist": data.results})
      if (data.errors) {
        errors.mes = "foutje"; 
        this.setState({errors});
        return true
      }
    });
  }

  render() {
    const {cursist} = this.state   
    const profileimage = (cursist) => {    
      if (cursist && cursist.portimage) {
        return <img className="img-thumbnail pad" src={cursist.portimage} alt="User"/>
      } else {
        return <img src="/img/nofoto.jpg" className="img-responsive pad" alt="User"/>
      }
    }
    if (cursist) {
          
    return (
        <div className="box box-primary">
          <div className="box-body box-profile">
            <span onClick={this.handleClick.bind(this)}>
              {profileimage(this.state.cursist)}
              <input type="file" name="files" accept=".jpg,.jpeg"  onChange={this.handleUploadCursist.bind(this)} ref="fileUploaders" style={{
                  display: "none"
                }}/>
            </span>
            <h3 className="profile-username text-center">
              {cursist.name}
            </h3>
            <input type="file" name="files" accept=".jpg,.jpeg" onChange={this.handleUploadCursist.bind(this)} ref="fileUploaders" style={{
                display: "none"
              }}/>
          </div>
        </div>);
    }else {
      return null
    }
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user,  profile: state.profile};
}

export default connect(mapStateToProps, {upload_cursist_image})(ProfileFotoPage);
