import moment from "moment";
import React, { Component } from "react";
import { Chart } from "react-google-charts";
export default class Tempratuur extends Component {
  render() {
    const { sensor } = this.props;
    function value(data) {
      if (data[0] && data[0].value) {
        return data[0].value;
      } else {
        return null;
      }
    }
    function luodate(data) {
      if (data[0] && data[0].datum) {
        return moment(data[0].datum).fromNow();
      } else {
        return null;
      }
    }
    return (
      <div className="card-body flex">
 
        <div className="justify-content-center temp-item">
        <h3>Actueel</h3>
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["Temp.", value(sensor.data)],
            ]}
            options={{
              redFrom: sensor.minvaluetemp,
              redTo: sensor.minvalue - 1,
              greenFrom: sensor.minvalue,
              greenTo: sensor.maxvalue,
              yellowFrom: sensor.maxvalue + 1,
              yellowTo: sensor.maxvaluetemp,
              minorTicks: 5,
              min: sensor.minvaluetemp,
              max: sensor.maxvaluetemp,
              greenColor:"#B1BF41",
              redColor:"#D96A29",
              yellowColor:"#D96A29"
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
        <div className="details">Laatste meting {luodate(sensor.data)}</div>
      </div>
    );
  }
}
