import React from "react";
import { connect } from "react-redux";

class KindertheaterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentWillMount() {}
  render() {
    const { beoordeling } = this.props;
    function beoordelingresult(result) {
      if (result === "onvoldoende") {
        return <span className="badge bg-red">{result}</span>;
      }
      if (result === "voldoende") {
        return <span className="badge bg-orange">{result}</span>;
      }
      if (result === "redelijk") {
        return <span className="badge bg-blue">{result}</span>;
      }
      if (result === "goed") {
        return <span className="badge bg-green">{result}</span>;
      }
    }

    function beoordelingresultspel(result) {
      if (result === "matig") {
        return <span className="badge bg-red">{result}</span>;
      }
      if (result === "voldoende") {
        return <span className="badge bg-orange">{result}</span>;
      }
      if (result === "ruimvoldoende") {
        return <span className="badge bg-blue">{result}</span>;
      }
      if (result === "goed") {
        return <span className="badge bg-purple">{result}</span>;
      }
      if (result === "uitstekend") {
        return <span className="badge bg-green">{result}</span>;
      }
    }
    if (this.props.loaded && beoordeling.kindertheater) {
      return (
        <div className="box box-default">
          <div className="card">
            <div className="card-header">
           Kindertheater
              <div className="float-right">
                {beoordelingresult(beoordeling.kindertheater.kindertheater)}
              </div>
            </div>
            <div className="card-body">
              <div className="card-body">
              <div className="row">
                  <div className="col-sm-4">Naam typetje</div>
                  <div className="col-sm-8">
                    {beoordeling.kindertheater.naamtypetje}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">Pakt tips van anderen op?</div>
                  <div className="col-sm-8">
                    {beoordeling.kindertheater.tips}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                   Kleding
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.kindertheater.kleding)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">Maakt groei door tijdens workshop</div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.kindertheater.groei
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">Typetje</div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.kindertheater.typetje
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                  Vergroten van bewegingen/mimiek"
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.kindertheater.mimiek)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                  Toont lef/enthousiasme
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.kindertheater.lef
                    )}
                  </div>
                </div>
              </div>
              <h5 className="card-title paddingtop">
              Overige Opmerkingen"
              </h5>
              {beoordeling.kindertheater.opmerkingen }
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
  return { beoordeling: state.beoordelingen.item };
}

export default connect(mapStateToProps, {})(KindertheaterForm);
