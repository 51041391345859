import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const renderField = ({ input, label, type, languages, meta: { touched, error, warning } }) => (
  <div>
         {label && <label>{label}</label>}
    <Tabs>
      {languages && (
        <TabList>
          <Tab>NL</Tab>
          <Tab>EN</Tab>
          <Tab>DU</Tab>
          <Tab>FR</Tab>
        </TabList>
      )}
      {languages && (
        <div>
          <TabPanel>
            <input  name={`${input.name}.NL`} defaultValue={input.value.NL}  className="form-control" placeholder={label} type={type} />
          </TabPanel>
          <TabPanel>
            <input name={`${input.name}.EN`}   className="form-control" placeholder={label} type={type} />
          </TabPanel>
          <TabPanel>
            <input {...input} className="form-control" placeholder={label} type={type} />
          </TabPanel>
          <TabPanel>
            <input {...input} className="form-control" placeholder={label} type={type} />
          </TabPanel>
        </div>
      )}
      {!languages && (
        <div>
          <input {...input} className="form-control" placeholder={label} type={type} />
        </div>
      )}
      <div>{touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}</div>
    </Tabs>
  </div>
);

export const renderAField = ({ input, label, type, meta: { asyncValidating, touched, error } }) => (
  <div>
    <label>{label}</label>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input {...input} type={type} className="form-control" placeholder={label} />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);
const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);
export const renderFieldUpload = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  label,
  type,
  meta: { asyncValidating, touched, error },
  ...props
}) => (
  <div>
    <label>{label}</label>
    <div className={asyncValidating ? "async-validating" : ""}>
      <input
        {...props.input}
        {...props}
        type="file"
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        value={null}
        className="form-control"
        placeholder={label}
      />
      {touched && error && <span className="text-danger">{error}</span>}
    </div>
  </div>
);

export const renderTextField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <label>{label}</label>
    <div>
      <textarea {...input} className="form-control" placeholder={label} type={type} />{" "}
      {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export const renderselect = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={key}>
        {props.options[key]}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} className="form-control">
            <option value="">Maak een keuze</option>
            {Object.keys(props.options).map(renderSelectOptions)}
          </select>
          {props.meta.touched &&
            ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) ||
              (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapi = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.naam}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} value={props.input.value._id} className="form-control selectpicker" data-selected-text-format="count">
            <option value="">Maak een keuze</option>
            {props.options.map(renderSelectOptions)}
          </select>
          {props.meta.touched &&
            ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) ||
              (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapiusers = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.voornaam + " " + key.achternaam}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <label>{props.label}</label>
        <div>
          <select {...props.input} value={props.input.value._id} className="form-control selectpicker" data-selected-text-format="count">
            <option value="">Maak een keuze</option>
            {props.options.map(renderSelectOptions)}
          </select>
          {props.meta.touched &&
            ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) ||
              (props.meta.warning && <span>{props.meta.warning}</span>))}
        </div>
      </div>
    );
  }
  return <div></div>;
};

export const renderselectapititel = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={key._id} value={key._id}>
        {key.titel}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <select {...props.input} className="form-control selectpicker" data-selected-text-format="count">
        <option value="">Maak een keuze</option>
        {props.options.map(renderSelectOptions)}
      </select>
    );
  }
  return <div></div>;
};

export const renderselectsimple = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={key}>
        {props.options[key]}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <div>
        <select {...props.input} className="form-control">
          <option value="">Select</option>
          {Object.keys(props.options).map(renderSelectOptions)}
        </select>
        {props.meta.touched &&
          ((props.meta.error && <span className="text-danger">{props.meta.error}</span>) ||
            (props.meta.warning && <span>{props.meta.warning}</span>))}
      </div>
    );
  }
  return <div></div>;
};

export const renderFieldsimple = ({ input, label, type, meta: { touched, error, warning } }) => (
  <input {...input} className="form-control" placeholder={label} type={type} />
);
