import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderselectapi, renderTextField } from "../../../../Components/Forms/renders";

class SensorEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-6">
                <Field
                  className="form"
                  name="active"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Standplaats actief"
                  component={renderselect}
                  options={{
                    true: "Actief",
                    false: "Uitgeschakeld",
                  }}
                />
                <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam kampeerplaats" />
                <Field name="beschrijving" type="input" component={renderTextField} label="beschrijving kampeerplaats" />
                <Field name="device_id" type="input" component={renderField} label="Vul de Sensor ID in" />
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
SensorEditForm = reduxForm({
  form: "sensoredit", // a unique identifier for this form
})(SensorEditForm);

// You have to connect() to any reducers that you wish to connect to yourself
SensorEditForm = connect(
  (state) => ({
    initialValues: state.standplaatsen.kampeerplaats, // pull initial values from account reducer
  }) // bind account loading action creator
)(SensorEditForm);

export default SensorEditForm;
