import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user } = this.props;
    function ismodule(val) {
      if (user.bedrijf && user.bedrijf.modules && user.bedrijf.modules.includes(val)) {
        return true;
      } else {
        return false;
      }
    }
    return (
      <div className="barback">
        <div className="row">
          {ismodule("activiteiten") && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Link to={"/activiteiten"} className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-volleyball-ball"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Activiteiten</span>
                </div>
              </Link>
            </div>
          )}
          {ismodule("biepp") && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Link to={"/biepp"} className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-swatchbook"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Biepp</span>
                </div>
              </Link>
            </div>
          )}
          {ismodule("logboeken") && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Link to={"/logboek"} className="info-box">
                <span className="info-box-icon bg-aqua">
                  <i className="fa fa-book"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Logboeken</span>
                </div>
              </Link>
            </div>
          )}
          {ismodule("sensoren") && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Link to={"/sensoren"} className="info-box">
                <span className="info-box-icon bg-aqua">
                <i className="fas fa-project-diagram"></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Sensoren</span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Header;
