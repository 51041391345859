import {
  SET_CERTIFICATEN,
  ADD_CERTIFICATEN,
  CERTIFICAAT_ERROR,
  SET_CERTIFICAAT
} from './actions';
export default function cats(state = {
  item: [],
  items: []
}, action = {}) {
  switch (action.type) {
    case ADD_CERTIFICATEN:
      return { ...state,
        items: [...state.itens, action.results]
      };
    case SET_CERTIFICAAT:
      return { ...state,
        item: action.results
      };
    case CERTIFICAAT_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_CERTIFICATEN:
      return { ...state,
        items: action.results
      };
    default:
      return state;
  }
}
