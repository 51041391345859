import {
  SET_FACILITEITEN,
  ADD_FACILITEITEN,
  FACILITEIT_ERROR,
  SET_FACILITEIT,
  SET_BESCHIKBAARHEID,
  SET_RESERVERINGEN
} from "./actions";
export default function cats(
  state = {
    item: [],
    items: [],
    beschikbaarheid: [],
    reserveringen:[]
  },
  action = {}
) {
  switch (action.type) {
    case ADD_FACILITEITEN:
      return { ...state, items: [...state.itens, action.results] };
    case SET_FACILITEIT:
      return { ...state, item: action.results };
    case FACILITEIT_ERROR:
      return { ...state, error: action.payload };
    case SET_FACILITEITEN:
      return { ...state, items: action.results };
    case SET_BESCHIKBAARHEID:
      return { ...state, beschikbaarheid: action.results };
    case SET_RESERVERINGEN:
    return { ...state, reserveringen: action.results };
    default:
      return state;
  }
}
