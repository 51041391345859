import {
  SET_TRAININGEN,
  ADD_TRAININGEN,
  TRAINING_ERROR,
  SET_TRAINING
} from './actions';
export default function cats(state = {
  item: [],
  items: []
}, action = {}) {
  switch (action.type) {
    case ADD_TRAININGEN:        
      return { ...state,
        items: [...state.items, action.results]
      };
    case SET_TRAINING:
      return { ...state,
        item: action.results
      };
    case TRAINING_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_TRAININGEN:
      return { ...state,
        items: action.results
      };
    default:
      return state;
  }
}
