import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required, numericality } from "redux-form-validators";
import { lang } from "../../../../Components/Lib/Languages/language";
import DateInput from "../../../../Components/Forms/date";
import { renderselecttijden, renderField } from "../../../../Components/Forms/renders";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            name="datum"
            type="number"
            validate={required({ msg: "Dit item is verplicht" })}
            component={DateInput}
            label={lang("Kies een datum")}
            onChange={this.props.setdate}
          />
          {this.props.tijden.length > 0 && (
            <div>
              <Field
                name="tijd"
                type="number"
                validate={required({ msg: "Dit item is verplicht" })}
                component={renderselecttijden}
                options={this.props.tijden}
                label={lang("Kies een tijd")}
              />
              <Field
                name="personen"
                type="number"
                validate={numericality({ '<': 9,   msg: "Dit item is verplicht (Max 8 personen)" })} 
                component={renderField}
                label={lang("Vul het aantal personen in")}
              />
              <div className="submitform">
                <input
                  type="submit"
                  className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                  name="next"
                  value={lang("Bevestig je reservering")}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  () => ({}) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
