
import {
  SET_ACTIVITEITEN,
  ADD_ACTIVITEITEN,
  ACTIVITEIT_ERROR,
  SET_ACTIVITEIT,
  SET_VERGELIJKEN,
  SET_JAAR
} from './actions';
export default function cats(state = {
  item: [],
  items: [],

}, action = {}) {
  switch (action.type) {
    case ADD_ACTIVITEITEN:
      return { ...state,
        items: [...state.itens, action.results]
      };
    case SET_ACTIVITEIT:
      return { ...state,
        item: action.results
      };
    case ACTIVITEIT_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_ACTIVITEITEN:
      return { ...state,
        items: action.results
      };
      case SET_VERGELIJKEN:
        return { ...state,
          bedrijven: action.results
        };
        case SET_JAAR:
          localStorage.setItem('jaar', action.results);
          return { ...state,
            jaar: action.results
          };
    default:
      return state;
  }
}

