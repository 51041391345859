import React, { Component } from "react";
import Header from "./Themes/Main/Header.jsx";
import Sitebar from "./Themes/Main/Sitebar.jsx";
import Footer from "./Themes/Main/Footer.jsx";
import Account from "./Themes/Account";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "./Components/Account/Login/Login.jsx";
import ForgetPage from "./Components/Account/Login/Forget";
import NewPasswordPage from "./Components/Account/Login/NewPassword";
import SmsPage from "./Components/Account/Sms/";
import SignupPage from "./Pages/Admin/Gebruikers/insert";
import VerficationPage from "./Components/Account/SignUp/Confirmation.jsx";
import RequireAuth from "./Auth/require_auth";
import RequireAuthAdmin from "./Auth/require_auth_admin";
import ProfilePage from "./Pages/profile/profile";
import NoMatch from "./Themes/Main/404";
import BedrijvenPage from "./Pages/Admin/Bedrijven/index";
import BedrijfInsertPage from "./Pages/Admin/Bedrijven/insert";
import BedrijfEditPage from "./Pages/Admin/Bedrijven/edit";
import GebruikersPage from "./Pages/Admin/Gebruikers/index";
import Loading from "./Components/loading/messages";
import LogboekPages from "./Pages/Logboeken/router";
import TrainingPages from "./Pages/Trainingen/router";
import Activiteiten from "./Pages/Activiteiten/router";
import FaciliteitenPages from "./Pages/Faciliteiten/router";
import Public from "./Themes/Public/index";
import PublicPages from "./Pages/Public/router";
import GebruikersEditPage from "./Pages/Admin/Gebruikers/edit";
import GebruikersPages from "./Pages/Gebruikers/router";
import MainPage from "./Pages/index";
import { settranslate } from "./Components/Lib/Languages/language";
import HorecaRouter from "./Pages/Horeca/router.js";
import BieppRouter from "./Pages/Biepp/router.js"
import VerhuurRouter from "./Pages/Verhuur/router"
import SensorRouter from "./Pages/Sensors/router.js";
import StandplaatsRouter from "./Pages/Standplaatsen/router.js";
class App extends Component {
  render() {
    const { location } = this.props;
    let loc = location.pathname.split("/");
    settranslate();
    if (loc[1] === "login" || loc[1] === "signup" || loc[1] === "404") {
      return (
        <Account>
          <Switch>
            <Route exact path="/login" component={LoginPage} {...App} />
            <Route exact path="/login/sms/:token" component={SmsPage} {...App} />
            <Route exact path="/login/forget" component={ForgetPage} {...App} />
            <Route exact path="/login/forget/:code" component={NewPasswordPage} />
            <Route exact path="/signup/verify/:code" component={VerficationPage} {...App} />
            <Route exact path="/404" component={NoMatch} />
          </Switch>
        </Account>
      );
    } else if (loc[1] === "public") {
      return (
        <Public>
          <Switch>
            <Route path="/public" component={PublicPages} />
          </Switch>
        </Public>
      );
    } else {
      return (
        <div className="content-page">
          <Header />
          <Sitebar />
          <div className="content-wrapper">
            <Loading />
            <section className="content">
              <Switch>
                <Route exact path="/admin/signup" component={RequireAuthAdmin(SignupPage)} />
                <Route exact path="/admin/profile" component={RequireAuth(ProfilePage)} />
                <Route exact path="/admin/gebruikers" component={RequireAuthAdmin(GebruikersPage)} />
                <Route exact path="/admin/gebruikers/:id" component={RequireAuthAdmin(GebruikersEditPage)} />
                <Route exact path="/" component={RequireAuth(MainPage)} />

                <Route exact path="/admin/bedrijven" component={RequireAuthAdmin(BedrijvenPage)} />
                <Route exact path="/admin/bedrijven/insert" component={RequireAuthAdmin(BedrijfInsertPage)} />
                <Route exact path="/admin/bedrijven/edit/:id" component={RequireAuthAdmin(BedrijfEditPage)} />

                <Route path="/logboek" component={RequireAuth(LogboekPages)} />
                <Route path="/activiteiten" component={RequireAuth(Activiteiten)} />
                <Route path="/admin/trainingen" component={RequireAuthAdmin(TrainingPages)} />
                <Route path="/faciliteiten" component={RequireAuth(FaciliteitenPages)} />
                <Route path="/gebruikers" component={RequireAuthAdmin(GebruikersPages)} />
                <Route path="/horeca" component={RequireAuth(HorecaRouter)} />
                <Route path="/biepp" component={RequireAuth(BieppRouter)} />
                <Route path="/verhuur" component={RequireAuth(VerhuurRouter)} />
                <Route path="/sensoren" component={RequireAuth(SensorRouter)} />
                <Route path="/standplaatsen" component={RequireAuth(StandplaatsRouter)} />
                <Route render={() => <Redirect to="/404" />} />
              </Switch>
            </section>
          </div>
          <Footer classNmae="main-footer" />
        </div>
      );
    }
  }
}

export default App;
