import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { translate } from "../../../../Components/Lib/Languages/language";
moment.locale("nl");
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  render() {

    const { bedrijf } = this.props;

      return (
        <Link
          className="activiteit-link"
          to={{
            pathname: "/public/bedrijf/" + bedrijf._id,
          }}
        >
          <div className="faciliteit-box-body">
            <h3 className="activiteit-titel">{translate(bedrijf.naam)}</h3>
            {translate(bedrijf.beschrijving)}
          </div>
          <div className="activiteit-arrow">
            <i className="fa fa-chevron-right"></i>
          </div>
        </Link>
      );
  }
}

export default Inschrijven;
