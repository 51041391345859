import React, { Component } from "react";
import { connect } from "react-redux";
export default function ComposedComponent() {
  class Authentication extends Component {
    componentWillMount() {
      this.logout(this.props);
      //this.checkpage(this.props);
    }

    logout(params) {
      const { history, match, token } = params;
      if (token) {
        localStorage.removeItem("gast");
        history.replace({ pathname: "/public/" + match.params.id, state: { from: "/public/" + match.params.soort + "/" + match.params.id } });
      }
    }
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated, token: state.auth.token };
  }

  return connect(mapStateToProps)(Authentication);
}
