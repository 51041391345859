import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class LogTabel extends Component {
  render() {
    const { sensor } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY HH:mm");
    }
    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "omschrijving",
        text: "Omschrijving",
      },
      {
        dataField: "value",
        text: "Temperatuur",
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cellContent, row) => {
          if (!cellContent) {
            return <span className="badge badge-danger">De waarde is niet goed</span>;
          } else {
            return <span className="badge badge-success">De waarde is goed</span>;
          }
        },
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={sensor.logs} columns={columns} pagination={paginationFactory({ sizePerPage: 20 })} />;
  }
}
