import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { fetchtrainingen } from "./Reducers/actions";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

class TrainingenPage extends React.Component {
  state = {
    errors: {},
    deelnemers: 0,
    hulpkrachten: 0,
    recreatieleider: 0,
    stagiaires: 0
  };

  componentWillMount() {
    this.getvals({});
  }

  getvals = vals => {
    this.props.fetchtrainingen(vals).then(data => {});
  };

  
  render() {
    const { trainingen,username } = this.props;

  

    function datum(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    
    function wijzigen (role) {
      if (role ==="admin" || role==="root" || role==="hoofdtrainer") {
        return true
      } else {
        return false
      }
    }
  
    const columns = [
   
      {
        dataField: "datum",
        text: "Datum",
        formatter: datum
      },
      {
        dataField: "titel",
        text: "Titel"
      },
      {
        dataField: "trainer.voornaam",
        text: "Trainer"

      },
      {
        text: "Acties",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
          <span>
            <Link className="btn btn-success" to={"/admin/trainingen/"+row._id }>Bekijken</Link>
            {wijzigen(username.role) &&
            <Link className="btn btn-warning" to={"/admin/trainingen/edit/"+row._id }>Wijzigen</Link>
            }
            </span>
          )
        },
        headerStyle: (colum, colIndex) => {
         return { width: '250px' };
       }
      }
    ];
    
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Overzicht Trainingen</h3>
          <div className="box-tools pull-right">
            <Link
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
              to="/admin/trainingen/insert"
            >
              <i className="fa fa-plus"></i>&nbsp; Training Toevoegen
            </Link>
          </div>
        </div>
        <div className="col-md-12 no-float">
          <div className="box box-primary">
            <div className="box-body box-profile" />
            <BootstrapTable
              keyField="_id"
              data={trainingen}
              columns={columns}
              pagination={paginationFactory()}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { username: state.auth.user, trainingen: state.trainingen.items};
}

export default connect(mapStateToProps, { fetchtrainingen })(TrainingenPage);
