import React from "react";
import { connect } from "react-redux";
import Header from "../Components/Parts/header";
import ActiviteitenWidget from "./Activiteiten/Components/widget";
import FaciliteitenWidget from "./Faciliteiten/Components/widget";
import SensorWidget from "./Sensors/Components/widget";
import SensorWidgetFavo from "./Sensors/Components/favowidget";
import { fetchsensoren } from "./Sensors/Reducers/actions";
import Loading from "../Components/loading/load";
class Startpage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      load: false,
    };
  }
  componentDidMount() {
    this.props.fetchsensoren().then((data) => {
      this.setState({ load: true });
    });
  }
  render() {
    const { user, sensoren } = this.props;
    function vaild(vall) {
      if (vall.includes(user.role)) {
        return true;
      } else {
        return false;
      }
    }
    function ismodule(val) {
      if (user.bedrijf && user.bedrijf.modules && user.bedrijf.modules.includes(val)) {
        return true;
      } else {
        return false;
      }
    }
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Welkom bij Recraplan</h3>
        </div>
        {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker"]) && <Header user={user} />}
        <div className="row">
          {vaild(["admin", "manager", "td", "faciliteitmedewerker"]) && ismodule("sensoren") && (
            <div className="col-md-12">
              <div className="box box-default">
                <div className="box-header with-border">
                  <h3 className="box-title">Favoriete sensoren</h3>
                </div>
                {this.state.load && <SensorWidgetFavo sensoren={sensoren} />}
                <Loading loadings={this.state.load} />
              </div>
            </div>
          )}
          {vaild(["admin", "manager", "td", "faciliteitmedewerker"]) && ismodule("sensoren") && (
            <div className="col-md-12">
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Lijst sensoren</h3>
                </div>
                {this.state.load && <SensorWidget sensoren={sensoren} />}
                <Loading loadings={this.state.load} />
              </div>
            </div>
          )}
          {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker"]) && ismodule("activiteiten") && (
            <div className="col-md-6">
              <div className="box box-success">
                <div className="box-header with-border">
                  <h3 className="box-title">Activiteiten waar je voor in kan schrijven</h3>
                </div>
                <div className="box-body">
                  <ActiviteitenWidget />
                </div>
              </div>
            </div>
          )}
          {vaild(["admin", "manager", "hoofdrecreatie", "faciliteitmedewerker"]) && ismodule("faciliteiten") && (
            <div className="col-md-6">
              <div className="box box-info">
                <div className="box-header with-border">
                  <h3 className="box-title">Lijst met Faciliteiten</h3>
                </div>
                <div className="box-body">
                  <FaciliteitenWidget />
                </div>
              </div>
            </div>
          )}
          {ismodule("activiteiten") && (
            <>
              <div className="col-md-6">
                <div className="box box-warning">
                  <div className="box-header with-border">
                    <h3 className="box-title">Vragen of opmerkingen</h3>
                  </div>
                  <div className="box-body">
                    Heb je bij het gerbuik een vraag of het gebruik van recraplan. Stuur dan een mail naar{" "}
                    <a href="mailto:support@recraplan.nl">support@recraplan.nl</a>
                    <p>We zullen je vraag zo snel mogelijk beantwoorden</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box box-warning">
                  <div className="box-header with-border">
                    <h3 className="box-title">Handleidingen</h3>
                  </div>
                  <div className="box-body clearfix">
                    <h5>Download de handleidingen</h5>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      href="http://download.recraplan.nl/Algemeen - Recraplan Inschrijven Activiteiten NL FR DE.pdf"
                      type="button"
                      className="btn btn-default btn-block btn-flat"
                    >
                      Instructie Recraplan – activiteiten inschrijven
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      href="http://download.recraplan.nl/Algemeen - Recraplan instructie voor gasten NL FR DE.pdf"
                      type="button"
                      className="btn btn-default btn-block btn-flat"
                    >
                      Instructie Recraplan – Instructie gasten
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      href="http://download.recraplan.nl/Algemeen - Recraplan Reserveren Faciliteiten NL FR DE.pdf"
                      type="button"
                      className="btn btn-default btn-block btn-flat"
                    >
                      Instructie Recraplan – Reserveren faciliteiten
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { user: state.auth.user, sensoren: state.sensoren.items };
}
export default connect(mapStateToProps, { fetchsensoren })(Startpage);
