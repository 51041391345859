import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderselectapi } from "../../../../Components/Forms/renders";

class SensorEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit, alertprofile } = this.props;
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-6">
                <Field
                  className="form"
                  name="active"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Status van de sensor"
                  component={renderselect}
                  options={{
                    true: "Actief",
                    false: "Uitgeschakeld",
                  }}
                />
                <Field
                  className="form"
                  name="favo"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Maak je sensor favoriet"
                  component={renderselect}
                  options={{
                    true: "Ja",
                    false: "Nee",
                  }}
                />
                <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam sensor" />
                <Field name="device_id" type="input" validate={[required()]} component={renderField} label="Vul de Sensor ID in" />
                <Field
                  className="form"
                  name="sensor"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Type Sensor"
                  component={renderselect}
                  options={{
                    temperatuur: "Temperatuur externe Sensor",
                    temperatuurint: "Temperatuur",
                    electra: "Electra",
                    energy_emu: "EMU PROFESSIONAL II",
                    nke_watteco: "Sens'O - LoRaWAN Pulse",
                    "EM300-DI": "Milesigt EM300-DI Pulse Sensor",
                    "SDM230-LoRaWAN": "SDM230-LoRaWAN",
                  }}
                />
                <Field
                  className="form"
                  name="soort"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Soort Sensor"
                  component={renderselect}
                  options={{
                    gas: "Gas",
                    water: "Water",
                    electra: "Electra",
                    ampere: "Ampere Meter",
                    temperatuur: "temperatuur",
                  }}
                />
                <Field name="label" type="input" component={renderField} label="Vul label in" />
                <Field name="group" type="input" component={renderField} label="Vul een groep in" />
                <Field name="minvalue" type="number" component={renderField} label="Minimale waarde correct" />
                <Field name="maxvalue" type="number" component={renderField} label="Maximale waarde correct" />
                <Field name="minvaluetemp" type="number" component={renderField} label="Minimale sensor" />
                <Field name="maxvaluetemp" type="number" component={renderField} label="Maximale waarde sendor" />
              </div>
              <div className="col-6">
                <Field name="alerts.sendminvalue" type="number" component={renderField} label="Minimale waarde stuur alert" />
                <Field name="alerts.sendmaxvalue" type="number" component={renderField} label="Maximale waarde stuur alert" />
                <Field
                  className="form"
                  name="alerts.uur"
                  validate={required({ msg: "Type item is verplicht" })}
                  label="Interval (uren) tussen alerts (0 elke afwijking)"
                  component={renderselect}
                  options={{
                    0: "Elke afwijking",
                    1: "Maximaal 1 keer uur",
                    2: "Maximaal 1 keer per dag",
                    3: "Slim opgebouwd ",
                  }}
                />
                <Field
                  name="alertprofile"
                  type="input"
                  component={renderselectapi}
                  options={alertprofile}
                  label="Kies een alert profiel"
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Wijzigen" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
SensorEditForm = reduxForm({
  form: "sensoredit", // a unique identifier for this form
})(SensorEditForm);

// You have to connect() to any reducers that you wish to connect to yourself
SensorEditForm = connect(
  (state) => ({
    initialValues: state.sensoren.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(SensorEditForm);

export default SensorEditForm;
