import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";
import Multiselect from "../../../../Components/Forms/multiselect";
import InputRange from "../../../../Components/Forms/range";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            name="naam"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Naam van de activiteit"
          />
          <Field
            name="beschrijving"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderTextField}
            label="korte beschrijving van de activiteit"
          />
          <Field
            className="form"
            autoFocus={true}
            name="duur"
            validate={required({ msg: "Type item is verplicht" })}
            label="Duur van de activiteit"
            component={InputRange}
            options={["Creativiteit", "Spel", "Sport", "Tochten", " Show/amusement", "Theater (amusement)", "Minidisco (amusement)", "Kerkdienst"]}
          />
          <Field
            className="form"
            autoFocus={true}
            name="doelgroep"
            validate={required({ msg: "Type item is verplicht" })}
            label="Doelgroep"
            component={Multiselect}
            options={["Kinderen 0-4", "Kinderen 4-8", "Kinderen 8-12", "Jeugd 12-15", "16+", "Volwassenen", "Gezinnen", "Senioren"]}
          />
          <Field
            className="form"
            type="number"
            autoFocus={true}
            name="mindeelnemers"
            validate={required({ msg: "Type item is verplicht" })}
            label="Minimaal aantal deelnemers"
            component={renderField}
          />
          <Field
            className="form"
            autoFocus={true}
            name="categorie"
            validate={required({ msg: "Type item is verplicht" })}
            label="Categorie"
            component={Multiselect}
            options={["Creativiteit", "Spel", "Sport", "Tochten", "Thema", " Show/amusement", "Theater", "Minidisco", "Kerkdienst", "Alex en Sammy"]}
          />
          <Field
            className="form"
            autoFocus={true}
            name="plaats"
            validate={required({ msg: "Type item is verplicht" })}
            label="Plaats van de activiteit"
            component={Multiselect}
            options={["Bos", "Zwembad", "Gehele park", "Recreatiezaal", " Restaurant", "Sportveld", "Strand", "Overige"]}
          />
       <Field
            className="form"
            autoFocus={true}
            name="slechtweer"
            validate={required({ msg: "Type item is verplicht" })}
            label="Slechtweer activiteit"
            component={renderselect}
            options={["Ja", "Nee"]}
          />
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg submit-bt" name="next" value="Bijwerken" />
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "editbieppactiviet2", // a unique identifier for this form
  enableReinitialize: true,
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state) => ({
    initialValues: state.biepp.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
