import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { getactiviteit, maakinschrijving, delinschrijving, updateinschrijving } from "./Reducers/actions";
import Inschrijving from "./Components/inschrijvingen";
import { translate } from "../../Components/Lib/Languages/language";
import InschrijfForm from "./Components/inschrijven";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      inschrijven: false,
    };
  }
  componentWillMount() {
  this.loadactiviteit()
  }
  loadactiviteit =() => {
    this.props.getactiviteit(this.props.match.params.id).then((data) => {
      if (data.results) {
        this.setState({ loaded: true });
      }
    });
  }

deleteinschrijving =(id) =>{
this.props.delinschrijving(id).then(data => {
  this.loadactiviteit()
})
}
setbetaald =(id) =>{
  this.props.updateinschrijving(id, {betaald: true}).then(data => {
    this.loadactiviteit()
  })
}

setaanwezig =(id) =>{
  this.props.updateinschrijving(id, {aanwezig: true}).then(data => {
    this.loadactiviteit()
  })

}
  submit = (vals) => {
    const values = Object.assign({}, { activiteit: this.props.match.params.id,  datumactiviteit : this.props.activiteiten.datum }, vals);
    this.props.maakinschrijving(values).then((data) => {
      this.props.getactiviteit(this.props.match.params.id).then((data) => {
        if (data.results) {
          this.setState({ loaded: true, inschrijven: false });
        }
      });
    });
  };
  showrrser = () => {
    this.setState({ inschrijven: true });
  };
  render() {
    const { activiteiten } = this.props;
    function datum(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }

    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-title">
            <h2>{translate(activiteiten.activiteit)}</h2>
            {activiteiten.betaald  &&
            <span className="badge badge-info">Deze activiteit is betaald</span>
  }
          </div>
          <Link to="/activiteiten" className="btn btn-primary btn-sm float-right" role="group">
            Overzicht
          </Link>
        </div>

        <div className="box-body box-profile">{translate(activiteiten.beschrijving)}</div>
    
        <div className="box-body box-profile">
          Datum:{" "}
          <span className="geboortedatum">
            {datum(activiteiten.datum)} {activiteiten.tijd}
          </span>
  
          <div className="block">    <button className="btn btn-success btn pull-right" onClick={this.showrrser}>
            Maak reserveringen
          </button>
          </div>
          <div>
          </div>
        </div>

        {this.state.inschrijven && (
          <div className="inschrijform">
            <InschrijfForm onSubmit={this.submit} />
          </div>
        )}
        {activiteiten.inschrijving && <Inschrijving setbetaald={this.setbetaald} setaanwezig={this.setaanwezig} deleteinschrijving={this.deleteinschrijving} activiteit={activiteiten} />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    activiteiten: state.activiteiten.item,
  };
}

export default connect(mapStateToProps, {
  getactiviteit,
  maakinschrijving,
  delinschrijving,
  updateinschrijving
})(ProfilePage);
