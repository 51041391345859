import React from "react";
import { connect } from "react-redux";
import { Savelog } from "./Reducers/actions";
import LogboekForm from "./Components/insertform";
import MessageSucces from "../../Components/Forms/Validate/conform";
import {message} from "../../Reducers/loading/actions"
class LogboekInsertPage extends React.Component {
  state = {
    errors: {},
    success: false
  };

  submit = vals => {
    this.props.Savelog(vals).then(data => {
      if (data.type === "LOGBOEK_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is succesvol verwerkt").then((datas) => {
          this.props.history.push("/logboek");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Je activiteit is goed opgeslagen"}
          success={this.state.success}
        />
        <div className="box-header with-border">
          <h3 className="box-title"> Logboeken</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <LogboekForm onSubmit={this.submit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, logboeken: state.logboeken };
}

export default connect(
  mapStateToProps,
  { Savelog, message }
)(LogboekInsertPage);
