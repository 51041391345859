import {
  SET_CURSISTEN,
  ADD_CURSISTEN,
  CURSIST_ERROR,
  SET_CURSIST
} from './actions';
export default function cats(state = {
  item: [],
  items: []
}, action = {}) {
  switch (action.type) {
    case ADD_CURSISTEN:        
      return { ...state,
        items: [...state.items, action.results]
      };
    case SET_CURSIST:
      return { ...state,
        item: action.results
      };
    case CURSIST_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_CURSISTEN:
      return { ...state,
        items: action.results
      };
    default:
      return state;
  }
}
