import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class Bar extends Component {
  actief = (step) => {
    if (step === this.props.step) {
      return "actief flex-item";
    } else {
      return "flex-item";
    }
  };
  render() {
    return (
      <ul className="nav nav-pills flex-container wizzard">
          <Link className={this.actief(0)} to={`/biepp/edit/${this.props.id}`}>Omschrijving Activiteit</Link>
          <Link className={this.actief(1)} to={`/biepp/edit/${this.props.id}/1`}>Uitleg activiteit</Link>
          <Link className={this.actief(2)} to={`/biepp/edit/${this.props.id}/2`}>Bijlagen</Link>
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
