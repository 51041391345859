import React, { Component } from "react";
import { Route } from "react-router-dom";
import Verhuur from ".";

class VerhuurRouter extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/verhuur/" component={Verhuur} />
   
      </React.Fragment>
    );
  }
}

export default VerhuurRouter;
