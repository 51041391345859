import React from "react";
import { connect } from "react-redux";

class SportenSpelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentWillMount() {}
  render() {
    const { beoordeling } = this.props;
    function beoordelingresult(result) {
      if (result === "onvoldoende") {
        return <span className="badge bg-red">{result}</span>;
      }
      if (result === "voldoende") {
        return <span className="badge bg-orange">{result}</span>;
      }
      if (result === "redelijk") {
        return <span className="badge bg-blue">{result}</span>;
      }
      if (result === "goed") {
        return <span className="badge bg-green">{result}</span>;
      }
    }

    function beoordelingresultspel(result) {
      if (result === "matig") {
        return <span className="badge bg-red">{result}</span>;
      }
      if (result === "voldoende") {
        return <span className="badge bg-orange">{result}</span>;
      }
      if (result === "ruimvoldoende") {
        return <span className="badge bg-blue">{result}</span>;
      }
      if (result === "goed") {
        return <span className="badge bg-purple">{result}</span>;
      }
      if (result === "uitstekend") {
        return <span className="badge bg-green">{result}</span>;
      }
    }
    if (this.props.loaded && beoordeling.sportenspel) {
      return (
        <div className="box box-default">
          <div className="card">
            <div className="card-header">
              Sport en Spel
              <div className="float-right">
                {beoordelingresult(beoordeling.sportenspel.sportenspel)}
              </div>
            </div>
            <div className="card-body">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">Voorbereid</div>
                  <div className="col-sm-9">
                    {beoordeling.sportenspel.voorbereid}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    Creëert een situatie waaruit gehandeld kan worden
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.sportenspel.handelen)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">Geeft spelbedoeling aan</div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.sportenspel.spelbedoeling
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">Praatje-plaatje-daadje</div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.sportenspel.praatjeplaatje
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    Enthousiasme tijdens spel (bijv. Moedigt aan, juiste
                    positie, geeft complimenten, helpt waar nodig)
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.sportenspel.enthousiast)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    Onderbreking (positieve feedback, past aan, geeft variatie)
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(
                      beoordeling.sportenspel.onderbreking
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    Afsluiting (positieve feedback, promotie)
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.sportenspel.afsluiting)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                  Staat zeker voor de groep
                  </div>
                  <div className="col-sm-4">
                    {beoordelingresultspel(beoordeling.sportenspel.voordegroep)}
                  </div>
                </div>
              </div>
              <h5 className="card-title paddingtop">
              Opmerkingen
              </h5>
              {beoordeling.sportenspel.opmerkingen }
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
  return { beoordeling: state.beoordelingen.item };
}

export default connect(mapStateToProps, {})(SportenSpelForm);
