import React from 'react';
import {connect} from 'react-redux';
import {updatetraining, gettraining} from './Reducers/actions';
import { fetchusers } from "../../../Reducers/auth/actions";
import CuristenForm from './Components/edit'
class TrainingPageInsert extends React.Component {
  state = {
    errors: {},
    loaded: false
  }
  componentDidMount() {
    this.setState({ loaded: false });

    this.props.fetchusers().then(data => {
        this.props.gettraining(this.props.match.params.id).then(data => {
            this.setState({ loaded: true });
        })
    });
  }

  submit = vals => {
      this.props.updatetraining(this.props.match.params.id, vals).then(data => {
 
        if (data.type ==="TRAINING_ERROR") {
          alert(data.payload);
          return false;
        } else {
              this.props.history.push('/admin/trainingen');
        }
      });
  }

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Training wijzigen</h3>
        </div>

      <div className="row">
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile">
          {this.state.loaded &&
            <CuristenForm onSubmit={this.submit}/>
          }
          </div>
        </div>
      </div>
    </div>
  </div>);
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, cursisten: state.cursisten};
}

export default connect(mapStateToProps, {fetchusers, updatetraining, gettraining})(TrainingPageInsert);
