import React from "react";
import { connect } from "react-redux";

import MessageSucces from "../../../Components/Forms/Validate/conform";
import MessageError from "../../../Components/Forms/Validate/error";
import {
  fetchbeoordelingcursisit,
  updatebeoordeling
} from "./Reducers/actions";
import AlgemeenRapport from "./Components/Rapport/algemeen";
import KwailiteitenRapport from "./Components/Rapport/kwailiteitenspel";
import PitchesRapport from "./Components/Rapport/pitches";
import SportenSpelRapport from "./Components/Rapport/sportenspel";
import Fun2DanceRapport from "./Components/Rapport/fun2dance";
import KindertheaterRapport from "./Components/Rapport/kindertheater";
class BeoordelingOverzicht extends React.Component {
  state = {
    errors: {},
    success: false,
    error: false,
    beoordelingen: {},
    loaded: true
  };

  render() {
    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Het profiel is goed opgeslagen"}
          success={this.state.success}
        />
        <MessageError
          tekst={"Er is iets fout gegaan"}
          error={this.state.error}
        />

        <AlgemeenRapport loaded={this.state.loaded} />
        <KwailiteitenRapport loaded={this.state.loaded} />
        <PitchesRapport loaded={this.state.loaded} />
        <SportenSpelRapport loaded={this.state.loaded} />
        <Fun2DanceRapport loaded={this.state.loaded} />
        <KindertheaterRapport loaded={this.state.loaded} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { beoordelingen: state.beoordelingen.item };
}

export default connect(mapStateToProps, {
  fetchbeoordelingcursisit,
  updatebeoordeling
})(BeoordelingOverzicht);
