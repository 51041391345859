import React, { Component } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
export default class BarGraph extends Component {
  render() {
    const { sensor, kleur, type } = this.props;
    var dateformate = "dd-MM";
    if (type === "maand") {
      dateformate = "MMM-YY";
    }
    if (type === "uur") {
      dateformate = "HH:mm";
    }
    function grdata(sensordata) {
      if (sensordata.data) {
        const columns = [
          { type: "datetime", label: "datum" },
          { type: "number", label: "KwH" },
        ];
        const rows = [];

        sensordata.data.map(function (a, key) {
          if (a.datum && a.value) {
            if (sensordata.temp && sensordata.temp[key] && key >0) {
              rows.push([new Date(a.datum), (sensordata.data[key - 1].value - a.value.toFixed(2))]);
              //rows.push([new Date(a.datum), sensordata.data[key - 1].value - a.value]);
            } else {
              if (type === "dag") {
                //rows.push([new Date(a.datum), (((a.value / 1000) * 690) / 1000).toFixed(2) * 24, 0]);
                rows.push([new Date(a.datum), a.value.toFixed(2)]);
              }
              if (type === "maand") {
                rows.push([new Date(a.datum), a.value.toFixed(2)]);
              }
            }
          } else {
            return;
          }
        });
        return [columns, ...rows];
      }
    }
    return (
      <Chart
        width={"100%"}
        height={"300px"}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={grdata(sensor)}
        options={{
          hAxis: {
            title: "Datum",
            format: dateformate,
          },
          colors: [kleur],
          vAxis: {
            title: "KwH",
          },

          seriesType: "bars",
          series: { 1: { type: "line" } },
        }}
        rootProps={{ "data-testid": "2" }}
      />
    );
  }
}
