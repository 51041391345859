import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { message } from "../../../Reducers/loading/actions";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { fetchreserveringen, deletereservering } from "./Reducers/actions";
import Loading from "../../../Components/loading/load";
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  componentWillMount() {
    if (!this.props.bedrijf._id) {
      this.props.getbedrijf(this.props.match.params.id).then((data) => {
        if (!data.results) {
          this.props.history.push("/404");
        } else {
          this.props.fetchreserveringen(this.props.bedrijf._id);
          this.setState({ loading: true });
        }
      });
    } else {
      this.props.fetchreserveringen(this.props.bedrijf._id);
      this.setState({ loading: true });
    }
  }

  deletein = (id) => {
    this.props.deletereservering(id).then((deln) => {
      this.props.fetchreserveringen(this.props.bedrijf._id).then((act) => {
        this.props.message(4000, "success", lang("Je inschrijving is succesvol verwijderd"));
      });
    });
  };
  render() {
    const { reserveringen } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("dddd DD MMMM");
    }
    const columns = [
      {
        dataField: "datum",
        text: lang("Datum"),
        formatter: priceFormatter,
      },
      {
        dataField: "tijd",
        text: lang("Tijd"),
      },
      {
        dataField: "faciliteit.naam",
        text: lang("Faciliteit"),
        formatter: (cellContent, row) => {
          return translate(cellContent);
        },
      },
      {
        dataField: "personen",
        text: lang("Personen"),
      },
      {
        text: lang("Uitschrijven"),
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
              <button className="btn btn-danger" onClick={this.deletein.bind(this, row._id)}>
                {lang("X")}
              </button>
            </span>
          );
        },
      },
    ];
    return (
      <div className="public-box-body">
        <Link
          className="btn btn-success pull-right bt-inschrijvingen"
          to={{
            pathname: "/public/faciliteiten/" + this.props.bedrijf._id,
          }}
        >
          {lang("Faciliteiten")}
        </Link>
        <h2 className="headline text-yellow">{lang("Reserveringen")}</h2>
        <div className="gastinfo">{lang("Wil je je uitschrijven voor een faciliteit? Klik dan op het rode kruisje.")}</div>
        <Loading loading={this.state.loading} />
        {this.state.loading && (
          <div className="activiteit-inschrijvingen">
            {reserveringen.length === 0 && <h3>{lang("Er zijn geen reserveringen")}</h3>}
            <BootstrapTable keyField="_id" data={reserveringen} columns={columns} wrapperClasses="table-responsive" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    reserveringen: state.openfaciliteiten.reserveringen,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchreserveringen,
  deletereservering,
})(Inschrijven);
