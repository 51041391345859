import {
  SET_WEBSITES,
  ADD_WEBSITES,
  WEBSITE_ERROR,
  SET_WEBSITE,
  SET_WEBSITE_PRGRESS
} from "./actions";
export default function cats(
  state = {
    item: [],
    items: [],
    counters: []
  },
  action = {}
) {
  switch (action.type) {
    case ADD_WEBSITES:
      return { ...state, items: [...state.itens, action.results] };
    case SET_WEBSITE:
      return { ...state, item: action.results };
    case SET_WEBSITE_PRGRESS:
      return { ...state, counters: action.results };
    case WEBSITE_ERROR:
      return { ...state, error: action.payload };
    case SET_WEBSITES:
      return { ...state, items: action.results };
    default:
      return state;
  }
}
