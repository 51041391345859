import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./style.scss";
import IndexKampeerplaatsen from "./Kampeerplaatsen/index";
import RequireAuthAdmin from "../../Auth/require_auth_admin";
import KampeerplaatsInsert from "./Kampeerplaatsen/insert"
import KampeerplaatsEditPage from "./Kampeerplaatsen/edit"
class StandplaatsRouter extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/standplaatsen/kampeerplaatsen/" component={IndexKampeerplaatsen} />
        <Route exact path="/standplaatsen/kampeerplaatsen/insert" component={KampeerplaatsInsert} />
        <Route exact path="/standplaatsen/kampeerplaatsen/edit/:id" component={KampeerplaatsEditPage} />
      </React.Fragment>
    );
  }
}

export default StandplaatsRouter;
