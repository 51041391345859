import React, { Component } from "react";
import { Route } from "react-router-dom";
import HorecaIndex from "./index"
import HorecaInsertPage from "./insert"
import HorecaEditPage from "./edit"
import InschrijfPageHoreca from "./inschrijvingen"
class HorecaRouter extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/horeca/" component={HorecaIndex} />
        <Route exact path="/horeca/insert" component={HorecaInsertPage} />
        <Route exact path="/horeca/edit/:id" component={HorecaEditPage} />
        <Route exact path="/horeca/reserveringen/:id" component={InschrijfPageHoreca} />
      </React.Fragment>
    );
  }
}

export default HorecaRouter;
