import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import Dagform from "./dag";
import {
  savebeschikbaarheid,
  getbeschikbaarheidweek,
} from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
class BeschikbaarheidComp extends React.Component {
  submit = (vals) => {
    const values = Object.assign(
      {},
      { faciliteiten: this.props.faciliteit._id },
      vals
    );
    this.props.savebeschikbaarheid(values).then((data) => {
      this.props.message(
        2000,
        "success",
        moment(vals.datum).format("DD-MM-YYYY") + " is succesvol opgeslagen"
      );
    });
  };


  render() {
    const { beschikbaarheid, tijden, dagen } = this.props;

    return (
      <div className="box-header with-border">
        <h2 className="box-title"> Beschikbaarheid</h2>
        <div className="box-body box-profile inschrijving">
          <div className="table-container">
            <div className="flex-row">
              <div className="flex-cell">Datum</div>
              {tijden.map((tijd, i) => (
                <div className="flex-cell" key={i}>
                  {tijd.tijd}
                </div>
              ))}
              <div className="flex-cell"></div>
            </div>
            {dagen.map((dag, i) => (
              <Dagform
                form={"formulier" + i}
                key={i}
                dag={dag}
                tijden={tijden}
                onSubmit={this.submit}
                bevals={beschikbaarheid}
              />
            ))}
          </div>
        </div>

      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    faciliteit: state.faciliteiten.item,
    beschikbaarheid: state.faciliteiten.beschikbaarheid,
  };
}

export default connect(mapStateToProps, {
  savebeschikbaarheid,
  getbeschikbaarheidweek,
  message,
})(BeschikbaarheidComp);
