import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { getfaciliteit, getbeschikbaarheidweek, getweek, saveweek, startweekvalues } from "./Reducers/actions";
import Beschikbaarheid from "./Components/beschikbaarheid";
import DatumForm from "./Components/datumform";
import OmschrijvingFrom from "./Components/omschrijving"
import WeekForm from "./Components/weekform";
import { message } from "../../Reducers/loading/actions";
import {translate} from "../../Components/Lib/Languages/language"
class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      tijden: [],
      starttijd: "",
      dagen: [],
      loadbschikbaar: false,
      weekloaded: false,
      week: [],
      datum: [],
    };
  }
  componentWillMount() {
    this.props.getfaciliteit(this.props.match.params.id).then((sdata) => {
      if (sdata.results) {
        this.setState({ datum: moment().format("WW-YYYY") });
        this.loadweek(moment().format("WW-YYYY"));
      }
    });
  }
  submit = (vals) => {
    this.setState({
      loadbschikbaar: false,
      weekloaded: true,
      datum: moment(vals.datum).format("WW-YYYY"),
    });
    this.loadweek(moment(vals.datum).format("WW-YYYY"));
  };

  loadweek = (datum) => {
    this.props
      .getweek(this.props.match.params.id, {
        datum: datum,
      })
      .then((data) => {
        if (data.results) {
          this.setbeschikbaarheid(data.results, datum);
          this.setState({ datum: datum, week: data.results, weekloaded: true });
        } else {
          this.setState({
            datum: datum,
            week: this.props.faciliteit,
            loadbschikbaar: false,
          });
        }
        this.setState({ loaded: true });
      });
  };
  submitweek = (vals) => {
    const values = Object.assign(
      {},
      {
        datum: this.state.datum,
        faciliteiten: this.props.match.params.id,
      },
      vals
    );
    this.props.saveweek(values).then((data) => {
      this.setState({ loadbschikbaar: false });
      this.props.message(2000, "success", "De week is opgeslagen wordt nu geladen").then((mes) => {
        this.setbeschikbaarheid(data.results.results, this.state.datum);
        this.setState({ loadbschikbaar: true });
      });
    });
  };

  startweek = (vals) => {
    const values = Object.assign(
      {},
      {
        datum: this.state.datum,
        faciliteiten: this.props.match.params.id,
      },
      vals
    );
    this.props.startweekvalues(values).then((data) => {
      this.setState({ loadbschikbaar: false });
      this.props.message(2000, "success", "De week is opgeslagen wordt nu geladen").then((mes) => {
            this.loadweek(this.state.datum);
      });
    });
  };

  setbeschikbaarheid = (data, datum) => {
    const startdatum = moment(datum, "WW-YYYY").startOf("week").toISOString();
    const eindatum = moment(datum, "WW-YYYY").endOf("week").toISOString();
    this.setState({
      startdatum: startdatum,
      eindatum: eindatum,
      starttijd: data.starttijd,
      eindtijd: data.eindtijd,
    });
    var start = moment.duration(data.starttijd, "HH:mm");
    var diff = Math.round(moment.duration(data.eindtijd, "HH:mm").subtract(start).as("minutes") / data.tijdsloten);
    var arr = [];
    for (let i = 0; i < diff; i++) {
      arr.push({
        tijd: moment(data.starttijd, "HH:mm")
          .add(data.tijdsloten * i, "minutes")
          .format("HH:mm"),
      });
    }
    this.setState({ tijden: arr });

    var dag = [];
    for (let i = 0; i < 7; i++) {
      dag.push(moment(startdatum).add(i, "days").toISOString());
    }
    this.setState({ dagen: dag });
    this.props
      .getbeschikbaarheidweek({
        startdatum: startdatum,
        eindatum: eindatum,
        faciliteit: this.props.faciliteit._id,
      })
      .then((data) => {
        this.setState({ loadbschikbaar: true });
      });
  };

  setweek = (week) => {
    this.loadweek(moment(this.state.datum, "WW-YYYY").add(week, "weeks").format("WW-YYYY"));
  };

  render() {
    const { faciliteit, week } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-title">
            <h2>{translate(faciliteit.naam)}</h2>
          </div>
          <Link to="/faciliteiten" className="btn btn-primary btn-sm float-right" role="group">
            Overzicht
          </Link>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.loaded && (
              <div className="box-body box-profile">
                <DatumForm week={week} onSubmit={this.submit} />
                {this.state.weekloaded && <div>
                  <WeekForm datum={this.state.datum} week={this.state.week} onSubmit={this.startweek} />
                  <OmschrijvingFrom datum={this.state.datum} week={this.state.week} onSubmit={this.submitweek} />
                  </div>}
                <div>
                  {this.state.loadbschikbaar && (
                    <Beschikbaarheid
                      faciliteit={faciliteit}
                      startdatum={this.state.startdatum}
                      eindatum={this.state.eindatum}
                      tijden={this.state.tijden}
                      starttijd={this.state.starttijd}
                      eindtijd={this.state.eindtijd}
                      dagen={this.state.dagen}
                    />
                  )}
                </div>
                <div className="box-body">
                  <button onClick={this.setweek.bind(this, -1)} className="btn btn-next btn-fill btn-success btn-wd btn-sm pull-left">
                    Vorige week
                  </button>
                  <button onClick={this.setweek.bind(this, 1)} className="btn btn-next btn-fill btn-success btn-wd btn-sm pull-right">
                    Volgende week
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    faciliteit: state.faciliteiten.item,
    week: state.faciliteiten.week,
  };
}

export default connect(mapStateToProps, {
  getfaciliteit,
  getbeschikbaarheidweek,
  getweek,
  saveweek,
  message,
  startweekvalues
})(ProfilePage);
