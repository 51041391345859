import React, { useState } from "react";
import { Link } from "react-router-dom";
const AdministratieMenu = ({ token }) => {
  const [isActive, setIsActive] = useState(false);
  if (token.role === "admin") {
    return (
      <li className="treeview">
        <a className="cursurhand" onClick={() => setIsActive(!isActive)}>
          <i className="fa fa-book" />
          <span>Administratie</span>
          <span className="pull-right-container">
            <i className="fa fa-angle-left pull-right" />
          </span>
        </a>
        <ul className={isActive ? "treeview-menu menu-open" : "treeview-menu "}>
          <li>
            <Link to="/admin/bedrijven" onClick={() => setIsActive(!isActive)}>
              <i className="fa fa-book" />
              <span>Bedrijven</span>
            </Link>

            <Link to="/admin/gebruikers/" onClick={() => setIsActive(!isActive)}>
              <i className="fa fa-users" />
              <span>Gebruikers</span>
            </Link>
          </li>
        </ul>
      </li>
    );
  } else {
    return null;
  }
};
export default AdministratieMenu;
