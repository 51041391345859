import React from "react";
import { connect } from "react-redux";


class PitchesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentWillMount() {}
  render() {
    const { beoordeling } = this.props;
    function beoordelingresult(result) {
       if (result ==="onvoldoende") {
        return <span className='badge bg-red'>{result}</span>
       }
       if (result ==="voldoende") {
        return <span className='badge bg-orange'>{result}</span>
       }
       if (result ==="redelijk") {
        return <span className='badge bg-blue'>{result}</span>
       }
       if (result ==="goed") {
        return <span className='badge bg-green'>{result}</span>
       }
      }
    if (this.props.loaded && beoordeling.pitches) {
      return (
        <div className="box box-default">
          <div className="card">
            <div className="card-header">
              Pitches
              <div className="float-right">{beoordelingresult(beoordeling.pitches.pitch)}</div>
            </div>
            <div className="card-body">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3">Voorbereid</div>
                  <div className="col-sm-3">{beoordeling.pitches.voorbereid}</div>
                </div>
              </div>
              <h5 className="card-title paddingtop">Uitvoering</h5>
              {beoordeling.pitches.uitvoering}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
    return { beoordeling: state.beoordelingen.item };
  }
  
  export default connect(mapStateToProps, {  })(PitchesForm);
