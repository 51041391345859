import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/nl";
import "react-datepicker/dist/react-datepicker.css";
class Datetime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      maxDate: false
    };
  }
  componentDidMount() {
    if (this.props.input.value) {
      const date = new moment(this.props.input.value).toDate();
      this.setState({ date });
    }
    if (this.props.maxDate) {

      this.setState({ maxDate: new Date(this.props.maxDate) });
    }
  }

  handleSelect = (val) => {
    const value = new moment(val).toDate();
    this.setState({ date: val });
    this.props.input.onChange(value);
  };

  render() {
    const { label, error, warning, touched } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div>
          <DatePicker
            selected={this.state.date}
            onChange={this.handleSelect}
            showTimeSelect
            className="form-control"
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="time"
            maxDate={this.state.maxDate}
            dateFormat="dd-MM-yyyy HH:mm"
          />
          {touched &&
            ((error && <span>{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default Datetime;
