import {
  SET_ACTIVITEITEN,
  PUBLIC_ERROR,
  SET_ACTIVITEIT,
  SET_BEDRIJF,
  SET_INSCHRIJVINGEN
} from "./actions";
export default function cats(
  state = {
    activiteit: [],
    activiteiten: [],
    inschrijvingen: [],
    bedrijf: [],
  },
  action = {}
) {
  switch (action.type) {
    case SET_BEDRIJF:
      return { ...state, bedrijf: action.results };
    case SET_ACTIVITEIT:
      return { ...state, activiteit: action.results };
    case SET_INSCHRIJVINGEN:
      return { ...state, inschrijvingen: action.results };
    case PUBLIC_ERROR:
      return { ...state, error: action.payload };
    case SET_ACTIVITEITEN:
      return { ...state, activiteiten: action.results };
    default:
      return state;
  }
}
