import React from 'react';
import {connect} from 'react-redux';
import {editsensor, getsensor} from '../Reducers/actions';
import { fetchalertprofiles } from "../Profiles/Reducers/actions";

import SensorEditForm from './Components/edit'
class SensorEditPage extends React.Component {
  state = {
    errors: {},
    loaded: false
  }


  componentWillMount() {
    this.setState({ loaded: false });
        this.props.getsensor(this.props.match.params.id).then(data => {
          this.props.fetchalertprofiles().then((bedrij) => {
            this.setState({ loaded: true });
          });
        })
  }

  submit = vals => {
    delete vals['data'];
      this.props.editsensor(this.props.match.params.id,vals).then(data => {
        if (data.type ==="SENSOR_ERROR") {
          alert(data.payload);
          return false;
        } else {
              this.props.history.push('/sensoren');
        }
      });
  }

  render() {
    const {alertprofile} = this.props
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Sensor wijzigen</h3>
        </div>

      <div className="row">
      <div className="col-md-12 no-float">
        <div className="box box-primary">
          <div className="box-body box-profile">
          {this.state.loaded &&
            <SensorEditForm alertprofile={alertprofile} onSubmit={this.submit}/>
            }</div>
        </div>
      </div>
    </div>
  </div>);
  }
};
function mapStateToProps(state) {
  return {username: state.auth.user, sensoren: state.sensoren.item, alertprofile: state.alertprofile.items };
}

export default connect(mapStateToProps, {editsensor, getsensor, fetchalertprofiles})(SensorEditPage);
