import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { message } from "../../../Reducers/loading/actions";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import { getbedrijf, fetchinschrijvingen, deleteinschrijving } from "./Reducers/actions";
import Loading from "../../../Components/loading/load";
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  componentWillMount() {
    if (!this.props.bedrijf._id) {
      this.props.getbedrijf(this.props.match.params.id).then((data) => {
        if (!data.results) {
          this.props.history.push("/404");
        } else {
          this.props.fetchinschrijvingen(this.props.bedrijf._id);
          this.setState({ loading: true });
        }
      });
    } else {
      this.props.fetchinschrijvingen(this.props.bedrijf._id);
      this.setState({ loading: true });
    }
  }

  deletein = (id) => {
    this.props.deleteinschrijving(id).then((deln) => {
      this.props.fetchinschrijvingen(this.props.bedrijf._id).then((act) => {
        this.props.message(2000, "success", lang("Je inschrijving is succesvol verwijderd"));
      });
    });
  };
  render() {
    const { inschrijvingen } = this.props;

    function priceFormatter(cell, row) {
      return moment(cell).format("dddd DD MMMM");
    }
    const columns = [
      {
        dataField: "activiteit.datum",
        text: lang("Datum"),
        formatter: priceFormatter,
      },
      {
        dataField: "activiteit.tijd",
        text: lang("Tijd"),
      },
      {
        dataField: "activiteit.activiteit",
        text: lang("Activiteit"),
        formatter: (cellContent, row) => {
          return translate(cellContent);
        },
      },
      {
        dataField: "personen",
        text: lang("Pers."),
      },
      {
        text: lang("Uitschrijven"),
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
              <button className="btn btn-danger" onClick={this.deletein.bind(this, row._id)}>
                X
              </button>
            </span>
          );
        },
      },
    ];
    return (
      <div className="public-box-body">
        <Link
          className="btn btn-success pull-right bt-inschrijvingen"
          to={{
            pathname: "/public/activiteiten/" + this.props.bedrijf._id,
          }}
        >
          {lang("Activiteiten")}
        </Link>
        <h2 className="headline text-yellow">{lang("Inschrijvingen")}</h2>
        <div className="gastinfo">{lang("Wil je je uitschrijven voor een activiteit? Klik dan op het rode kruisje.")}</div>
        <Loading loading={this.state.loading} />
        {this.state.loading && (
          <div className="activiteit-inschrijvingen">
            {inschrijvingen.length === 0 && <h3>{lang("Er zijn geen inschrijvingen")}</h3>}
            <BootstrapTable keyField="_id" data={inschrijvingen} columns={columns} wrapperClasses="table-responsive" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    inschrijvingen: state.pubred.inschrijvingen,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchinschrijvingen,
  deleteinschrijving,
})(Inschrijven);
