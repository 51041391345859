import React, { Component } from "react";
import RichTextEditor from "react-rte";

class RichTextMarkdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.input.value === "" ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(this.props.input.value, "html"),
    };
  }

  componentDidMount() {
    if (this.props.input.value) {
      //this.setState({value: this.props.input.value});
    }
  }

  onChange = (value) => {
    this.setState({ value });
    this.props.input.onChange(value.toString('html'));
  };

  render() {
    const { label } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div>
          <RichTextEditor className="wysiwyg-editor" value={this.state.value} onChange={this.onChange} />
        </div>
      </div>
    );
  }
}

export default RichTextMarkdown;