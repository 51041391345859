import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import DateTimeInput from "../../../Components/Forms/datetime";
import DateInput from "../../../Components/Forms/datepast";
import TimeInput from "../../../Components/Forms/time";
import LanInput from "../../../Components/Forms/Languages/input"
import LanTextField from "../../../Components/Forms/Languages/textfield"
import {
  renderField,
  renderselect,
} from "../../../Components/Forms/renders_langs";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }
componentDidMount() {

    
}
  inschrijving = (value) => {    
      this.setState({inschrijving: JSON.parse(value.target.value)})
  }
  render() {
    const { handleSubmit } = this.props;    
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            name="datum"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={DateInput}
            label="Datum van de Activiteit"
          />
          <Field
            name="tijd"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={TimeInput}
            label="Tijd van de activiteit"
          />
          <Field
            name="activiteit"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={LanInput}
            label="Naam van de activiteit"
            languages={["NL","DU","FR","EN"]}
          />
          <Field
            name="beschrijving"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={LanTextField}
            languages={["NL","DU","FR","EN"]}
            label="Beschrijving van de activiteit"
          />
          <Field
            name="inschrijving"
            validate={required({ msg: "Type item is verplicht" })}
            label="Inschrijving noodzakelijk"
            component={renderselect}
            options={{
              true: "Ja",
              false: "Nee",
            }}
            onChange={(value) => this.inschrijving(value)}
          />
          {this.state.inschrijving && (
            <div>
              <Field
                name="maxdeelnemers"
                type="number"
                validate={required({ msg: "Dit item is verplicht" })}
                component={renderField}
                label="Maximaal aantal deelnemers"
              />
              <Field
                name="startinschrijving"
                component={DateTimeInput}
                type="input"
                validate={required({ msg: "Dit item is verplicht" })}
                label="Start datum inschrijvingen"
              />
              <Field
                name="eindinschrijving"
                component={DateTimeInput}
                type="input"
                validate={required({ msg: "Dit item is verplicht" })}
                label="Eind datum inschrijvingen"
              />
            </div>
          )}
          <input
            type="submit"
            className="btn btn-next btn-fill btn-success btn-wd btn-mg"
            name="next"
            value="Wijzigen"
          />
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state) => ({

  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
