import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderselect,
  renderTextField
} from "../../../../Components/Forms/renders";
class Fun2Dancse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }ƒ

  render() {
    const { handleSubmit } = this.props;
    if (this.props.loaded) {
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
              <Field
                  className="form"
                  name="fun2dance.fun2dance"
                  label="Fun2Dance"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="fun2dance.ritme"
                  label="Ritmegevoel?"
                  component={renderselect}
                  options={{
                    ja: "ja",
                    nee: "nee",
                  }}
                />
                     <Field
                  className="form"
                  name="fun2dance.uitvoering"
                  label="Uitvoering"
                  component={renderTextField}
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input
                type="submit"
                className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                name="next"
                value="Beoordelen"
              />
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
  }
}
Fun2Dancse = reduxForm({
  form: "beoordelingfun2dance", // a unique identifier for this form
  enableReinitialize: true
})(Fun2Dancse);

// You have to connect() to any reducers that you wish to connect to yourself
Fun2Dancse = connect(
  state => ({
    initialValues: state.beoordelingen.item, // pull initial values from account reducer

  }),
  {}
)(Fun2Dancse);

export default Fun2Dancse;

