import { SET_BEDRIJVEN, BEDRIJF_ERROR, SET_BEDRIJF  } from "./actions";
export default function horeca(
  state = {
    error: {},
    items: [],
    item: [],
  },
  action = {}
) {
  switch (action.type) {
    case BEDRIJF_ERROR:
      return { ...state, error: action.payload };
    case SET_BEDRIJF:
      return { ...state, item: action.results };
    case SET_BEDRIJVEN:
      return { ...state, items: action.results };
    default:
      return state;
  }
}
