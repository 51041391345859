import React from "react";
import { connect } from "react-redux";
import { Savelog, Verwerken } from "./Reducers/actions";
import LogboekForm from "./Components/insertformwebsite";
import {message} from "../../Reducers/loading/actions"
import moment from "moment";
class LogboekInsertPage extends React.Component {
  state = {
    errors: {},
    activiteit: "",
    beschrijving: "",
    datum: "",
    tijd: ""
  };

  componentWillMount() {
    if (this.props.location.row) {
      this.setState({
        activiteit: this.props.location.row.activiteit,
        beschrijving: this.props.location.row.beschrijving,
        datum: moment(this.props.location.row.datum).format("DD/MM/YYYY"),
        tijd: this.props.location.row.tijd,
        id: this.props.location.row._id
      });
    } else {
      this.props.history.push("/logboek/website");
    }
  }

  submit = vals => {
    vals.activiteit = this.state.activiteit;
    vals.beschrijving = this.state.beschrijving;
    vals.datum = this.state.datum;
    vals.tijd = this.state.tijd;
    this.props.Savelog(vals).then(data => {
      if (data.type === "LOGBOEK_ERROR") {
        this.props.message(3000, "error", "Er is een fout opgetreden");
        return false;
      } else {
        this.props.Verwerken({id: this.state.id}).then(datav => {
          this.props.message(3000, "success", "De activiteit is succesvol opgeslagen").then(data => {
          this.props.history.push("/logboek/website");
          })
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Logboeken van </h3>
        </div>
        <div className="box-body box-profile">
          <li className="time-label">
            <span className="bg-red">
              {this.state.datum} {this.state.tijd}
            </span>
          </li>
          <div className="timeline-item">
            <h3 className="timeline-header">{this.state.activiteit}</h3>
            <div className="timeline-body">{this.state.beschrijving}</div>
            <div className="timeline-footer" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <LogboekForm onSubmit={this.submit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, logboeken: state.logboeken };
}

export default connect(
  mapStateToProps,
  { Savelog, Verwerken, message }
)(LogboekInsertPage);
