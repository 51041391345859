import React from "react";
import { connect } from "react-redux";
import { saveactiviteitenmanual } from "./Reducers/actions";
import LogboekForm from "./Components/insert";
import {message} from "../../Reducers/loading/actions"
import { getonewebsite } from "../Website/Reducers/actions";
class LogboekInsertPage extends React.Component {
  state = {
    errors: {},
    load: true,
  };

  componentWillMount() {    
 
  }

  submit = (vals) => {
    this.props.saveactiviteitenmanual(vals).then((data) => {
      if (data.type === "ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is succesvol verwerkt").then(data => {
          this.props.history.push("/activiteiten/insert/website");
        });
      }
    });
  };

  render() {
    return (
      <div className="box box-default maincontent">
        <div className="box-header with-border">
          <h3 className="box-title"> Activiteit toevoegen </h3>
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                {this.state.load && <LogboekForm onSubmit={this.submit} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    logboeken: state.logboeken,
    website: state.website.item,
  };
}

export default connect(mapStateToProps, { saveactiviteitenmanual, getonewebsite, message })(
  LogboekInsertPage
);
