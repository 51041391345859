export const SET_BIEPP_BIEPP_ACTIVITEITEN = "SET_BIEPP_BIEPP_ACTIVITEITEN";
export const ADD_BIEPP_BIEPP_ACTIVITEITEN = "ADD_BIEPP_BIEPP_ACTIVITEITEN";
export const BIEPP_ACTIVITEIT_ERROR = "BIEPP_ACTIVITEIT_ERROR";
export const SET_BIEPP_ACTIVITEIT = "SET_BIEPP_ACTIVITEIT";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function set(results) {
  return {
    type: SET_BIEPP_BIEPP_ACTIVITEITEN,
    results,
  };
}

export function setone(results) {
  return {
    type: SET_BIEPP_ACTIVITEIT,
    results,
  };
}
export function add(results) {
  return {
    type: SET_BIEPP_ACTIVITEIT,
    results,
  };
}
export function itemError(error) {
  return {
    type: BIEPP_ACTIVITEIT_ERROR,
    payload: error,
  };
}

export function savebiepactiviteit(data) {
  return (dispatch) => {
    return fetch("/api/admin/biepp/activiteiten", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(add(results));
        return results;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function saveactiviteitenmanual(data) {
  return (dispatch) => {
    return fetch("/api/admin/activiteiten/manual", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(add(results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
export function maakinschrijving(data) {
  return (dispatch) => {
    return fetch("/api/admin/activiteiten/inschrijven", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(add(results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function editbiepactiviteit(id, vals) {
  var data = new FormData();
  for (var i = 0; i < vals.file.length; i++) {
    data.append("file", vals.file[i])
}
  data.append("data", JSON.stringify(vals));
  return (dispatch) => {
    return fetch("/api/admin/biepp/activiteiten/" + id, {
      method: "post",
      body: data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(add(results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function UploadFiles(data, id) {
  return async (dispatch) => {
    var formData = new FormData();
    formData.append("file", data.file);
    try {
      const response = await fetch("/api/fotoboek/upload/" + id, {
        method: "post",
        body: formData,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const data = await handleResponse(response);
      return dispatch(setone(data.results));
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het uploaden van de bestanden"));
    }
  };
}
export function delbiepactiviteit(id) {
  return (dispatch) => {
    return fetch("/api/admin/biepp/activiteiten/" + id, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function delinschrijving(id) {
  return (dispatch) => {
    return fetch("/api/admin/inschrijvingen/" + id, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getbiepactiviteit(id) {
  return (dispatch) => {
    return fetch("/api/admin/biepp/activiteiten/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setone(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchactiviteiten() {
  return (dispatch) => {
    return fetch("/api/admin/biepp/activiteiten/", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(set(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
export function fetchactiviteitenmetinschrijving() {
  return (dispatch) => {
    return fetch("/api/admin/activiteiten/inschrijvingen", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(set(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function Verwerken(data) {
  return (dispatch) => {
    return fetch("/api/admin/activiteiten/website/verwerken", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return results.results;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
