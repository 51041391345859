import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import ProfileFotoPage from "./Components/ProfielFoto";
import MessageSucces from "../../../Components/Forms/Validate/conform";
import MessageError from "../../../Components/Forms/Validate/error";
import { Link } from "react-router-dom";
import { fetchcurist } from "./Reducers/actions";
import { gettraining, deletecursist } from "../Trainingen/Reducers/actions";
import Beoordelingen from "../Beoordelingen/cursist";
import BeoordelingenOverzicht from "../Beoordelingen/overzicht"
class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cursist: {},
      loadbeoordeling: false
    };
  }
  componentWillMount() {
    this.setState({ loadbeoordeling: false });
    this.props.fetchcurist(this.props.match.params.id).then(data => {
      if (data.results) {
        this.setState({ cursist: data.results });
        this.props.gettraining(this.props.match.params.training).then(data => {
          this.setState({ loadbeoordeling: true });
        });
      }
    });
  }

  deletetraining = id => {
    this.props.deletecursist(id, { cursist: this.props.match.params.id });
  };

  render() {
    const { cursist, training } = this.props;
    function datum(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    function leeftijd(datum) {    
      if (datum) {
        var birthday = moment(datum, "DD/MM/YYYY");    
        return moment().diff(birthday, "years");
      }
    }

    function meerderjaars(ervaring) {   
      if (ervaring ==="1") {
        return <span className='label label-success'>Eerste jaars</span>
      }
      if (ervaring ==="2") {
        return <span className='label label-warning'>Meerderjaars</span>
      }
    }

    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Het profiel is goed opgeslagen"}
          success={this.state.success}
        />
        <MessageError
          tekst={"Er is iets fout gegaan"}
          error={this.state.error}
        />
        <div className="box-header with-border">
          <div className="box-title">
            <h2>
              {cursist.voornaam} {cursist.achternaam} 
            </h2>
          </div>
          <Link
            to={"/admin/trainingen/" + this.props.match.params.training}
            className="btn btn-primary btn-lg float-right"
            role="group"
          >
            Overzicht
          </Link>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="box-header with-border">
              <h3 className="box-title"> Trainingen</h3>
              <div className="box-body box-profile" />
              <div className="react-bootstrap-table">
                {training._id && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Titel</th>
                        <th>Trainer</th>
                        <th style={{ width: "20px" }}>Acties</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{datum(training.datum)}</td>
                        <td>{training.titel}</td>
                        <td>{training.trainer.voornaam}</td>
                        <td>
                          <button
                            onClick={this.deletetraining.bind(
                              this,
                              training._id
                            )}
                            className="btn"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                  Leeftijd: <span className="geboortedatum">{leeftijd(cursist.geboortedatum)}</span>
                  {cursist.bedrijf && <div>Park: <span className="geboortedatum">{cursist.bedrijf.naam}</span></div>}
                  {meerderjaars(cursist.ervaring)}
              </div>
            </div>
            <div className="box-header with-border">
              <h3 className="box-title"> Beoordelingen</h3>
              {training._id && cursist._id && this.state.loadbeoordeling && (
                <Beoordelingen training={training} cursist={cursist} />
              )}
            </div>
          </div>
          <div className="col-md-5">
            <ProfileFotoPage cursist={this.state.cursist} />
          </div>
        </div>
        <div className="row">
        <div className="col-md-12">
        {training._id && cursist._id && this.state.loadbeoordeling && (
                <BeoordelingenOverzicht training={training} cursist={cursist} />
              )}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    cursist: state.cursisten.item,
    training: state.trainingen.item,
    beoordelingen: state.beoordelingen.item
  };
}

export default connect(mapStateToProps, {
  fetchcurist,
  gettraining,
  deletecursist
})(ProfilePage);
