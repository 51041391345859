import React, { Component } from "react";
import { connect } from "react-redux";
import { message } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { fetchhoreca } from "./Reducers/actions";
import Horeca from "./Components/horeca";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
class FacilitetenPage extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
  };

  componentWillMount() {
    this.props.getbedrijf(this.props.match.params.id).then((data) => {
      if (!data.results) {
        this.props.history.push("/404");
      } else {
        this.props.fetchhoreca(data.results._id).then((act) => {
          this.setState({ loading: true });
        });
      }
    });
  }

  render() {
    const { horeca } = this.props;

    return (
      <div className="public-box-body">
        <h2 className="headline text-yellow facheadline"> {lang("Horeca gelegenheden")}</h2>
        <Loading loading={this.state.loading} />
        {this.state.loading && (
          <div className="activiteit-content">
            <div className="gastinfo">
              {lang(
                 "Om gebruik te maken van onze horeca is het verplicht om je per tafel te registeren. Hier vind je de horecagelegenheid"
              )}
            </div>
            {horeca.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
            {horeca.map((horec) => (
              <div key={horec._id} className="activiteit-content">
                <Horeca horeca={horec} link={true} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    horeca: state.horecap.items,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  fetchhoreca,
})(FacilitetenPage);
