import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./style.scss";
import SensorTempIndex from "./Temperatuur/index";
import SensorEditPage from "./Sensor/edit";
import SensorInsert from "./Sensor/insert";
import SensorItem from "./Temperatuur/show";
import AlertProfile from "./Profiles/index";
import AlertProfileInsert from "./Profiles/insert";
import AlertProfileEdit from "./Profiles/edit";
import SensorElecIndex from "./Elecktra/index"
import SensorItemElectraAMP from "./Ampere/show"
import SensorIndexGas from "./Gas/index"
import SensorItemGas from "./Gas/show"
import SensorIndexWater from "./Water/index"
import SensorItemWater from "./Water/show"
import SensorItemElectra from "./Elecktra/show"
import RequireAuthAdmin from "../../Auth/require_auth_admin";
import InsexSensor from ".";
class SensorRouter extends Component {
  render() {
    return (
      <React.Fragment>
         <Route exact path="/sensoren/" component={InsexSensor} />
        <Route exact path="/sensoren/sensor/" component={SensorTempIndex} />
        <Route exact path="/sensoren/sensor/temperatuur" component={SensorTempIndex} />
        <Route exact path="/sensoren/sensor/electra" component={SensorElecIndex} />
        <Route exact path="/sensoren/sensor/amp/show/:id" component={SensorItemElectraAMP} />
        <Route exact path="/sensoren/sensor/gas" component={SensorIndexGas} />
        <Route exact path="/sensoren/sensor/toevoegen" component={RequireAuthAdmin(SensorInsert)} />
        <Route exact path="/sensoren/sensor/show/:id" component={SensorItem} />
        <Route exact path="/sensoren/sensor/electra/show/:id" component={SensorItemElectra} />
        <Route exact path="/sensoren/sensor/gas/show/:id" component={SensorItemGas} />
        <Route exact path="/sensoren/sensor/edit/:id" component={SensorEditPage} />
        <Route exact path="/sensoren/alertprofile" component={AlertProfile} />
        <Route exact path="/sensoren/sensor/water" component={SensorIndexWater} />
        <Route exact path="/sensoren/sensor/water/show/:id" component={SensorItemWater} />
        <Route exact path="/sensoren/alertprofile/insert" component={RequireAuthAdmin(AlertProfileInsert)} />
        <Route exact path="/sensoren/alertprofile/edit/:id" component={RequireAuthAdmin(AlertProfileEdit)} />
  
      </React.Fragment>
    );
  }
}

export default SensorRouter;
