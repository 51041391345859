export const SET_SENSOREN = 'SET_SENSOREN';
export const ADD_SENSOREN = 'ADD_SENSOREN';
export const SENSOR_ERROR = 'SENSOR_ERROR';
export const SET_SENSOR_MAAND = 'SET_SENSOR_MAAND';
export const SET_SENSOR_DAG = 'SET_SENSOR_DAG'
export const SET_SENSOR_UUR = 'SET_SENSOR_UUR'
export const SET_SENSOR = 'SET_SENSOR'


function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_SENSOREN,
    results
  }
}
export function setone_dag(results) {
  return {
    type: SET_SENSOR_DAG,
    results
  }
}
export function setone_uur(results) {
  return {
    type: SET_SENSOR_UUR,
    results
  }
}
export function setone_maand(results) {
  return {
    type: SET_SENSOR_MAAND,
    results
  }
}
export function setone(results) {
  return {
    type: SET_SENSOR,
    results
  }
}
export function add(results) {
  return {
    type: ADD_SENSOREN,
    results
  }
}
export function itemError(error) {
  return {
    type: SENSOR_ERROR,
    payload: error
  };
}

export function Savesensor(data) {
  return dispatch => {
    return fetch('/api/admin/sensor', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results));
    }).catch((err) => {
      console.log(err);
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function editsensor(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensoren/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(add(results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
 
export function fetchsensoren() {
  return dispatch => {
    return fetch('/api/admin/sensoren/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchsensorentemp() {
  return dispatch => {
    return fetch('/api/admin/sensoren/temp', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function fetchsensorenelectra() {
  return dispatch => {
    return fetch('/api/admin/sensoren/electra', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchsensorgas() {
  return dispatch => {
    return fetch('/api/admin/sensoren/gas', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensor(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorelectra(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/electra/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorgas(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/gas/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function getsensorelectra_dag(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/electra/day/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_dag(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorgas_dag(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/gas/day/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_dag(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function getsensorelectra_uur(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/electra/hour/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_uur(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorgas_uur(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/gas/hour/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_uur(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorelectra_maand(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/electra/month/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_maand(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getsensorgas_maand(id, data) {
  return dispatch => {
    return fetch('/api/admin/sensor/gas/month/'+id, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone_maand(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

