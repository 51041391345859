import React, { Component } from "react";
import { Link } from "react-router-dom";
class NoMatch extends Component {
  render() {
    return (
      <div className="login-box-body">
        <h2 className="headline text-yellow"> 404</h2>
        <div className="error-content">
          <h3>
            <i className="fa fa-warning text-yellow" /> Oops! de pagina is niet
            gevonden
          </h3>
          <p>
            We hebben de pagina niet gevonden waar je naar toe wilde gaan. Ga
            terug naar de <Link to={{ pathname: "/" }}>begin pagina</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default NoMatch;
