import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import Multiselect from "../../../../Components/Forms/multiselect";
import UploadFrom from "../../../../Components/Forms/upload";
import RichTextMarkdown from "../../../../Components/Forms/wysiwyg";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            className="form"
            autoFocus={true}
            name="materialen"
            label="Materialen"
            component={Multiselect}
            options={[
              "Bal",
              "Hoepel",
              "Springtouw",
              "Pilon groot",
              "Pilon Klein",
              "Pilon",
              "steunpaal",
              "Volleybalnet",
              "Dopjes",
              "Lintjes",
              "Basketbal",
              "Volleybal",
              "Voetbal",
              "Schaar",
              "Lijm",
              "Gekleurd papier",
              "Papier",
              "Stiften",
              "Potloden",
              "Wc rollen",
              "Crepe papier",
              "Verf",
              "Kwasten",
              "Eierdozen",
              "Ballonen",
              "Balonnen pomp",
              "Pennen",
              "Rommeldoos",
              "Nietmachine",
              "Touw",
              "lampion folie",
              "Afzetlint",
              "Afzet palen",
              "Rookmachine",
              "Poster",
              "Spandoek",
              "Muziekinstallatie",
              "Microfooon",
              "Headset",
              "Tafels",
              "Banken",
              "Klei",
              "Perferator",
              "Thema kleding",
              "Verkleedkleding",
              "Schmink",
              "Tennisballen",
              "Badmintonracket",
              "Shuttle",
              "Fluitje",
              "Scorebord",
              "Bierviltjes",
              "Aluminiumfolie",
              "Paal",
              "Wasknijpers",
              "Wiebel oogjes",
            ]}
          />
          <Field
            name="voorbereiding"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={RichTextMarkdown}
            label="Voorbereiding activiteit"
          />
          <Field
            name="uitleg"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={RichTextMarkdown}
            label="Uitleg activiteit"
          />
          <Field name="tips" type="input" component={RichTextMarkdown} label="Tips voor de activiteit" />
          <Field name="file" type="input" component={UploadFrom} label="Upload hier de bestanden" />
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg submit-bt" name="next" value="Bijwerken" />
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "editbieppactiviet1", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state) => ({
    initialValues: state.biepp.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
