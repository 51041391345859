import React from "react";
import { connect } from "react-redux";
import { editbiepactiviteit, getbiepactiviteit } from "./Reducers/actions";
import StartForm from "./Components/edit/insertform";
import OmschrijvingForm from "./Components/edit/insertact";
import { fixmessage, message } from "../../Reducers/loading/actions";
import { Bar } from "./Components/bar";
class BieppEdit extends React.Component {
  state = {
    errors: {},
    load: false,
    step: "0",
    id: null,
  };

  componentWillMount() {
    this.props.getbiepactiviteit(this.props.match.params.id).then((data) => {
      if (data.results) {
        if (this.props.match.params.step) {
          this.setState({ id: this.props.match.params.id, step: this.props.match.params.step, load: true });
        } else {
          this.setState({ load: true, id: this.props.match.params.id });
        }
      } else {
        this.props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de activititeit")
      }
    });
  }

  submit = (vals) => {
    this.props.editbiepactiviteit(this.state.id, vals).then((data) => {
      if (data.type === "BIEPP_ACTIVITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(2000, "success", "De activiteit is succesvol verwerkt").then((datas) => {
          this.props.history.push("/biepp/edit/" + this.state.id+ "/1");
        });
      }
    });
  };

  selectform = (step) => {
    if (step === "0") {
      return <StartForm onSubmit={this.submit} />;
    }
    if (step === "1") {
      return <OmschrijvingForm onSubmit={this.submit} />;
    }
  };

  render() {
    return (
      <div className="box box-default maincontent">
        <div className="box-header with-border">
          <h3 className="box-title"> Activiteit bewerken </h3>
        </div>
        <div className="wizard">
          <Bar step={this.state.step} id={this.state.id} />
        </div>
        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">{this.state.load && this.selectform(this.state.step)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    biepp: state.biepp.item,
  };
}

export default connect(mapStateToProps, { editbiepactiviteit, getbiepactiviteit, fixmessage, message })(BieppEdit);
