import { SET_GASTEN, GASTEN_ERROR } from "./actions";
export default function cats(
  state = {
    gast: [],
    error: []
  },
  action = {}
) {
  switch (action.type) {
    case SET_GASTEN:
      return { ...state, activiteit: action.results };
    case GASTEN_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
