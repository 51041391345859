import React from "react";
import { connect } from "react-redux";


class KwailiteitenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentWillMount() {}
  render() {
    const { beoordeling } = this.props;
    if (this.props.loaded && beoordeling.kwaliteitenspel) {
      return (
        <div className="box box-default">
          <div className="card">
            <div className="card-header">
              Kwaliteitenspel
            </div>
            <div className="card-body">
              <h5 className="card-title paddingtop">Waarin blinkt de deelnemer uit?</h5>
              {beoordeling.kwaliteitenspel.top}
              <h5 className="card-title paddingtop">Wat zijn de uitdagingen voor de deelnemer?</h5>
              {beoordeling.kwaliteitenspel.bijzonderheden}
              <h5 className="card-title paddingtop">Welke kwaliteit heb je ontvangen?</h5>
              {beoordeling.kwaliteitenspel.ontvangen}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
    return { beoordeling: state.beoordelingen.item };
  }
  
  export default connect(mapStateToProps, {  })(KwailiteitenForm);
