import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Textlang from "../../../Components/Forms/Languages/textfield";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="weekform" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              name="omschrijving"
              type="input"
              component={Textlang}
              languages={["NL", "DU", "FR", "EN"]}
              label="Extra omschrijving van deze week"
            />
          </div>
        </div>
        <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "omschrijvingform", // a unique identifier for this form
  enableReinitialize: true,
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state, props) => ({
    initialValues: props.week, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
