import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderTextField } from "../../../../Components/Forms/renders";

class BedrijvenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {}
  render() {
    const { handleSubmit, alertprofile } = this.props;

    return (
      <div className="box box-default">
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <div className="row">
            <div className="col-6">
              <Field
                className="form"
                name="active"
                validate={required({ msg: "Type item is verplicht" })}
                label="Standplaats actief"
                component={renderselect}
                options={{
                  true: "Actief",
                  false: "Uitgeschakeld",
                }}
              />
              <Field name="naam" type="input" validate={[required()]} component={renderField} label="Naam kampeerplaats" />
              <Field name="beschrijving" type="input" component={renderTextField} label="beschrijving kampeerplaats" />
              <Field name="device_id" type="input" component={renderField} label="Vul de Sensor ID in" />
            </div>
          </div>
          <div className="pull-left submit">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Opslaan" />
          </div>
        </div>
      </form>
    </div>
    );
  }
}
BedrijvenForm = reduxForm({
  form: "bedrijfinsert", // a unique identifier for this form
})(BedrijvenForm);

// You have to connect() to any reducers that you wish to connect to yourself
BedrijvenForm = connect()(BedrijvenForm);

export default BedrijvenForm;
