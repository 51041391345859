import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message, clearmessage, fixmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { lang } from "../../../Components/Lib/Languages/language";
import Loading from "../../../Components/loading/load";
import { fetchhoreca } from "../Horeca/Reducers/actions";
import { fetchopenfaciliteiten } from "../Faciliteiten/Reducers/actions";
import Horeca from "../Horeca/Components/horeca";
import Faciliteiten from "../Faciliteiten/Components/faciliteit";

class FacilitetenPage extends Component {
  state = {
    errors: {},
    loading: false,
    datum: "",
    loadform: true,
    horeca: false,
    faciliteiten: false,
    activiteiten: false
  };

  componentWillMount() {
    this.props.getbedrijf(this.props.match.params.id).then((data) => {
      if (!data.results) {
        this.props.history.push("/public");
      } else {
        if (data.results.modules) {
          if (data.results.modules.includes("horecas")) {
            this.props.fetchhoreca(data.results._id).then((act) => {
              this.setState({ loaded: true, horeca: true });
            });
          } if (data.results.modules.includes("faciliteiten")) {
            this.props.fetchopenfaciliteiten(data.results._id).then((act) => {
              this.setState({ loaded: true, faciliteiten: true });
            });
          } else {
            this.setState({ loaded: true });
          }
        } else {
          this.setState({ loaded: true });
        }
      }
    });
  }
  componentWillUnmount() {
    this.props.clearmessage();
  }

  render() {
    const { bedrijfp, horeca, faciliteiten } = this.props;
    return (
      <div className="public-box-body">
        <h2 className="headline text-yellow facheadline"> {lang("Kies een bedrijfsonderdeel")}</h2>
        <Loading loading={this.state.loaded} />
        {this.state.loaded && (
          <div className="inschrijf-content">
            <div>
              {bedrijfp.modules.length === 0 && <h3>{lang("Er zijn geen bedrijfonderdelen geactiveerd voor dit bedrijf")} </h3>}
              {this.state.horeca && (
                <div>
                  <h4 className="headline text-yellow"> {lang("Horeca")}</h4>
                  {horeca.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
                  {horeca.map((horec) => (
                    <div key={horec._id} className="activiteit-content">
                      <Horeca horeca={horec} link={true} />
                    </div>
                  ))}
                </div>
              )}
              {this.state.faciliteiten && (
                <div>
                    <h4 className="headline text-yellow"> {lang("Faciliteiten")}</h4>
                  {faciliteiten.length === 0 && <h3>{lang("Er zijn geen faciliteiten waar je voor kan reserveren")} </h3>}
                  {faciliteiten.map((faciliteit) => (
                    <div key={faciliteit._id} className="activiteit-content">
                      <Faciliteiten faciliteit={faciliteit} link={true} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <Link
          to={{
            pathname: "/public/",
          }}
          className="back-arrow"
        >
          <i className="fa fa-chevron-left"></i> {lang("Terug")}
        </Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijfp: state.bedrijfp.item,
    horeca: state.horecap.items,
    faciliteiten: state.openfaciliteiten.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  getbedrijf,
  clearmessage,
  fetchhoreca,
  fetchopenfaciliteiten,
})(FacilitetenPage);
