import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import datumField from "../../../Components/Forms/datepast";
import moment from "moment";
import { renderselecttijden, renderselect } from "../../../Components/Forms/renders";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3">
            <Field
              name="datum"
              type="input"
              validate={required({ msg: "Dit item is verplicht" })}
              component={datumField}
              label="Kies een datum"
              defaultValue={moment().format("DD/MM/YYYY")}
              onChange={this.props.changedate}
            />
          </div>
          {this.props.tijden.length > 0 && (
            <div className="col-md-3">
              <Field name="tijd" type="number" component={renderselecttijden} options={this.props.tijden} label={"Kies een tijd"} />
            </div>
          )}
          <div className="col-md-2">
            <Field
              name="aanwezig"
              label="Kies aanwezigheid"
              component={renderselect}
              options={{
                true: "Ja",
                false: "Nee",
              }}
            />
          </div>
          <div className="col-md-3">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg weekformsubmit" name="next" value="Zoeken" />
          </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state, props) => ({
    initialValues: props.week, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
