export const SET_ACTIVITEITEN = 'SET_ACTIVITEITEN';
export const ADD_ACTIVITEITEN = 'ADD_ACTIVITEITEN';
export const ACTIVITEIT_ERROR = 'ACTIVITEIT_ERROR';
export const SET_ACTIVITEIT = 'SET_ACTIVITEIT'
export const SET_VERGELIJKEN = 'SET_VERGELIJKEN'
export const SET_JAAR ='SET_JAAR'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_ACTIVITEITEN,
    results
  }
}
export function setjaar(results) {
  return {
    type: SET_JAAR,
    results
  }
}
export function setvergelijken(results) {
  return {
    type: SET_VERGELIJKEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_ACTIVITEIT,
    results
  }
}
export function add(results) {
  return {
    type: SET_ACTIVITEIT,
    results
  }
}
export function itemError(error) {
  return {
    type: ACTIVITEIT_ERROR,
    payload: error
  };
}

export function saveactiviteiten(data) {
  return dispatch => {
    return fetch('/api/admin/activiteiten', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function saveactiviteitenmanual(data) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/manual', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function maakinschrijving(data) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/inschrijven', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function updateactiviteit(id, data) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/'+id, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      dispatch(add(results));
      return true;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function delactiviteit(id) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return true
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function delinschrijving(id) {
  return dispatch => {
    return fetch('/api/admin/inschrijvingen/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return true
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function updateinschrijving(id, data) {
  return dispatch => {
    return fetch('/api/admin/inschrijvingen/'+id, {
      method: 'put',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return true
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function getactiviteit(id) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchactiviteiten() {
  return dispatch => {
    return fetch('/api/admin/activiteiten/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function fetchactiviteitenmetinschrijving() {
  return dispatch => {
    return fetch('/api/admin/activiteiten/inschrijvingen', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function vergelijken(data) {
  return dispatch => {
    return fetch('/api/admin/vergelijken/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setvergelijken(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function Verwerken(data) {
  return dispatch => {
    return fetch('/api/admin/activiteiten/website/verwerken', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return results.results;
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function veranderjaar(data) {
  return dispatch => {
    return dispatch(setjaar(data));
  }
}
