import React from "react";
import { hydrate, render } from "react-dom";
import Analytics from "react-router-ga";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import jwtDecode from "jwt-decode";
import rootReducer from "./rootReducer";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch } from "react-router-dom";
import { AUTH_TOKEN, AUTH_USER } from "./Reducers/auth/types";
import "bootstrap/dist/js/bootstrap"; // for responsive navbar dropdown and modal dialog
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../node_modules/dropzone/dist/min/dropzone.min.css";
import "./lib/css/ADMStyle.css";
import "./lib/css/skins/skin-blue.css";
import "./index.css";
import "./lib/css/index.scss";
import "./lib/css/style.css";
import "react-dates/lib/css/_datepicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const store = createStore(rootReducer, applyMiddleware(thunk));
try {
  const token = jwtDecode(localStorage.getItem("token"));
  // update application state with token information if needed
  if (token) {
    store.dispatch({ type: AUTH_TOKEN, token: token });
    store.dispatch({ type: AUTH_USER });
  }
} catch (error) {
  console.log(error);
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Analytics id="UA-46769096-3">
        <Provider store={store}>
          <Switch>
            <App />
          </Switch>
        </Provider>
      </Analytics>
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <Analytics id="UA-46769096-3">
        <Provider store={store}>
          <Switch>
            <App />
          </Switch>
        </Provider>
      </Analytics>
    </BrowserRouter>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
