import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
class CursistenComponent extends React.Component {
  state = {
    cursisten:[]
  };

  rowEvents = (e, row, rowInde) => {
    console.log(e);
    
    
  }


  render() {
  
    const { cursisten } = this.props;
    const profileimage = (profile) => {
      if (profile && profile.portimage) {
        return <img className="square img-thumbnail" src={profile.portimage} alt="User"/>
      } else {
        return <img src="/img/nofoto.jpg" className="square img-thumbnail" alt="User"/>
      }
    }
    const columns = [
      {
        dataField: "portimage",
        text: "afbeelding",
        formatter: (cellContent, row) => {
          return (
            <span>
    
              <Link
                to={{ pathname: "/admin/trainingen/" + cursisten._id +"/cursist/"+row._id }}
              >
                {profileimage(row)}
              </Link>
            </span>
          );
        }
      },
      {
        dataField: "voornaam",
        text: "Voornaam"
      },
      {
        dataField: "achternaam",
        text: "Achternaam"
      },
      {
        dataField: "bedrijf.naam",
        text: "Bedrijf"

      },
      {
        dataField: "geboortedatum",
        text: "",
        formatter: (cellContent, row) => {
          if (row.ervaring ==="1") {
            return <span className='label label-success'>Eerste jaars</span>
          }
          if (row.ervaring ==="2") {
            return <span className='label label-warning'>Meerderjaars</span>
          }
        },
        headerStyle: (colum, colIndex) => {
          return { width: "50x" };
        }
      },
      {
        dataField: "_id",
        text: "acties",
        formatter: (cellContent, row) => {
          return (
            <span>
    
              <Link
                className="btn btn-success"
                to={{ pathname: "/admin/trainingen/" + cursisten._id +"/cursist/"+row._id }}
              >
                Bekijken
              </Link>
            </span>
          );
        }
      }
    ];   
    if (cursisten && cursisten.cursisten) {
    return (

            <BootstrapTable
              keyField="_id"
              data={ cursisten.cursisten}
              columns={columns}
              pagination={paginationFactory({ sizePerPage: 50})}
      
            />

    );
    }
    else {
return null
    }
  }
}



export default CursistenComponent;
