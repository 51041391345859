import { SET_STANDPLAATSEN, ADD_STANDPLAATSEN,SET_KAMPEERPLAATS, SET_KAMPEERPLAATSEN, SET_STANDPLAATS,STANDPLAATS_ERROR } from "./actions";
export default function standplaatsen(
  state = {
    item: [],
    items: [],
    kampeerplaatsen: [],
    kampeerplaats:{},
    error:{}
  },
  action = {}
) {
  switch (action.type) {
    case ADD_STANDPLAATSEN:
      return { ...state, items: [...state.items, action.results] };
    case SET_STANDPLAATS:
      return { ...state, item: action.results };
    case SET_KAMPEERPLAATSEN:
      return { ...state, kampeerplaatsen: action.results };
      case SET_KAMPEERPLAATS:
        return { ...state, kampeerplaats: action.results };
    case SET_STANDPLAATSEN:
      return { ...state, items: action.results };
      case STANDPLAATS_ERROR:
        return { ...state, error: action.payload };
    default:
      return state;
  }
}
