import React, { Component } from "react";
import { Route } from "react-router-dom";
import Faciliteiten from "./index"
import FaciliteitenInsert from "./insert"
import FaciliteitenEdit from "./edit"
import FaciliteitPage from "./faciliteit"
import  InschrijPage from "./inschrijvingen"
class Inschrijvingen extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/faciliteiten/" component={Faciliteiten} />
        <Route exact path="/faciliteiten/insert" component={FaciliteitenInsert} />
        <Route exact path="/faciliteiten/edit/:id" component={FaciliteitenEdit} />
        <Route exact path="/faciliteiten/show/:id" component={FaciliteitPage} />
        <Route exact path="/faciliteiten/reserveringen/:id" component={InschrijPage} />
      </React.Fragment>
    );
  }
}

export default Inschrijvingen;
