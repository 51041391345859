import React from "react";
import { connect } from "react-redux";
import {fetchhoreca} from "./Reducers/actions"
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { message } from "../../Reducers/loading/actions";
class InschrijvingPage extends React.Component {
  state = {
    errors: {},
    loading: false
  };

  componentWillMount() {
    this.getvals({});
  }

  getvals = () => {
    this.props.fetchhoreca().then((data) => {
      if (data.type !== "HORECA_ERROR") {
        this.setState({loading: false})
      }
    });
  };


  handleItem(item) {
    this.props.Verwerken({ id: item._id }).then((datav) => {
      this.getvals({});
    });
  }

  render() {
 
    const columns = [
      {
        dataField: "naam",
        text: "Naam horeca",
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
               <Link
                className="btn btn-warning"
                to={{ pathname: "/horeca/edit/"+row._id}}
              >
                Wijzigen
              </Link>{" "}
              <Link
                className="btn btn-success"
                to={{ pathname: "/horeca/show/"+row._id}}
              >
                Tafels invoeren
              </Link>{" "}
              <Link
                className="btn btn-info"
                to={{ pathname: "/horeca/reserveringen/"+row._id}}
              >
                Reserveringen
              </Link>
            </span>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "450px" };
        },
      },
    ];
    const { horeca } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Horeca gelegenheden</h3>
          <div className="box-tools pull-right">
            <Link
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
              to="/horeca/insert"
            >
              <i className="fa fa-plus"></i>&nbsp; Horeca Toevoegen
            </Link>
          </div>
        </div>
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12 no-float">
              <div className="box box-primary">
                <div className="box-body box-profile" />
                <BootstrapTable
                  keyField="_id"
                  data={horeca}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.auth.user,
    horeca: state.horeca.items,
  };
}

export default connect(mapStateToProps, {
  message,
  fetchhoreca
})(InschrijvingPage);
