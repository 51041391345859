import React from "react";

import { Link } from "react-router-dom";

import { Sparklines, SparklinesLine } from "react-sparklines";
import moment from "moment";

class SensorWidget extends React.Component {
  state = {
    errors: {},
  };

  render() {
    const { sensoren } = this.props;
    const result = sensoren.filter((obj) => obj.favo === true);
    function seturl(sen, id) {
      if (sen === "temperatuur") {
        return "/sensoren/sensor/show/" + id;
      } else if (sen === "gas") {
        return "/sensoren/sensor/gas/show/" + id;
      } else if (sen === "electra") {
        return "/sensoren/sensor/electra/show/" + id;
      } else if (sen === "water") {
        return "/sensoren/sensor/water/show/" + id;
      } else if (sen === "ampere") {
        return "/sensoren/sensor/amp/show/" + id;
      }
    }

    function graph(row) {
      const data = [];
      if (row.sensordatas.length > 0) {
        row.sensordatas.map(function (a, key) {
          data.push(a.value);
          return a;
        });
      }
      if (row.electradatas.length > 0) {
        row.electradatas.map(function (a, key) {
          if (key > 0) {
            data.push(Number(a.value) - Number(row.electradatas[key - 1].value));
          }
          return a;
        });
      }
      return (
        <Sparklines height={100} data={data.reverse()}>
          <SparklinesLine color="#848c45" />
        </Sparklines>
      );
    }
    function lastvalue(row) {
      if (row.sensordatas.length > 0) {
        return row.sensordatas[0].value.toFixed(2);
      } else if (row.electradatas.length > 1 && row.soort === "electra") {
        return (Number(row.electradatas[0].value) - Number(row.electradatas[1].value)).toFixed(2);
      } 
      else if (row.electradatas.length > 1 && row.soort === "water") {
        return ((Number(row.electradatas[0].value) - Number(row.electradatas[1].value))/100).toFixed(2);
      } else {
        return null;
      }
    }
    function luodate(momn) {
      if (momn.electradatas && momn.electradatas[0]) return moment(momn.electradatas[0].datum).fromNow();

      if (momn.sensordatas && momn.sensordatas[0]) return moment(momn.sensordatas[0].datum).fromNow();
    }
    function isalert(status) {
      if (status == "false") return "card-header alerttemp";
      else return "card-header";
    }
    return (
      <div className="row">
        {result.map((sensor, key) => (
          <div key={key} className="col-lg-4 p-4">
            <div key={key} className="tempsensor card card-primary">
              <div className={isalert(sensor.status)}>
                <h3 className="card-title">{sensor.naam}</h3>
              </div>
              {graph(sensor)}
              <div className="details">
                <div className="valuekwh"> {lastvalue(sensor)}</div>
                <div className="meeting">Laatste meting {luodate(sensor)}</div>
              </div>
              <div className="card-footer">
                <Link className="small-box-footer" to={{ pathname: seturl(sensor.soort, sensor._id) }}>
                  Bekijk de sensor <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default SensorWidget;
