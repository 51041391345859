import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getprofile, signoutUser } from "../../Reducers/auth/actions";
import { fetchprofile } from "../../Pages/profile/Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import { fetchbedrijven } from "../../Pages/Admin/Bedrijven/Reducers/actions";
class Header extends Component {
  componentWillMount() {
    this.props.getprofile().then((data) => {
      if (data && data.type === "auth_error") {
        this.props.signoutUser();
      }
    });
    this.props.fetchprofile();
    this.props.fetchbedrijven().then(data => {});
  }
  Logout() {
    this.props.signoutUser();
    this.props.message(3000, "info", "Je bent succesvol uitgelogd");
  }

  render() {
    const { username, profile } = this.props;

    const Profileimage = (profile) => {
      if (profile && profile.portimage) {
        return <img src={profile.portimage} title="Logo Recraplan" className="img-circle" alt="Gebuiker foto" />;
      } else {
        return <img src="/img/nofoto.jpg" title="Logo Recraplan" className="img-circle" alt=" foto" />;
      }
    };


    return (
      <header className="main-header">
        <Link to="/" className="logo">
          <span className="logo-mini">
          <img src="/img/logo_small.png" alt="Logo Recraplan" height={55} />
          </span>
          <span className="logo-lg">
           <img src="/img/logo.png" alt="Logo Recraplan"  height={55} />
          </span>
        </Link>

        <nav className="navbar navbar-static-top">
          {/* Sidebar toggle button */}
          <Link to="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
            <span className="sr-only">Toggle navigation</span>
            <span className="fas fa-bars"></span>
          </Link>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a href="_blank" className="dropdown-toggle" data-toggle="dropdown">
                  <span className="hidden-xs">
                    {username.voornaam} {username.achternaam}
                  </span>
                </a>
                <ul className="dropdown-menu">
                  {/* User image */}
                  <li className="user-header">
                    {Profileimage(profile)}
                    <p></p>
                  </li>
                  <li className="user-footer d-flex justify-content-around">
                    <div>
                      <Link to="/admin/profile" className="btn btn-default btn-flat">
                        Profiel
                      </Link>
                    </div>
                    <div>
                      <Link to="/login" onClick={() => this.Logout()} className="btn btn-default btn-flat">
                        Uitloggen
                      </Link>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return { errors: state.auth.error, username: state.auth.user, profile: state.profile };
}
export default connect(mapStateToProps, { fetchprofile, getprofile, signoutUser, message, fetchbedrijven })(Header);
