import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf } from "../Activiteiten/Reducers/actions";
import { getfaciliteit, getbeschikbaarheid, savereververing } from "./Reducers/actions";
import { Animated } from "react-animated-css";
import Faciliteit from "./Components/faciliteit";
import InschrijfForm from "./Components/datumform";
import Loading from "../../../Components/loading/load";
import { lang, translate } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";
import moment from "moment";
class FacilteitPage extends Component {
  state = {
    errors: {},
    loading: false,
    tijden: [],
    datum: "",
    aanmeldingen: 0,
    week: false,
    nodata: false,
    vol: true,
  };

  componentWillMount() {
    this.props.getfaciliteit(this.props.match.params.id).then((data) => {
      if (data.results) {
        if (!this.props.bedrijf._id) {
          this.props.getbedrijf(data.results.bedrijf).then((bdata) => {
            this.setState({ loaded: true });
          });
        }
        if (data.results.vol) {
          this.props.fixmessage("danger", lang("Helaas is het niet mogelijk om een reservering te plaatsen, omdat je al een reservering(en) hebt"));
          this.setState({ vol: false, loaded: true });
        } else {
          this.setState({ vol: true, loaded: true });
        }
      }
    });
  }
  componentWillUnmount() {
    this.props.clearmessage();
  }
  setdate = (datum) => {
    this.props.clearmessage();
    this.props.getbeschikbaarheid({ faciliteit: this.props.match.params.id, datum: moment(datum).toISOString() }).then((data) => {
      if (data.results) {
        if (data.results.tijden.filter((item) => item.tijd !== undefined).length > 0) {
          this.setState({ tijden: data.results.tijden, nodata: false, week: true, datum: moment(datum).toISOString() });
        } else {
          this.setState({ tijden: [], nodata: true, week: false, datum: moment(datum).toISOString() });
        }
      } else {
        this.setState({ tijden: [], nodata: true, week: false, datum: moment(datum).toISOString() });
      }
    });
  };

  showinfo = (week) => {
    if (this.state.week && week && week.weekinfo && week.weekinfo.omschrijving)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-info"}>
            <p>{translate(week.weekinfo.omschrijving)}</p>
          </div>
        </Animated>
      );
  };

  nodatainfo = (info) => {
    if (this.state.nodata)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-warning"}>
            <p>{info}</p>
          </div>
        </Animated>
      );
  };
  submit = (vals) => {
    const checktijd = this.state.tijden.find((a) => a.tijd === vals.tijd);
    if (checktijd.aanmeldingen[0] && parseInt(checktijd.personen,0) < (parseInt(checktijd.aanmeldingen[0].personen,0) + parseInt(vals.personen,0))) {
      this.setState({ loaded: true });
      var maxtotaal = parseInt(checktijd.personen,0) - parseInt(checktijd.aanmeldingen[0].personen,0);
      this.props.message(
      10000,
        "danger",
        lang("Het maximaal aantal personen is bereikt. Je kan nog") + " " + maxtotaal + " " + lang("personen aanmelden")
      );
    } else {
      this.setState({ loaded: false });
      const maxpers = this.state.tijden.find((el) => el.tijd === vals.tijd);
      var nogover = maxpers.personen - (maxpers.aanmeldingen.personen || 0);
      if (nogover >= vals.personen) {
        vals.datum = this.state.datum;
        vals.faciliteit = this.props.match.params.id;
        this.props.savereververing(vals).then((data) => {
          if (data.type === "FACILITEIT_ERROR") {
            alert(data.payload);
            this.setState({ loaded: true });
            return false;
          } else {
            this.props.message(4000, "success", lang("Je reservering is succesvol verwerkt")).then((data) => {
              this.props.history.push("/public/faciliteiten/" + this.props.bedrijf._id);
            });
          }
        });
      } else {
        this.setState({ loaded: true });
        this.props.message(
          4000,
          "danger",
          lang("Het maximaal aantal personen is bereikt. Je kan nog") + " " + nogover + " " + lang("personen aanmelden")
        );
      }
    }
  };

  render() {
    const { faciliteit, beschikbaarheid } = this.props;
    return (
      <div className="public-box-body">
        <Loading loading={this.state.loaded} />
        {this.state.loaded && (
          <div>
            <Faciliteit faciliteit={faciliteit} />
            <h2 className="headline text-yellow"> {lang("Reserveren")}</h2>
            {this.showinfo(beschikbaarheid)}
            {this.state.vol && (
              <div className="inschrijf-content">
                <InschrijfForm tijden={this.state.tijden} setdate={this.setdate} onSubmit={this.submit} />
                {this.nodatainfo(lang("Het is helaas niet mogelijk een reservering te maken op deze datum (activiteit/faciliteit is volgeboekt)."))}
              </div>
            )}
          </div>
        )}
        <Link
          to={{
            pathname: "/public/faciliteiten/" + faciliteit.bedrijf,
          }}
          className="back-arrow"
        >
          <i className="fa fa-chevron-left"></i> {lang("Terug")}
        </Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    faciliteit: state.faciliteiten.item,
    beschikbaarheid: state.faciliteiten.beschikbaarheid,
  };
}

export default connect(mapStateToProps, {
  message,
  getbedrijf,
  getfaciliteit,
  getbeschikbaarheid,
  savereververing,
  fixmessage,
  clearmessage,
})(FacilteitPage);
