import React from "react";
import { connect } from "react-redux";
import { fetchalertprofiles } from "../Profiles/Reducers/actions";
import { Savesensor } from "../Reducers/actions";

import BedrijvenForm from "./Components/form";
class SensorInsert extends React.Component {
  state = {
    errors: {},
    loaded: false,
  };

  componentWillMount() {
    this.setState({ loaded: false });
    this.props.fetchalertprofiles().then((bedrij) => {
      this.setState({ loaded: true });
    });
  }

  submit = (vals) => {
    this.props.Savesensor(vals).then((data) => {
      if (data.type === "SENSOR_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.history.push("/sensoren");
      }
    });
  };

  render() {
    const {alertprofile} = this.props
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Sensor toevoegen</h3>
        </div>

        <div className="row">
          <div className="col-md-12 no-float">
            <div className="box box-primary">
              <div className="box-body box-profile">
                {this.state.loaded && 
                <BedrijvenForm alertprofile={alertprofile} onSubmit={this.submit} />
  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { username: state.auth.user, sensoren: state.sensoren, alertprofile: state.alertprofile.items };
}

export default connect(mapStateToProps, { Savesensor, fetchalertprofiles })(SensorInsert);
