export const SET_FACILITEITEN = 'SET_FACILITEITEN';
export const ADD_FACILITEITEN = 'ADD_FACILITEITEN';
export const FACILITEIT_ERROR = 'FACILITEIT_ERROR';
export const SET_FACILITEIT = 'SET_FACILITEIT'
export const SET_BESCHIKBAARHEID = 'SET_BESCHIKBAARHEID'
export const  SET_RESERVERINGEN = 'SET_RESERVERINGEN'

function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_FACILITEITEN,
    results
  }
}

export function setbeschikbaarheid(results) {
  return {
    type: SET_BESCHIKBAARHEID,
    results
  }
}

export function setreservering(results) {
  return {
    type: SET_RESERVERINGEN,
    results
  }
}

export function setone(results) {
  return {
    type: SET_FACILITEIT,
    results
  }
}
export function add(results) {
  return {
    type: SET_FACILITEIT,
    results
  }
}
export function itemError(error) {
  return {
    type: FACILITEIT_ERROR,
    payload: error
  };
}

export function deletereservering(id) {
  return dispatch => {
    return fetch('/api/public/reservering/'+id, {
      method: 'delete',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(results.results);
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchreserveringen(id) {
  return dispatch => {
    return fetch('/api/public/reserveringen/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setreservering(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function getfaciliteit(id) {
  return dispatch => {
    return fetch('/api/public/faciliteit/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast'),
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function getbeschikbaarheid(data) {  
  return dispatch => {
    return fetch('/api/public/beschikbaarheid/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setbeschikbaarheid(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function savereververing(data) {    
  return dispatch => {
    return fetch('/api/public/reservering/', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return results;
    }).catch((err) => {
      console.log(err);
      
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}


export function fetchopenfaciliteiten(bedrijf) {
  return dispatch => {
    return fetch('/api/public/faciliteiten/'+bedrijf, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem('gast')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

