import React, { Component } from "react";
import { Route } from "react-router-dom";
import GebruikersPage from "./index";
import GebruikerEditPage from "./edit";
import GebruikerInsertPage from "./insert"
class Gebruikers extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/gebruikers" component={GebruikersPage} />
        <Route exact path="/gebruikers/edit/:id" component={GebruikerEditPage} />
        <Route exact path="/gebruikers/toevoegen" component={GebruikerInsertPage} />
      </React.Fragment>
    );
  }
}

export default Gebruikers;
