import React, { Component } from "react";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
export default class Tabel extends Component {
  render() {
    const { sensor } = this.props;
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY HH:mm");
    }


    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "value",
        text: "KwH",
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (sensor.maxvalue <= (((cellContent / 1000) * 690) / 1000).toFixed(2)) {
              return <span className="badge badge-danger">{(((cellContent / 1000) * 690) / 1000).toFixed(2)}</span>;
            } else {
              return <span className="badge badge-success">{(((cellContent / 1000) * 690) / 1000).toFixed(2)}</span>;
            }
          } else {
            return null;
          }
        },
      },
      {
        dataField: "decoded_payload.Current1",
        text: "Fase 1",
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (sensor.maxvalue <= (((cellContent / 1000) * 230) / 1000).toFixed(2)) {
              return <span className="badge badge-danger">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            } else {
              return <span className="badge badge-success">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            }
          } else {
            return null;
          }
        },
      },
      {
        dataField: "decoded_payload.Current2",
        text: "Fase 2",
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (sensor.maxvalue <= (((cellContent / 1000) * 230) / 1000).toFixed(2)) {
              return <span className="badge badge-danger">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            } else {
              return <span className="badge badge-success">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            }
          } else {
            return null;
          }
        },
      },
      {
        dataField: "decoded_payload.Current3",
        text: "Fase 3",
        formatter: (cellContent, row) => {
          if (cellContent) {
            if (sensor.maxvalue <= (((cellContent / 1000) * 230) / 1000).toFixed(2)) {
              return <span className="badge badge-danger">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            } else {
              return <span className="badge badge-success">{(((cellContent / 1000) * 230) / 1000).toFixed(2)}</span>;
            }
          } else {
            return null;
          }
        },
      },
    ];
    return <BootstrapTable wrapperClasses="table-responsive-md" keyField="_id" data={sensor.data} columns={columns} pagination={paginationFactory()} />;
  }
}
