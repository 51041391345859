import React from "react";
import moment from "moment";
class BarComponent extends React.Component {
  componentDidMount() {}
  render() {
    const { reserveringen, datumres } = this.props;
    function datum(cell) {
      return moment(cell).format("DD-MM-YYYY");
    }

    function teldeelnemers(reser) {
      if (reser && reser.length > 0) {
        var u = reser.map((item) => item.personen).reduce((prev, next) => prev + next);
        return u;
      } else {
        return 0;
      }
    }

    return (
      <div className="barback">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-clock-o"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Datum</span>
                <span className="info-box-number">{datum(datumres)}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-clock-o"></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Aantal reserveringen</span>
                <span className="info-box-number">{reserveringen.length}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-aqua">
                <i className="fa fa-users"></i>
              </span>
              <div className="info-box-content">
                <span className="info-box-text">Aantal personen</span>
                <span className="info-box-number">{teldeelnemers(reserveringen)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BarComponent;
