import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../../Components/Forms/renders";
import { lang } from "../../../../Components/Lib/Languages/language";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            name="tafel"
            type="input"
            validate={required({ msg: lang("Dit item is verplicht") })}
            component={renderselect}
            options={[ "Terras", "Binnenlocatie" ]}
            label={lang("Kies je locatie")}
          />
          <Field
            name="personen"
            type="number"
            validate={required({ msg: lang("Dit item is verplicht") })}
            component={renderField}
            label={lang("Vul het aantal personen in")}
          />
          <Field
            name="telefoonnummer"
            type="input"
            validate={required({ msg: lang("Dit item is verplicht") })}
            component={renderField}
            label={lang("Vul je telefoonnummer in")}
          />
          <div className="submitform">
            <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value={lang("Verzenden")} />
          </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "tafelinsert", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  () => ({}) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
