export const SET_VERHUUR = 'SET_VERHUUR';
export const ADD_VERHUUR = 'ADD_VERHUUR';
export const VERHUUR_ERROR = 'VERHUUR_ERROR';


function handleResponse(response) {
  if (response.ok) {
    return response.json();

  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_VERHUUR,
    results
  }
}


export function itemError(error) {
  return {
    type: VERHUUR_ERROR,
    payload: error
  };
}




export function fetchverhuur() {
  return dispatch => {
    return fetch('/api/admin/faciliteiten/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

