import React, { Component } from "react";
import { Route } from "react-router-dom";
import PublicPageActiviteiten from "./Activiteiten/index";
import PublicPageInschrijving from "./Activiteiten/inschrijven"
import RequireAuthAdmin from "../../Auth/require_gast"
import gastLogout from "./Gasten/Components/adminregist"
import PublicPageMijnInschrijvingen from "./Activiteiten/mijninschrijvingen"
import FacilietenPage from "./Faciliteiten/index"
import ReserveringPage from "./Faciliteiten/mijnreserveringen"
import FacilteitPage from "./Faciliteiten/faciliteit"
import Login from "./Gasten/index"
import VerifyLogin from "./Gasten/verify"
import Horecas from "./Horeca/index"
import Horeca from "./Horeca/horeca"
import Bedrijven from "./Bedrijven/index"
import Bedrijf from "./Bedrijven/bedrijf"
class Inschrijvingen extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/public/" component={Bedrijven} />
        <Route exact path="/public/:id" component={Login} />
        <Route exact path="/public/gast/verify/:id" component={VerifyLogin} />
        <Route exact path="/public/activiteiten/:id" component={RequireAuthAdmin(PublicPageActiviteiten)} />
        <Route exact path="/public/inschrijvingen/activiteit/:id" component={RequireAuthAdmin(PublicPageInschrijving)} />
        <Route exact path="/public/inschrijvingen/:id" component={RequireAuthAdmin(PublicPageMijnInschrijvingen)} />
        <Route exact path="/public/faciliteiten/:id" component={RequireAuthAdmin(FacilietenPage)} />
        <Route exact path="/public/faciliteit/:id" component={RequireAuthAdmin(FacilteitPage)} />
        <Route exact path="/public/reserveringen/:id" component={RequireAuthAdmin(ReserveringPage)} />
        <Route exact path="/public/adminregist/:soort/:id" component={gastLogout(Login)} />
        <Route exact path="/public/horecas/:id" component={RequireAuthAdmin(Horecas)} />
        <Route exact path="/public/horeca/:id" component={RequireAuthAdmin(Horeca)} />
        <Route exact path="/public/bedrijf/:id" component={RequireAuthAdmin(Bedrijf)} />
      </React.Fragment>
    );
  }
}

export default Inschrijvingen;
