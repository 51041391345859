import moment from 'moment'
import config from "../../../../package.json";
import trans from "./translation.json"
export function settranslate() {
  if (!localStorage.getItem("taal")) {
    localStorage.setItem("taal", config.language);
    moment.locale(config.language.toLowerCase());
  } else {
    var taal = localStorage.getItem("taal").toLowerCase();  
    moment.locale(taal);
  }
}

export function translate(value) {
  var taal = localStorage.getItem("taal");  
  if (value && value[taal]) {   
    return value[taal];
  }
  else if (value && value[config.language]) {
    return value[config.language];
  }
  if (typeof value === 'string') {
    
    return value;
  }
}

export function changelanguage(taal) {
  localStorage.setItem("taal", taal);
}

export function lang(value) {
  var taal = localStorage.getItem("taal");  
  if (trans[value]) {
    if (trans[value][0][taal]) {
      return trans[value][0][taal]
    } else {
      return value
    }
  } else {
    return value
  }
  
}

export function datumlang(datum, format) {
  var taal = localStorage.getItem("taal").toLowerCase();    
  moment.locale(taal);
  return moment(datum).format(format)

}