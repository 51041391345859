import React, { Component } from "react";
import { Route } from "react-router-dom";
import LogboekInsertPage from "./insert";
import LogboekEditPage from "./edit";
import LogboekInsertPageWebsite from "./website";
import LogboekPage from "./overzicht";
import VergelijkenPage from "./vergelijken";
import WebsitePage from "../Website/index";
class Bedrijven extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/logboek" component={LogboekPage} />
        <Route exact path="/logboek/vergelijken" component={VergelijkenPage} />
        <Route exact path="/logboek/insert" component={LogboekInsertPage} />
        <Route exact path="/logboek/edit/:id" component={LogboekEditPage} />
        <Route
          exact
          path="/logboek/insert/website"
          component={LogboekInsertPageWebsite}
        />
        <Route exact path="/logboek/website" component={WebsitePage} />
      </React.Fragment>
    );
  }
}

export default Bedrijven;
