import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect } from "../../../Components/Forms/renders";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
  componentDidMount() {
    if (!this.props.initialValues._id) this.props.initialize();
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
         <div className="row">
         <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">

         <Field
           className="form"
           autoFocus={true}
           name="vakantie"
           validate={required({ msg: "Type item is verplicht" })}
           label="Vakantie periode"
           component={renderselect}
           options={{
             Weekenden: "Weekenden",
             Vakantie: "Vakantie",
             "Door de weeks (buiten de vakantie)":
               "Door de weeks (buiten de vakantie)"
           }}
         />
          <Field
            className="form"
            autoFocus={true}
            name="weer"
            validate={required({ msg: "Type item is verplicht" })}
            label="Weer"
            component={renderselect}
            options={{
              Mooi: "Mooi",
              Gemiddeld: "Gemiddeld",
              Slecht: "Slecht"
            }}
          />
          <Field
            className="form"
            autoFocus={true}
            name="soortactiviteit"
            validate={required({ msg: "Type item is verplicht" })}
            label="Soorten Activiteiten"
            component={renderselect}
            options={{
              Creativiteit: "Creativiteit",
              Spel: "Spel",
              Sport: "Sport",
              Tochten: "Tochten",
              "Show/amusement": " Show/amusement",
              "Theater (amusement)": "Theater (amusement)",
              "Minidisco (amusement)": "Minidisco (amusement)",
              "Kerkdienst": "Kerkdienst"
            }}
          />
          <Field
            className="form"
            autoFocus={true}
            name="doelgroep"
            validate={required({ msg: "Type item is verplicht" })}
            label="Doelgroep"
            component={renderselect}
            options={{
              "Kinderen 6-": "Kinderen 6-",
              "Kinderen 12-": "Kinderen 12-",
              "Jeugd 12-15": "Jeugd 12-15",
              "16+": "16+",
              Volwassenen: "Volwassenen",
              Gezinnen: "Gezinnen",
              Senioren: "Senioren"
            }}
          />
          <Field
            name="recreatieleider"
            type="number"
            component={renderField}
            label="FTE (recreatieleider)"
          />
          <Field
            name="hulpkrachten"
            type="number"
            component={renderField}
            label="Hulpkrachten"
          />
          </div>
           <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <Field
            name="stagiaires"
            type="number"
            component={renderField}
            label="Stagiaires"
          />
          <Field
            name="deelnemers"
            type="number"
            component={renderField}
              validate={required({ msg: "Type item is verplicht" })}
            label="Aantal deelnemers"
          />
          <Field
            className="form"
            autoFocus={true}
            name="betalend"
            validate={required({ msg: "Type item is verplicht" })}
            label="Betalend"
            component={renderselect}
            options={{
              true: "ja",
              false: "nee"
            }}
          />
          <Field
            className="form"
            autoFocus={true}
            name="samenwerkinghoreca"
            validate={required({ msg: "Type item is verplicht" })}
            label="Samenwerking horeca"
            component={renderselect}
            options={{
              true: "ja",
              false: "nee"
            }}
          />
          <Field
            className="form"
            autoFocus={true}
            name="alexsammy"
            validate={required({ msg: "Type item is verplicht" })}
            label="Alex of Sammy aanwezig"
            component={renderselect}
            options={{
              true: "ja",
              false: "nee"
            }}
          />
          </div>
          </div>
          <div className="pull-left submit">
            <input
              type="submit"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Aanmaken"
            />
            </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel" // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  state => ({
    initialValues: state.logboeken // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
