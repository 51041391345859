import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import renderDate from "../../../Components/Forms/datepast";
class KiesDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit(values) {
    this.props.changedata(values);
  }

  onchangeitem = (vals) => {
    const { handleSubmit } = this.props;
    this.setState({ loading: true });
    setTimeout(
      handleSubmit((params) => {
        this.onSubmit(params);
      })
    );
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Field name="datum" type="number" component={renderDate} label="Kies een datum" onChange={this.onchangeitem} />
      </form>
    );
  }
}

KiesDate = reduxForm({
  form: "filterdatumdag", // a unique identifier for this form
})(KiesDate);

// You have to connect() to any reducers that you wish to connect to yourself
KiesDate = connect((state) => ({
  initialValues: {
    datum: new Date(),
  },
}))(KiesDate);

export default KiesDate;
