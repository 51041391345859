import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchkampeerplaatsen } from "../Reducers/actions";
import Loading from "../../../Components/loading/load";
import KampeerplaatsLijst from "./Components/widget"
function IndexKampeerplaatsen({ kampeerplaatsen, fetchkampeerplaatsen }) {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    fetchkampeerplaatsen().then((data) => {
      setLoad(true);
    },[])
  }, [load]);
  return (
    <div className="box box-default">
      <div className="box-header with-border">
        <h3 className="box-title"> Kampeerplaatsen</h3>
      </div>
      <Loading loadings={load} />
      {load && <KampeerplaatsLijst kampeerplaatsen={kampeerplaatsen} />}
    </div>
  );
}
function mapStateToProps(state) {
  return { user: state.auth.user, kampeerplaatsen: state.standplaatsen.kampeerplaatsen };
}
export default connect(mapStateToProps, { fetchkampeerplaatsen })(IndexKampeerplaatsen);
