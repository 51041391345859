import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderTextField } from "../../../Components/Forms/renders";
import datumField from "../../../Components/Forms/datepast";
import tijdField from "../../../Components/Forms/time";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
          <Field
            name="naam"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Naam van de faciliteit"
          />
          <Field
            name="beschrijving"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderTextField}
            label="Omschrijving van de faciliteit"
          />
          <Field
            name="reserveringen"
            type="number"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Aantal reserveringen toegestaan (0 is onbeperkt)"
          />
          <Field
            name="personen"
            type="number"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Aantal personen per tijdsslot"
          />
          <Field name="startdatum" type="input" validate={required({ msg: "Dit item is verplicht" })} component={datumField} label="Startdatum" />
          <Field name="einddatum" type="input" validate={required({ msg: "Dit item is verplicht" })} component={datumField} label="Einddatum" />
          <Field name="starttijd" type="input" validate={required({ msg: "Dit item is verplicht" })} component={tijdField} label="Tijd opening" />
          <Field name="eindtijd" type="input" validate={required({ msg: "Dit item is verplicht" })} component={tijdField} label="Tijd sluiting" />
          <Field
            name="tijdsloten"
            type="number"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Tijdsloten in minuten"
          />
          <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Aanmaken" />
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  () => ({}) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
