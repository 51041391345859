export const BEDRIJF_ERROR = "BEDRIJF_ERROR";
export const SET_BEDRIJVEN = 'SET_BEDRIJVEN';
export const ADD_BEDRIJVEN = 'ADD_BEDRIJVEN';
export const SET_BEDRIJF = 'SET_BEDRIJF'
function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function itemError(error) {
  return {
    type: BEDRIJF_ERROR,
    payload: error,
  };
}
export function set(results) {
  return {
    type: SET_BEDRIJVEN,
    results
  }
}


export function setone(results) {
  return {
    type: SET_BEDRIJF,
    results
  }
}

export function getbedrijf(id) {
  return dispatch => {
    return fetch('/api/public/bedrijf/'+id, {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem("gast"),
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

export function fetchbedrijf() {
  return dispatch => {
    return fetch('/api/public/bedrijven/', {
      method: 'get',
      headers: {
        "Content-Type": "application/json",
        Gast: localStorage.getItem("gast"),
      }
    }).then(handleResponse).then(results => {
      return dispatch(set(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
