import React from "react";
import moment from "moment";
import { translate } from "../../../Components/Lib/Languages/language";
import BootstrapTable from "react-bootstrap-table-next";
class InschrijvingenComponent extends React.Component {
  render() {
    const { reserveringen, faciliteit, datum } = this.props;

    function datumact(cell) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: datumact,
      },
      {
        dataField: "tijd",
        text: "Tijd",
      },
      {
        dataField: "gasten.naam",
        text: "Naam",
        sort: true,
      },
      {
        dataField: "personen",
        text: "Personen",
      },
    ];

    return (
      <div className="box-header with-border">
        <h2 className="page-header">
          {translate(faciliteit.naam)}
          <small className="pull-right">{datumact(datum)}</small>
        </h2>
        <div className="box-body box-profile inschrijving"></div>
        <div className="box-body box-profile inschrijving">
          <BootstrapTable keyField="_id" data={reserveringen} columns={columns} defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]} />
        </div>
      </div>
    );
  }
}
export default InschrijvingenComponent;
