import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Animated } from "react-animated-css";
import { fetchreserveringen, getfaciliteit, deletereservering, getbeschikbaarheid, savereververing, setaanwezig } from "./Reducers/actions";
import { message } from "../../Reducers/loading/actions";
import { translate } from "../../Components/Lib/Languages/language";
import DatumForm from "./Components/dateformres";
import InschrijfForm from "./Components/inschrijven";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import ComponentToPrint from "./Components/print";
import Bar from "./Components/bar";
import moment from "moment";
class InschrijPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      inschrijven: false,
      tijden: [],
      datum: moment(),
      tijd: "",
      aanwezig: false,
    };
  }

  componentWillMount() {
    this.props.getfaciliteit(this.props.match.params.id).then((sdata) => {
      if (sdata.results) {
        this.setdate(moment().toISOString());
      }
    });
  }

  submit = (vals) => {
    this.setState({ tijd: vals.tijd });
    this.loadreserveringen(moment(vals.datum).toISOString(), vals.tijd, vals.aanwezig);
  };

  reserveringen = (vals) => {
    vals.faciliteit = this.props.match.params.id;
    this.props.savereververing(vals).then((data) => {
      if (data.type === "FACILITEIT_ERROR") {
        alert(data.payload);
        return false;
      } else {
        this.props.message(4000, "success", "Je reservering is succesvol verwerkt").then((data) => {
          this.loadreserveringen(moment(vals.datum).toISOString(), this.state.tijd, this.state.aanwezig);
          this.setState({ inschrijven: false });
        });
      }
    });
  };

  loadreserveringen = (datum, tijd, aanwezig) => {
    this.props.fetchreserveringen({ datum: datum, faciliteit: this.props.faciliteit._id, tijd: tijd, aanwezig: aanwezig }).then((data) => {
      this.setState({ loaded: true, datum: datum, aanwezig: aanwezig });
    });
  };
  setdate = (datum) => {
    this.props.getbeschikbaarheid({ faciliteit: this.props.match.params.id, datum: moment(datum).toISOString() }).then((data) => {
      if (data.results) {
        this.setState({ tijden: data.results.tijden, loaded: true, nodata: false, week: true, datum: moment(datum).toISOString() });
        this.loadreserveringen(moment(datum).toISOString(), this.state.tijd, this.state.aanwezig);
      }
    });
  };

  deletein = (id) => {
    this.props.deletereservering(id).then((deln) => {
      this.props.message(4000, "success", "De inschrijving is succesvol verwijderd");
      this.loadreserveringen(this.state.datum, this.state.tijd, this.state.aanwezig);
    });
  };

  setaanwezig = (id) => {
    this.props.setaanwezig({ id: id, aanwezig: true }).then(() => {
      this.props.message(4000, "success", "De gast is succesvol op aanwezig gezet");
      this.loadreserveringen(this.state.datum, this.state.tijd, this.state.aanwezig);
    });
  };
  setdatum = (datum) => {
    this.props.getfaciliteit(this.props.match.params.id).then((sdata) => {
      if (sdata.results) {
        this.setdate(datum);
      }
    });
  };
  showrrser = () => {
    this.setState({ inschrijven: true });
  };

  nodatainfo = (info) => {
    if (this.state.nodata)
      return (
        <Animated animationIn="fadeInDown" animationOut="fadeOutUp" className="loadingmessage" isVisible={true}>
          <div className={"callout callout-warning"}>
            <p>{info}</p>
          </div>
        </Animated>
      );
  };

  render() {
    const { faciliteit, reserveringen } = this.props;

    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "datum",
        text: "Datum",
        formatter: priceFormatter,
      },
      {
        dataField: "tijd",
        text: "Tijd",
      },
      {
        dataField: "gasten.naam",
        text: "Naam",
        sort: true,
      },
      {
        dataField: "gasten.email",
        text: "E-mailadres",
      },
      {
        dataField: "personen",
        text: "Personen",
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return (
            <span>
              <button className="btn btn-success" onClick={this.setaanwezig.bind(this, row._id)}>
                Aanwezig
              </button>
              <button className="btn btn-danger pull-right" onClick={this.deletein.bind(this, row._id)}>
                X
              </button>
            </span>
          );
        },
      },
    ];

    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <div className="box-title">
            <h2>{translate(faciliteit.naam)}</h2>
          </div>
          <Link to="/faciliteiten" className="btn btn-primary btn-sm float-right" role="group">
            Overzicht faciliteiten
          </Link>
        </div>

        {this.state.loaded && (
          <div className="box-body box-profile">
            <div>
              <DatumForm tijden={this.state.tijden} changedate={this.setdate} onSubmit={this.submit} />
            </div>
            <div>
              <Bar reserveringen={reserveringen} datumres={this.state.datum} tijden={this.state.tijden} tijd={this.state.tijd} />
            </div>
            <div className="pull-right">
              <button className="btn btn-success btn" onClick={this.showrrser}>
                Maak reserveringen
              </button>{" "}
              <ReactToPrint content={() => this.componentRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button className="btn btn-info btn" onClick={handlePrint}>
                      Printen
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </div>
            {this.state.inschrijven && (
              <div className="inschrijform">
                <InschrijfForm tijden={this.state.tijden} setdate={this.setdatum} onSubmit={this.reserveringen} />
                {this.nodatainfo("Het is helaas niet mogelijk een reservering te maken op deze datum")}
              </div>
            )}
            <div className="box-body">
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  reserveringen={reserveringen}
                  faciliteit={faciliteit}
                  datum={this.state.datum}
                  tijden={this.state.tijden} 
                  tijd={this.state.tijd}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
              <BootstrapTable
                keyField="_id"
                data={reserveringen}
                columns={columns}
                pagination={paginationFactory({sizePerPage: 25})}
                defaultSorted={[{ dataField: "gasten.naam", order: "asc" }]}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    faciliteit: state.faciliteiten.item,
    reserveringen: state.faciliteiten.reserveringen,
    week: state.faciliteiten.week,
  };
}

export default connect(mapStateToProps, {
  fetchreserveringen,
  message,
  deletereservering,
  getfaciliteit,
  getbeschikbaarheid,
  savereververing,
  setaanwezig,
})(InschrijPage);
