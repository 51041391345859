import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import React from "react";
import donwload from "downloadjs";

class AlgemeenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      naam: "",
      bestand: ""
    };
  }
  componentWillMount() {
    this.setState({
      naam: this.props.cursist.voornaam + " " + this.props.cursist.achternaam,
      bestand: this.props.training.certificaten.bestand 
    });
  }

  downloadcert = () => {
    // Fetch an existing PDF document
    if (this.state.bestand) {
    const url = "/"+this.state.bestand;
    fetch(url, {
      headers: {
        Accept: "application/pdf"
      },
      responseType: "blob"
    })
      .then(response => {
        if (response.ok) {
          return response.arrayBuffer();
        }
      })
      .then(blob => {
        PDFDocument.load(blob).then(pdfDoc => {
          pdfDoc.embedFont(StandardFonts.TimesRoman).then(customFont => {
            const text = this.state.naam;
            const textSize = 40;
            const textWidth = customFont.widthOfTextAtSize(text, textSize);   
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            // Get the width and height of the first page
            const { width } = firstPage.getSize();
            var lenghte = (width  - textWidth) /2;
            firstPage.drawText(this.state.naam, {
              x: lenghte -10,
              y: 520,
              size: textSize,
              color: rgb(0.95, 0.1, 0.1)
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            pdfDoc.save().then(modfile => {
              //const file = new Blob(modfile, { type: "application/pdf" });
              donwload(modfile, this.state.naam + ".pdf", "application/pdf");
            });

            // Trigger the browser to download the PDF document
          });
        });
      }); 
    } else {
      alert("Certificaat is niet gevonden")
    }
  };
  render() {
    return (
      <div className="box box-default">
        <div className="box-body">
          <div className="pull-left submit">
            <input
              type="button"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Download certificaat"
              onClick={this.downloadcert.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AlgemeenForm;
