import React from "react";
import { connect } from "react-redux";
import { fetchwebsite, websitecounters } from "./Reducers/actions";
import {Verwerken} from "../Logboeken/Reducers/actions"
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {Link} from 'react-router-dom';
import paginationFactory from "react-bootstrap-table2-paginator";
import {message} from "../../Reducers/loading/actions"
import ProgressComponent from "./Components/progress"
class WebsitePage extends React.Component {
  state = {
    errors: {},
    deelnemers: 0,
    hulpkrachten: 0,
    recreatieleider: 0,
    stagiaires: 0
  };

  componentWillMount() {
    this.getvals({});
    this.getcount({});
  }

  getvals = vals => {
    this.props.fetchwebsite(vals).then(data => {
     if(data.type !== "WEBSITE_ERROR") { 

     if (data.results.length > 0) {
      this.props.message(3000, "info" ," Je hebt nog " + data.results.length +" activiteiten die je moet verwerken" )
     } else {
      this.props.message(3000, "success" ," Alle activiteiten zijn verwerkt" )
     }
    }
    });
  };

  getcount = vals => {
    this.props.websitecounters(vals).then(data => {});
  };

  handleItem(item) {
    this.props.Verwerken({id: item._id}).then(datav => {
    this.getvals({});
    });
  }

  render() {
    function priceFormatter(cell, row) {
      return moment(cell).format("DD-MM-YYYY");
    }
    const columns = [
      {
        dataField: "datum",
        text: "datum",
        formatter: priceFormatter
      },
      {
        dataField: "activiteit",
        text: "Naam activiteit"
      },
      {
        text: "Toevoegen",
        dataField: "_id",
        formatter: (cellContent, row) => {
          return <span><button className="btn btn-danger" onClick={this.handleItem.bind(this,row)}>Verwijderen</button> <Link className="btn btn-success" to={{ pathname: "/logboek/insert/website", row }}>Toevoegen</Link></span>
        },
        headerStyle: (colum, colIndex) => {
         return { width: '250px' };
       }
      }
    ];
    const { website, counters } = this.props;
    return (
      <div className="box box-default">
      <ProgressComponent naam="hoeveel procent van de activiteiten heb je ingevuld" tel2={counters.website} tel1={counters.logboek}/>
        <div className="box-header with-border">
          <h3 className="box-title">
            {" "}
            Activiteiten vanuit de website
          </h3>
        </div>
        <div className="box box-default">
          <div className="row">
            <div className="col-md-12 no-float">
              <div className="box box-primary">
                <div className="box-body box-profile" />
                <BootstrapTable
                  keyField="_id"
                  data={website}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { username: state.auth.user, website: state.website.items, counters: state.website.counters, jaar: state.logboeken.jaar };
}

export default connect(
  mapStateToProps,
  { fetchwebsite,websitecounters, Verwerken, message }
)(WebsitePage);
