import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import renderDaterange from "../../../../Components/Forms/daterange";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
class FilterSensor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loading: true,
      gemiddelde: 0,
      max: 0,
      stand: 0,
      lekkage: true,
      fases: [0, 0, 0],
    };
  }
  componentWillReceiveProps(nextprops) {
    if (!nextprops.loading) {
      this.setState({
        loading: nextprops.loading,
        gemiddelde: nextprops.gemiddelde.toFixed(2),
        max: nextprops.max.toFixed(2),
        stand: Number(nextprops.stand / 1000).toFixed(3),
        lekkage: nextprops.lekkage,
      });
    }
  }
  onSubmit(values) {
    this.props.changedata(values);
  }

  onchangeitem = (vals) => {
    const { handleSubmit } = this.props;
    this.setState({ loading: true });
    setTimeout(
      handleSubmit((params) => {
        this.onSubmit(params);
      })
    );
  };

  loading() {
    if (this.state.loading) {
      return <div className="col-md-3 col-sm-6 col-xs-12 loadingsearch">Gegevens worden geladen ...</div>;
    }
  }
  render() {
    console.log(this.props.lekkage);
    return (
      <form onSubmit={this.onSubmit}>
        <div className="row filter ">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <Field name="datum" type="number" component={renderDaterange} label="Kies een datum" onChange={this.onchangeitem} />
          </div>
          <div className="col-md-1 col-sm-6 col-xs-6">
            Gemiddelde<span className="gemiddelde">{this.state.gemiddelde} Kub</span>
          </div>
          <div className="col-md-1 col-sm-6 col-xs-6">
            Hoogste Piek<span className="gemiddelde">{this.state.max} Kub</span>
          </div>
          <div className="col-md-1 col-sm-6 col-xs-6">
            Laatste Meterstand<span className="gemiddelde">{this.state.stand}</span>
          </div>
          {this.state.lekkage === false && (
            <div className="col-md-3 col-sm-6 col-xs-6">
              Status{" "}
              <span
                data-tooltip-id="tooltip-anchor-hide"
                data-tooltip-content="Er is geen nulstand beschikbaar in deze periode, wat betekent dat er gedurende deze tijd water is gebruikt. 
                In de meeste gevallen is er echter altijd een moment waarop er geen water wordt gevraagd."
                data-tooltip-delay-hide={1000}
                className="gemiddelde badge badge-warning"
              >
                Er is vermoedelijk een lekkage
              </span>
              <Tooltip id="tooltip-anchor-hide" />
            </div>
          )}
        </div>
      </form>
    );
  }
}

FilterSensor = reduxForm({
  form: "filtersensor", // a unique identifier for this form
})(FilterSensor);

// You have to connect() to any reducers that you wish to connect to yourself
FilterSensor = connect((state) => ({
  initialValues: {
    datum: { startdate: moment().subtract(7, "days"), enddate: new Date() },
  },
}))(FilterSensor);

export default FilterSensor;
