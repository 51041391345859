import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderselect,
  renderTextField
} from "../../../../Components/Forms/renders";

class AlgemeenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }
componentWillMount() {

}
  render() {
    const { handleSubmit } = this.props;
    if (this.props.loaded) {
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field
                  className="form"
                  name="algemeen.algemeen"
                  label="Algemeen indruk"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.uitstraling"
                  label="Uitstraling"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.enthousiast"
                  label="Enthousiast"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.houding"
                  label="Actieve houding"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.groepsgedrag"
                  label="Groepsgedrag"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.vaardigheden"
                  label="Vaardigheden"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                <Field
                  className="form"
                  name="algemeen.medewerker"
                  label="Soort medewerker"
                  component={renderselect}
                  options={{
                    "Zelfstandige werker": "Zelfstandige werker",
                    "Mee-werker": "Mee-werker",
                    "Leider type": "Leider type"
                  }}
                />
                     <Field
                  className="form"
                  name="algemeen.toelichting"
                  label="Toelichting"
                  component={renderTextField}
                />
                     <Field
                  className="form"
                  name="algemeen.bijzonderheden"
                  label="Bijzonderheden trainingsweekend"
                  component={renderTextField}
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input
                type="submit"
                className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                name="next"
                value="Beoordelen"
              />
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
  }
}
AlgemeenForm = reduxForm({
  form: "beoordelingalgemeen", // a unique identifier for this form
  enableReinitialize: true
})(AlgemeenForm);

// You have to connect() to any reducers that you wish to connect to yourself
AlgemeenForm = connect(
  state => ({
    initialValues: state.beoordelingen.item, // pull initial values from account reducer

  }),
  {}
)(AlgemeenForm);

export default AlgemeenForm;
