import {
  SET_ALERTPROFILES,
  ADD_ALERTPROFILES,
  ALERTPROFILE_ERROR,
  SET_ALERTPROFILE
} from './actions';
export default function alertprofile(state = {
  item: [],
  items: []
}, action = {}) {
  switch (action.type) {
    case ADD_ALERTPROFILES:
      return { ...state,
        items: [...state.items, action.results]
      };
    case SET_ALERTPROFILE:
      return { ...state,
        item: action.results
      };
    case ALERTPROFILE_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_ALERTPROFILES:
      return { ...state,
        items: action.results
      };
    default:
      return state;
  }
}
