import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { message, fixmessage, clearmessage } from "../../../Reducers/loading/actions";
import { getbedrijf, getactiviteit, savinschrijving } from "./Reducers/actions";
import InschrijfForm from "./Components/inschrijfform";
import Activiteit from "./Components/activiteiten";
import Loading from "../../../Components/loading/load";
import { checkgast, Sendmail, gast } from "../Gasten/Reducers/actions";
import { lang, datumlang } from "../../../Components/Lib/Languages/language";
import "react-sweet-progress/lib/style.css";
class Inschrijven extends Component {
  state = {
    errors: {},
    loading: false,
    sendmail: false,
    inschrijven: false,
    aanmeldingen: 0,
    maxdeelnemers: 0,
    openstand: 0,
  };

  componentWillMount() {
    this.props.getactiviteit(this.props.match.params.id).then((data) => {
      if (data.results) {
        this.setState({ maxdeelnemers: data.results.maxdeelnemers });
        if (data.results.aanmeldingen.length > 0) {
          var openstaand = Math.round(data.results.maxdeelnemers - data.results.aanmeldingen[0].personen);
          if (openstaand < 1) {
            this.props.fixmessage(
              "danger",
              lang("Het maximaal aantal personen is bereikt. Je kan je helaas niet meer inschrijven")
            );
          }
          this.setState({
            aanmeldingen: data.results.aanmeldingen[0].personen,
            openstand: openstaand,
          });
        } else {
          this.setState({ openstand: data.results.maxdeelnemers });
        }
        if (!this.props.bedrijf._id) {
          this.props.getbedrijf(data.results.bedrijf).then((bdata) => {
            this.setState({ loaded: true });
          });
        } else {
          this.setState({ loaded: true });
        }
      }
    });

  }
  componentWillUnmount() {
    this.props.clearmessage();
  }
  submit = (vals) => {
    this.setState({ loaded: false });
    vals.datumactiviteit = this.props.activiteit.datum
    if (parseInt(vals.personen,0) <= this.state.openstand) {
      this.props.savinschrijving(this.props.match.params.id, vals).then((data) => {
        if (data.type === "FACILITEIT_ERROR") {
          alert(data.payload);
          this.setState({ loaded: true });
          return false;
        } else {
          this.props.message(2000, "success", lang("Je inschrijving is succesvol verwerkt")).then((data) => {
            this.props.history.push("/public/activiteiten/" + this.props.bedrijf._id);
          });
        }
      });
    } else {
      this.setState({ loaded: true });
      this.props.message(
        4000,
        "danger",
        lang("Het maximaal aantal personen is bereikt. Je kan nog") + " " + this.state.openstand + " " + lang("personen aanmelden")
      );
    }
  };

  render() {
    const { activiteit } = this.props;
    function datum(cell) {
        return <h4 className="activiteit-datum">{datumlang(cell, "dddd DD MMMM YYYY")}</h4>;
      
    }
    return (
      <div className="public-box-body">
          <Loading loading={this.state.loaded} />
        {this.state.loaded && (
          <div>
            {datum(activiteit.datum)}
            <Activiteit activiteit={activiteit} />

            <h2 className="headline text-yellow"> {lang("Inschrijven")}</h2>
            {this.state.openstand > 0 && (
              <div className="inschrijf-content">
                <InschrijfForm onSubmit={this.submit} />
              </div>
            )}
          </div>
        )}
        <Link
          to={{
            pathname: "/public/activiteiten/" + activiteit.bedrijf,
          }}
          className="back-arrow"
        >
          <i className="fa fa-chevron-left"></i> {lang("Terug")}
        </Link>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bedrijf: state.pubred.bedrijf,
    activiteit: state.pubred.activiteit,
  };
}

export default connect(mapStateToProps, {
  message,
  fixmessage,
  clearmessage,
  getbedrijf,
  getactiviteit,
  savinschrijving,
  checkgast,
  Sendmail,
  gast,
})(Inschrijven);
