export const SET_BEOORDELINGEN = 'SET_BEOORDELINGEN';
export const ADD_BEOORDELINGEN = 'ADD_BEOORDELINGEN';
export const BEOORDELING_ERROR = 'BEOORDELING_ERROR';
export const SET_BEOORDELING = 'SET_BEOORDELING'

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}


export function set(results) {
  return {
    type: SET_BEOORDELINGEN,
    results
  }
}
export function setone(results) {
  return {
    type: SET_BEOORDELING,
    results
  }
}
export function add(results) {
  return {
    type: ADD_BEOORDELINGEN,
    results
  }
}
export function itemError(error) {
  return {
    type: BEOORDELING_ERROR,
    payload: error
  };
}


export function fetchbeoordelingcursisit(data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/beoordelingen/cursist', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}
export function updatebeoordeling(data) {
  return dispatch => {
    return fetch('/api/admin/trainingen/beoordelingen', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
      }
    }).then(handleResponse).then(results => {
      return dispatch(setone(results.results));
    }).catch((err) => {
      return dispatch(itemError('Er is een fout opgetreden bij het insert database'));
    });
  };
}

