import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import {
  renderField,
} from "../../../Components/Forms/renders";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  inschrijving = (value) => {    
      this.setState({inschrijving: JSON.parse(value.target.value)})
  }
  render() {
    const { handleSubmit } = this.props;    
    return (
      <form onSubmit={handleSubmit}>
        <div className="box-body">
        <div className="box-title">
            <h3>Inschrijven</h3>
          </div>
          <Field
            name="email"
            type="email"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="E-mailadres"
          />
            <Field
            name="naam"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Familienaam"
          />
             <Field
            name="personen"
            type="number"
            validate={required({ msg: "Dit item is verplicht" })}
            component={renderField}
            label="Aantal personen"
          />
          <input
            type="submit"
            className="btn btn-next btn-fill btn-success btn-wd btn-mg"
            name="next"
            value="Inschrijven"
          />
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "inschrijven", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  () => ({
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
