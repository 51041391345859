import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderselect, renderselectapi } from "../../../../Components/Forms/renders";
import DateInput from "../../../../Components/Forms/date";
import mask from "../../../../Components/Forms/mask"

class BedrijvenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      einddatum: false,
    };
  }

  componentDidMount() {  
    this.changerole(this.props.initialValues.role);
  }

  checkrole = (val) => {
    this.changerole(val.target.value);
  };

  changerole = (val) => {
    if (val === "manager" || val === "admin" || val === "hoofdrecreatie") {
      this.setState({ einddatum: false });
    } else {
      this.setState({ einddatum: true });
    }
  };

  render() {
    const { handleSubmit, bedrijven } = this.props;    
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field name="email" type="input" validate={[required()]} component={renderField} label="Vul het e-mailadres in" />
                <Field name="voornaam" type="input" validate={[required()]} component={renderField} label="Vul de voornaam in" />
                <Field name="achternaam" type="input" validate={[required()]} component={renderField} label="Vul de achternaam in" />
                <Field name="telefoonnummer" type="input" mask="39999999999" validate={[required()]} component={mask} label="Vul je telefoonnummer in" />
                <Field name="bedrijf" type="input" validate={[required()]} component={renderselectapi} options={bedrijven} label="Vul het bedrijf in" />
                <Field
                  name="role"
                  type="input"
                  validate={[required()]}
                  component={renderselect}
                  onChange={this.checkrole}
                  options={{
                    manager: "Manager",
                    medewerker: "Medewerker",
                  }}
                  label="Vul de rol van de medewerker in"
                />
                {this.state.einddatum && (
                  <Field name="einddatum" type="input" validate={[required()]} component={DateInput} label="Vul de einddatum in van het contract" />
                )}
              </div>
            </div>
            <div className="pull-left submit">
              <input type="submit" className="btn btn-next btn-fill btn-success btn-wd btn-mg" name="next" value="Updates" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
BedrijvenForm = reduxForm({
  form: "gebruikeredit", // a unique identifier for this form
})(BedrijvenForm);

// You have to connect() to any reducers that you wish to connect to yourself
BedrijvenForm = connect(
  (state) => ({
    initialValues: state.gebruikers.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(BedrijvenForm);

export default BedrijvenForm;
