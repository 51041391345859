import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import MessageSucces from "../../../Components/Forms/Validate/conform";
import MessageError from "../../../Components/Forms/Validate/error";
import CursistenComponent from "./Components/cursisten";
import { gettraining, updatetraining } from "./Reducers/actions";
import CuristenForm from "./Components/form";

class TrainingPage extends React.Component {
  state = {
    errors: {},
    success: false,
    error: false,
    training: {}
  };
  componentWillMount() {
    this.props.gettraining(this.props.match.params.id).then(data => {
      if (data.results) {
        this.setState({ training: data.results });
      }
    });
  }

  submit = vals => {
    this.props.updatetraining(this.props.match.params.id, vals).then(data => {
      if (data.type ==="TRAINING_ERROR") {
        alert(data.payload);
        return false;
      } else {
            alert("De trainig is bijgewerkt")
      }
    });
}

  render() {
    const { training } = this.state;
    function priceFormatter(datum) {
      return moment(datum).format("DD-MM-YYYY");
    }

    return (
      <div className="box box-default">
        <MessageSucces
          tekst={"Het profiel is goed opgeslagen"}
          success={this.state.success}
        />
        <MessageError
          tekst={"Er is iets fout gegaan"}
          error={this.state.error}
        />
        <div className="box-header with-border">
          <div className="box-title">
            <h2>
            {training.titel} 
            </h2>
            <span className="discription">
{priceFormatter(training.datum)}
            </span>
          </div>
        </div>
        <div className="box-header with-border">
          <h3 className="box-title">Trainer: {training.trainer && training.trainer.voornaam} 
          </h3>
        </div>
        <div className="row">
 
          <div className="col-md-12">
            <div className="box-header with-border">
              <h3 className="box-title"> Cursisten</h3>
              <CursistenComponent
                cursisten={training}
                router={this.props.history}
              />
            </div>
            <div className="box box-widget collapsed-box">
            <div className="box-header with-border">
            <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-plus"></i>
                </button>
              </div>
              <h3 className="box-title"> Training aanpassen</h3>
              {training._id && <CuristenForm onSubmit={this.submit} />}
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { training: state.trainingen.item };
}

export default connect(mapStateToProps, { gettraining, updatetraining })(TrainingPage);
