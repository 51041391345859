import { SET_HORECAS, ADD_HORECAS, HORECA_ERROR, SET_HORECA, SET_RESERVERINGEN } from "./actions";
export default function cats(
  state = {
    item: [],
    items: [],
    reserveringen: [],
  },
  action = {}
) {
  switch (action.type) {
    case ADD_HORECAS:
      return { ...state, items: [...state.itens, action.results] };
    case SET_HORECA:
      return { ...state, item: action.results };
    case HORECA_ERROR:
      return { ...state, error: action.payload };
    case SET_RESERVERINGEN:
      return { ...state, reserveringen: action.results };
    case SET_HORECAS:
      return { ...state, items: action.results };
    default:
      return state;
  }
}
