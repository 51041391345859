import {
  SET_BEOORDELINGEN,
  ADD_BEOORDELINGEN,
  BEOORDELING_ERROR,
  SET_BEOORDELING
} from './actions';
export default function cats(state = {
  item: [],
  items: []
}, action = {}) {
  switch (action.type) {
    case ADD_BEOORDELINGEN:        
      return { ...state,
        items: [...state.items, action.results]
      };
    case SET_BEOORDELING:
      return { ...state,
        item: action.results
      };
    case BEOORDELING_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_BEOORDELINGEN:
      return { ...state,
        items: action.results
      };
    default:
      return state;
  }
}
