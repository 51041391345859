import moment from 'moment';
import {
  SET_LOGBOEKEN,
  ADD_LOGBOEKEN,
  LOGBOEK_ERROR,
  SET_LOGBOEK,
  SET_VERGELIJKEN,
  SET_JAAR
} from './actions';
export default function cats(state = {
  item: [],
  items: [],
  bedrijven:[],
  jaar: localStorage.getItem('jaar') ? localStorage.getItem('jaar'): moment().year()

}, action = {}) {
  switch (action.type) {
    case ADD_LOGBOEKEN:
      return { ...state,
        items: [...state.itens, action.results]
      };
    case SET_LOGBOEK:
      return { ...state,
        item: action.results
      };
    case LOGBOEK_ERROR:
      return { ...state,
        error: action.payload
      };
    case SET_LOGBOEKEN:
      return { ...state,
        items: action.results
      };
      case SET_VERGELIJKEN:
        return { ...state,
          bedrijven: action.results
        };
        case SET_JAAR:
          localStorage.setItem('jaar', action.results);
          return { ...state,
            jaar: action.results
          };
    default:
      return state;
  }
}

