import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderselectapi } from "../../Components/Forms/renders";
import Logboekenmenu from "../../Pages/Logboeken/menu";
import Faciliteitensidebar from "../../Pages/Faciliteiten/menu";
import Activiteitensidebar from "../../Pages/Activiteiten/menu";
import Bieppsidebar from "../../Pages/Biepp/menu";
import AdministratieMenu from "../../Pages/Admin/menu";
import SensorSidebar from "../../Pages/Sensors/menu";
import StansplaatsSidebar from "../../Pages/Standplaatsen/menu";
import { update } from "../../Pages/Admin/Gebruikers/Reducers/actions";
class Sitebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jaar: "",
    };
  }

  componentDidMount() {
    this.setState({ jaar: this.props.jaar });
    this.props.initialize({ jaar: this.props.jaar });
  }
  onchangeitem = (vals) => {
    this.props.update(this.props.username._id, {bedrijf: vals.target.value}).then((data) => {
      if (data.type === "GEBRUIKER_ERROR") {
        alert(data.payload);
        return false;
      } else {
        window.location.reload();
      }
    });
    //this.props.setjaar(vals.target.value);
  };

  render() {
    const { username, profile, token, bedrijven } = this.props;
    const bedrijf = (user) => {
      if (user && user.bedrijf) {
        return user.bedrijf.naam;
      }
    };

 

    const profileimage = (profile) => {
      if (profile && profile.portimage) {
        return <img src={profile.portimage} className="img-circle" alt="User" />;
      } else {
        return <img src="/img/nofoto.jpg" className="img-circle" alt="User" />;
      }
    };

    return (
      <aside className="main-sidebar" style={{ height: "100%" }}>
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar">
          {/* Sidebar user panel */}
          <div className="user-panel">
            <div className="center-block image">{profileimage(profile)}</div>
            <div className="pull-left info">
              <p>{username.name}</p>
            </div>
          </div>

          <form className="sidebar-toggle sidebar-open p-2">
            <div className="logo-lg center-block">{bedrijf(username)}</div>
            {token.role === "admin" && (
              <Field
                className="form"
                name="jaar"
                label="Kies een Bedrijf"
                component={renderselectapi}
                onChange={this.onchangeitem}
                options={bedrijven}
              />
            )}
          </form>

          {username.role && (
            <ul className="sidebar-menu" data-widget="tree">
              <li className="header">HOOFDMENU</li>
              <SensorSidebar token={token} username={username} />
              <StansplaatsSidebar token={token} username={username} />
              <Activiteitensidebar token={token} username={username} />
              <Bieppsidebar token={token} username={username} />
              <Faciliteitensidebar token={token} username={username} />

              <Logboekenmenu token={token} username={username} />
              <AdministratieMenu token={token} sername={username} />
            </ul>
          )}
        </section>
        {/* /.sidebar */}
      </aside>
    );
  }
}
function mapStateToProps(state) {
  return {
    username: state.auth.user,
    token: state.auth.token,
    profile: state.profile,
    jaar: state.logboeken.jaar,
    bedrijven: state.bedrijven.items,
  };
}

Sitebar = reduxForm({
  form: "kiesjaar", // a unique identifier for this form
})(Sitebar);

// You have to connect() to any reducers that you wish to connect to yourself
Sitebar = connect(mapStateToProps, { update })(Sitebar);

export default Sitebar;
