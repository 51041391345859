import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  renderselect,
  renderTextField,
  renderField
} from "../../../../Components/Forms/renders";
class KindertheaterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.loaded) {
    return (
      <div className="box box-default">
        <form onSubmit={handleSubmit}>
          <div className="box-body">
            <div className="row">
              <div className="col-12">
                <Field
                  className="form"
                  name="kindertheater.kindertheater"
                  label="Algemeen"
                  component={renderselect}
                  options={{
                    onvoldoende: "onvoldoende",
                    voldoende: "voldoende",
                    redelijk: "redelijk",
                    goed: "goed"
                  }}
                />
                  <Field
                  className="form"
                  name="kindertheater.naamtypetje"
                  label="Naam van het typetje"
                  component={renderField}
                />
                <Field
                  className="form"
                  name="kindertheater.tips"
                  label="Pakt tips van anderen op?"
                  component={renderselect}
                  options={{
                    ja: "ja",
                    nee: "nee"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.kleding"
                  label="Kleding"
                  component={renderselect}
                  options={{
                    matig: "matig",
                    voldoende: "voldoende",
                    ruimvoldoende: "ruim voldoende",
                    goed: "goed",
                    uitstekend: "uitstekend"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.groei"
                  label="Maakt groei door tijdens workshop"
                  component={renderselect}
                  options={{
                    matig: "matig",
                    voldoende: "voldoende",
                    ruimvoldoende: "ruim voldoende",
                    goed: "goed",
                    uitstekend: "uitstekend"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.typetje"
                  label="Typetje"
                  component={renderselect}
                  options={{
                    matig: "matig",
                    voldoende: "voldoende",
                    ruimvoldoende: "ruim voldoende",
                    goed: "goed",
                    uitstekend: "uitstekend"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.mimiek"
                  label="Vergroten van bewegingen/mimiek"
                  component={renderselect}
                  options={{
                    matig: "matig",
                    voldoende: "voldoende",
                    ruimvoldoende: "ruim voldoende",
                    goed: "goed",
                    uitstekend: "uitstekend"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.lef"
                  label="Toont lef/enthousiasme"
                  component={renderselect}
                  options={{
                    matig: "matig",
                    voldoende: "voldoende",
                    ruimvoldoende: "ruim voldoende",
                    goed: "goed",
                    uitstekend: "uitstekend"
                  }}
                />
                <Field
                  className="form"
                  name="kindertheater.opmerkingen"
                  label="Overige Opmerkingen"
                  component={renderTextField}
                />
              </div>
            </div>
            <div className="pull-left submit">
              <input
                type="submit"
                className="btn btn-next btn-fill btn-success btn-wd btn-mg"
                name="next"
                value="Beoordelen"
              />
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
  }
}
KindertheaterForm = reduxForm({
  form: "beoordelingkindertheater", // a unique identifier for this form
  enableReinitialize: true
})(KindertheaterForm);

// You have to connect() to any reducers that you wish to connect to yourself
KindertheaterForm = connect(
  state => ({
    initialValues: state.beoordelingen.item // pull initial values from account reducer
  }),
  {}
)(KindertheaterForm);

export default KindertheaterForm;
