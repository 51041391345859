import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { renderField, renderFieldUpload } from "../../../../Components/Forms/renders";

class BedrijvenForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }


  componentDidMount() {
 
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title"> Bedrijf toevoegen</h3>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="box-body">
         <div className="row">
         <div className="col-12">
          <Field
            name="naam"
            type="input"
              validate={[required()]}
            component={renderField}
            label="Naam van certificaat"
          />
          <Field
            name="files"
            type="file"
            component={renderFieldUpload}
            label="Upload certificaat"
          />
          </div>
          </div>
          <div className="pull-left submit">
            <input
              type="submit"
              className="btn btn-next btn-fill btn-success btn-wd btn-mg"
              name="next"
              value="Aanmaken"
            />
            </div>
        </div>
      </form>
      </div>
    );
  }
}
BedrijvenForm = reduxForm({
  form: "certificaatform" // a unique identifier for this form
})(BedrijvenForm);

// You have to connect() to any reducers that you wish to connect to yourself
BedrijvenForm = connect()(BedrijvenForm);


export default BedrijvenForm;
