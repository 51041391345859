import React, { Component } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
class RangeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: { min: 45, max: 60 },
    };
  }

  componentDidMount() {
    if (this.props.input.value) {
      this.setState({ value: this.props.input.value });
    } else {
      this.props.input.onChange(this.state.value);
    }
  }

  handleSelect = (value) => {
    this.setState({ value });
    this.props.input.onChange(value);
  };
  render() {
    const { label, error, touched, warning, } = this.props;
    return (
      <div>
        <label>{label}</label>
        <div className="input-range-style">
          <InputRange
            maxValue={480}
            formatLabel={(value) => `${value} min.`}
            minValue={0}
            step={15}
            value={this.state.value}
            onChange={this.handleSelect}
          />
          {touched && ((error && <span className="text-danger">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}

export default RangeSelect;
