import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import datumField from "../../../Components/Forms/date";
class LogboekFormWebsite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      inschrijving: false,
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
         <div className="row">
          <div className="col-md-3">
          <Field
            name="datum"
            type="input"
            validate={required({ msg: "Dit item is verplicht" })}
            component={datumField}
            label="Kies een datum"
            dateformat={"WW-YYYY"}
          />
        </div>
        <div className="col-md-3">
        <input
            type="submit"
            className="btn btn-next btn-fill btn-success btn-wd btn-mg weekformsubmit"
            name="next"
            value="Zoeken"
          />
        </div>
        </div>
      </form>
    );
  }
}
LogboekFormWebsite = reduxForm({
  form: "formprofiel", // a unique identifier for this form
})(LogboekFormWebsite);

// You have to connect() to any reducers that you wish to connect to yourself
LogboekFormWebsite = connect(
  (state,props) => ({
    initialValues: props.week, // pull initial values from account reducer
  }) // bind account loading action creator
)(LogboekFormWebsite);

export default LogboekFormWebsite;
